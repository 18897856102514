import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import dayjs from 'dayjs';
import { Button, DatePicker } from 'antd';
import { InputLabel } from '@mui/material';
import './FieldDateRangePicker.css';

const { RangePicker } = DatePicker;

const FieldDateRangePicker = ({
  name = ``,
  className = ``,
  onChange = () => {},
  value = {
    start_date: `2023-01-02`,
    end_date: `2024-08-02`,
  },
  style = {},
  popupClassName = '',
  error = false,
  errorText = ``,
}) => {
  const [selectedDates, setSelectedDates] = useState([]);
  const [isFocus, setIsFocus] = useState(false);
  const dateFormat = 'YYYY-MM-DD';

  const handleDateChange = (dates, dateStrings) => {
    const formattedDates = dateStrings.map((dateStr, index) => {
      if (!R.isNil(dateStr) && !R.isEmpty(dateStr)) {
        const time = index === 0 ? '00:00:00' : '23:59:59'; // Use 00:00:00 for start_date and 23:59:59 for end_date
        return `${dateStr} ${time}`;
      }

      return ``;
    });

    setSelectedDates(dates);
    onChange(formattedDates);
  };

  useEffect(() => {
    if (!R.isEmpty(value.start_date) && !R.isEmpty(value.end_date)) {
      setSelectedDates([
        dayjs(value.start_date, dateFormat),
        dayjs(value.end_date, dateFormat),
      ]);
    }
  }, [value]);

  return (
    <div className={className} style={style}>
      <div className="custom-date-range-picker">
        <InputLabel
          id="demo-simple-select-standard-label"
          className={`inputLabel ${
            isFocus ? 'inputLabelHover' : 'inputLabelBlur'
          }`}
        >
          {name}
        </InputLabel>

        <RangePicker
          popupClassName={popupClassName}
          value={selectedDates}
          placeholder={['วันเริ่มต้น', 'วันสิ้นสุด']}
          format={dateFormat}
          onChange={handleDateChange}
          onFocus={() => {
            setIsFocus(true);
          }}
          onBlur={() => {
            setIsFocus(false);
          }}
          status={error === true ? 'error' : null}
          style={{
            color: value ? '#121f43' : '#8ca3ba',
            width: '-webkit-fill-available',
          }}
        />
      </div>
    </div>
  );
};

export default FieldDateRangePicker;
