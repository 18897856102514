import { useState, useEffect } from 'react';
import currency from 'currency.js';
import * as R from 'ramda';
import moment from 'moment';
import io from 'socket.io-client';

const google = window.google;
const monthsFull = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม',
];
const monthsShort = [
  'ม.ค.',
  'ก.พ.',
  'มี.ค.',
  'เม.ย.',
  'พ.ค.',
  'มิ.ย.',
  'ก.ค.',
  'ส.ค.',
  'ก.ย.',
  'ต.ค.',
  'พ.ย.',
  'ธ.ค.',
];
let googleMapsPromise;
export default {
  ToConvertDateTimeForTable: (date, showtime = true) => {
    date = new Date(date);
    const thailandOptions = { timeZone: 'Asia/Bangkok' };
    const year = date.toLocaleString('en', {
      year: 'numeric',
      ...thailandOptions,
    });
    const month = String(
      date.toLocaleString('en', { month: '2-digit', ...thailandOptions }),
    ).padStart(2, '0');
    const day = String(
      date.toLocaleString('en', { day: '2-digit', ...thailandOptions }),
    ).padStart(2, '0');
    const hours = String(
      date.toLocaleString('en', {
        hour: '2-digit',
        hour12: false,
        ...thailandOptions,
      }),
    ).padStart(2, '0');
    const minutes = String(
      date.toLocaleString('en', { minute: '2-digit', ...thailandOptions }),
    ).padStart(2, '0');

    return showtime
      ? `${year}-${month}-${day} ${hours}:${minutes}`
      : `${year}-${month}-${day}`;
  },

  IsEmptyFunction: (func) => {
    const _func = func.toString();
    const start_index = _func.indexOf('{');
    const end_index = _func.lastIndexOf('}');

    if (start_index !== -1 && end_index !== -1) {
      const contentInsideBraces = _func
        .substring(start_index + 1, end_index)
        .trim();

      return R.isEmpty(contentInsideBraces) ? true : false;
    } else {
      return null;
    }
  },

  getCookie(params) {
    let name = params + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  },

  ConvertJsonToFormData: (json_data = {}) => {
    const form_data = new FormData();

    Object.keys(json_data).forEach((key) => {
      if (
        !Array.isArray(json_data[key])
        // && typeof json_data[key] !== "object"
      ) {
        form_data.append(key, json_data[key]);
      } else {
        form_data.append(key, JSON.stringify(json_data[key]));
      }
    });

    return form_data;
  },

  RegexValidate: (type = `number`) => {
    if (type === `number`) {
      return /^[+-]?([0-9]+([.][0-9]+)?|[.][0-9]+)$/;
    } else if (type === `phone`) {
      // return /^(?:\+66|0)[689]\d{7,8}$/;
      return /^(?:\+66|0)(?:[689]\d{7,8}|[2]\d{7,8})$/;
    } else if (type === `email`) {
      return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    } else if (type === `float_2_decimal`) {
      return /^\d+(\.\d{2})?$/;
    } else if (type === `front_license_plate`) {
      // return /^[ก-ฮ0-9]{2}$/;
      return /^[ก-ฮ0-9]{2,3}$/;
    } else if (type === `center_license_plate`) {
      // return /^\d{4}$/;
      // return /^\d{3,4}$/;
      return /^\d{1,4}$/;
    }
  },
  getParam(body) {
    let param = '';
    let key = Object.keys(body);
    key.forEach((e, i) => {
      if (typeof body[e] === 'object') {
        body[e].forEach((ob, obi) => {
          if (obi !== body[e].length - 1 || i !== key.length - 1) {
            param += e + '=' + ob + '&';
          } else {
            param += e + '=' + ob;
          }
        });
      } else {
        if (i !== key.length - 1) {
          param += e + '=' + body[e] + '&';
        } else {
          param += e + '=' + body[e];
        }
      }
    });
    return param;
  },
  sanitizeInput(input) {
    return input.replace(/ {2,}/g, ' ');
  },
  formatNumeric(value, type = 'value', precision = '2') {
    if (type === 'value') {
      return currency(value, { precision: precision }).value;
    } else if (type === 'display') {
      return currency(value, {
        precision: precision,
        separator: ',',
        pattern: '#',
      }).format();
    }
  },
  isJpgFile: ({ file = null }) => {
    if (!file) {
      return false;
    } else if (!file.type.includes('image/jpeg')) {
      return false;
    }

    return true;
  },
  isImageFile: ({ file = null }) => {
    if (!file) {
      return false;
    }

    const allowedTypes = ['image/jpeg', 'image/png'];

    // Check if the file's MIME type is in the allowed list
    if (allowedTypes.includes(file.type)) {
      return true;
    }

    return false;
  },
  isPdfFile: ({ file = null }) => {
    if (!file) {
      return false;
    }

    if (file.type !== 'application/pdf') {
      return false;
    }

    return true;
  },
  isTxtFile: ({ file = null }) => {
    if (!file) {
      return false;
    } else if (!file.type.includes('text/plain')) {
      return false;
    }

    return true;
  },
  isCsvOrXlsxfile: ({ file = null }) => {
    if (!file) {
      return false;
    }

    const allowedExtensions = ['.csv', '.xlsx', '.xls'];
    const fileName = file.name.toLowerCase();
    const fileExtension = fileName.substring(fileName.lastIndexOf('.'));

    if (allowedExtensions.includes(fileExtension)) {
      return true;
    }

    return false;
  },
  isFileOverSize: ({ file = null, size = 10 }) => {
    //size is number of MB size

    if (!file) {
      return true;
    } else if (file.size > size * 1024 * 1024) {
      return true;
    }

    return false;
  },
  convertFileSizeToPixel: async ({ file = null }) => {
    if (!file) {
      return { width: null, height: null };
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);

    const { width, height } = await new Promise((resolve) => {
      img.onload = () => {
        URL.revokeObjectURL(img.src);
        resolve({ width: img.width, height: img.height });
      };
    });

    return { width: width, height: height };
  },

  filteredParams(queryParams) {
    const result = Object.fromEntries(
      Object.entries(queryParams).filter(([key, value]) => {
        return value !== '' && (!Array.isArray(value) || value.length > 0);
      }),
    );
    return result;
  },

  useWindowSize: () => {
    const [windowSize, setWindowSize] = useState({
      windowWidth: undefined,
      windowHeight: undefined,
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener('resize', handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
  },
  addhtpps(url) {
    if (url.includes('https://') || url.includes('http://')) {
      return url;
    } else {
      return 'http://' + url;
    }
  },

  formatThaiCitizenId: (value) => {
    const sanitizedValue = String(value).replace(/[-\D]/g, '');
    // Insert hyphens at appropriate positions
    const formattedValue = sanitizedValue.replace(
      /(\d{1,1})(\d{4,4})(\d{5,5})(\d{2,2})(\d{1,1})/,
      '$1-$2-$3-$4-$5',
    );
    return formattedValue;
    return value ? formattedValue : '';
  },

  validateFile: (file) => {
    const isUrl = typeof file === 'string';
    if (!isUrl) {
      const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
      const maxSize = 10 * 1024 * 1024; // 10MB

      if (!file) {
        return true; // No error when the file is empty
      }

      if (!allowedTypes.includes(file.type)) {
        return 'อัพโหลดไฟล์นามสกุลไฟล์ JPG PNG และ PDF เท่านั้น และขนาดไม่เกิน 10 MB';
      }
      if (file.size > maxSize) {
        return 'อัพโหลดไฟล์นามสกุลไฟล์ JPG PNG และ PDF เท่านั้น และขนาดไม่เกิน 10 MB';
      }
      return true;
    }
  },
  setDateLocale(datetime) {
    if (datetime.toString().includes(`GMT+0700`)) {
      return new Date(datetime);
    } else {
      let year = parseInt(moment(datetime).format('YYYY'));
      let dd = moment(datetime).get('date');
      let month = moment(datetime).get('month');
      let h = moment(datetime).format('HH');
      let m = moment(datetime).format('mm');
      let date = new Date(Date.UTC(year, month, dd, h, m));
      return date;
    }
  },
  momentTwoDate(startDate, endDate, type = 'short', utc = false) {
    let start = this.setDateLocale(startDate);
    let end = this.setDateLocale(endDate);
    let year = parseInt(moment(start).format('YYYY')) + (utc ? 543 : 0);
    let dd = moment(start).get('date');
    let month;
    if (type == 'short') {
      month = monthsShort[moment(start).get('month')];
    } else {
      month = monthsFull[moment(start).get('month')];
    }

    let yearEnd = parseInt(moment(end).format('YYYY')) + (utc ? 543 : 0);
    let ddEnd = moment(end).get('date');
    // let monthEnd = monthsShort[moment(end).get('month')];
    let monthEnd;
    if (type == 'short') {
      monthEnd = monthsShort[moment(end).get('month')];
    } else {
      monthEnd = monthsFull[moment(end).get('month')];
    }
    if (monthEnd === month) {
      if (dd === ddEnd) {
        return dd + ' ' + month + ' ' + year;
      } else {
        return dd + ' - ' + ddEnd + ' ' + month + ' ' + year;
      }
    } else {
      if (year === yearEnd) {
        return dd + ' ' + month + ' - ' + ddEnd + ' ' + monthEnd + ' ' + year;
      } else {
        return (
          dd +
          ' ' +
          month +
          ' ' +
          year +
          ' - ' +
          ddEnd +
          ' ' +
          monthEnd +
          ' ' +
          year
        );
      }
    }
  },
  momentDateTime(datetime, type = 'short', utc = false) {
    // console.log(datetime);
    // datetime += '+7000';
    let year = parseInt(moment(datetime).format('YYYY'));
    let dd = moment(datetime).get('date');
    // console.log(dd);
    let month = moment(datetime).get('month');
    let h = moment(datetime).format('HH');
    let m = moment(datetime).format('mm');
    let date;
    if (utc) {
      date = new Date(Date.UTC(year, month, dd, h, m));
    } else {
      date = new Date(year, month, dd, h, m);
    }

    if (date) {
      let year = parseInt(moment(date).format('YYYY')) + 543;
      let dd = moment(date).get('date');
      let month;
      if (type == 'short') {
        month = monthsShort[moment(date).get('month')];
      } else {
        month = monthsFull[moment(date).get('month')];
      }
      let time = moment(date).format('HH:mm');
      return dd + ' ' + month + ' ' + year + ' ' + time + ' น.';
    } else {
      return '-';
    }
  },
  convertDistance(distance, time) {
    let allDistance = '';
    let allInterval = '';
    let order_interval;
    let order_distance;
    // const { data } = response;
    // console.log(response, 'fasdfasfas');
    if (allDistance === '' || allInterval === '') {
      allDistance = parseFloat(distance / 1000);
      //จากวินาทีเป็นนาที
      allInterval = parseFloat(time / 60);
    } else {
      allDistance = parseFloat(allDistance) + parseFloat(distance / 1000);
      // เวลาตำแน่งใหม่บวกกัน จากวินาทีเป็นนาที
      allInterval = parseFloat(allInterval) + parseFloat(time / 60);
    }
    order_distance = allDistance.toFixed(2);
    if (allInterval.toFixed(2) > 60) {
      // จากนาทีเป็น ชม
      let interval = parseFloat(allInterval / 60);
      // job.orderDetail.order_interval =
      //     interval.toFixed(2) + " ชม.";
      let string = interval.toFixed(2) + '';
      let time = string.split('.');
      if (time.length === 2) {
        if (time[1] > 60) {
          let int = parseInt(time[1]);
          let min = parseFloat(int / 60);
          let intH = parseInt(time[0]);
          let hours = parseFloat(intH + min);
          let hToS = hours.toFixed(2).toString();
          let showTime = hToS.split('.');
          if (showTime[1] === 60) {
            order_interval = parseInt(showTime[0]) + 1 + ' ชม. ';
          } else {
            order_interval = showTime[0] + ' ชม. ' + showTime[1] + ' นาที';
          }
        } else {
          // console.log(
          //     "testt",
          //     time[0] + " ชม. " + time[1] + " นาที"
          // );
          if (time[1] === 60) {
            order_interval = parseInt(time[0]) + 1 + ' ชม. ';
          } else {
            order_interval = time[0] + ' ชม. ' + time[1] + ' นาที';
          }
        }
      }
      // else {
      //     console.log("test", time);
      //     job.orderDetail.order_interval =
      //         time[0] + " ชม. " + time[1] + " นาที";
      // }
    } else {
      if (allInterval % 1 !== 0) {
        order_interval =
          Math.floor(allInterval) +
          ' นาที ' +
          (60 * (allInterval % 1)).toFixed(0) +
          ' วินาที';
      } else {
        order_interval = allInterval.toFixed(0) + ' นาที';
      }
    }
    // console.log('asdfasdfasdf');
    // console.log(order_interval, order_distance, 'fasdfasdfasdf');
    return {
      allInterval: allInterval,
      allDistance: allDistance,
      orderDistance: order_distance,
      orderInterval: order_interval,
    };
  },
  addComma(number) {
    if (number) {
      number = parseFloat(number);
      return number
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return '-';
    }
  },
  addCommaWith2digit(number) {
    if (number) {
      const numberF = parseFloat(number);
      return numberF.toLocaleString('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return '0';
    }
  },
  toGMTPlus7(date) {
    const utc = new Date(date);
    utc.setHours(utc.getHours() + 7); // Adding 7 hours for GMT+7 (Bangkok time)
    return utc.toISOString();
  },
  workDetailProduct(data, isTemplate = false) {
    data = data.sort((a, b) => a.id - b.id);

    let product = data.map((e) => {
      let prod;
      if (isTemplate) {
        prod = e.templateWorkLocationProducts.map((p) => {
          let prod_img = [];
          if (
            p.work_location_product_image &&
            p.work_location_product_image.length > 0
          ) {
            prod_img = p.work_location_product_image.map((i) => {
              return { src: this.addhtpps(i.image) };
            });
          }

          return {
            name: p.product.name,
            product_id: p.product.id,
            image: this.pathImage() + p.product.image,
            example: p.product.example,
            product_name: p.product_name,
            product_size: p.stl_standard_size ? p.stl_standard_size.id : '',
            product_size_name: p.stl_standard_size
              ? p.stl_standard_size.name.split(' ')[1]
              : '',
            product_width: p.width,
            product_length: p.length,
            product_height: p.height,
            product_volume: p.volume,
            product_weight: p.weight,
            product_count_type: p.count_type,
            product_amount: p.amount,
            product_image: prod_img,
            product_image_per_route: [],
          };
        });
      } else {
        prod = e.workLocationProducts.map((p) => {
          let prod_img = p.work_location_product_image.map((i) => {
            return { src: this.addhtpps(i.image) };
          });
          return {
            name: p.product.name,
            product_id: p.product.id,
            image: this.pathImage() + p.product.image,
            example: p.product.example,
            product_name: p.product_name,
            product_size: p.stl_standard_size ? p.stl_standard_size.id : '',
            product_size_name: p.stl_standard_size
              ? p.stl_standard_size.name.split(' ')[1]
              : '',
            product_width: p.width,
            product_length: p.length,
            product_height: p.height,
            product_volume: p.volume,
            product_weight: p.weight,
            product_count_type: p.count_type,
            product_amount: p.amount,
            product_image: prod_img,
            product_image_per_route: [],
          };
        });
      }

      return {
        type: e.location_type,
        name: e.location_name,
        lat: e.lat,
        long: e.long,
        address: e.address,
        district: e.district,
        subdistrict: e.subdistrict,
        province: e.province,
        postal_code: e.postal_code,
        contact_name: e.contact_name,
        contact_phone: e.contact_phone,
        place_name: e.address,
        place_type_id: e.place_type,
        remark: e.remark,
        document: e.document,
        document_file: [],
        product: prod,
      };
    });
    return product;
  },
  pathImage() {
    return `${process.env.REACT_APP_MINIO_ENDPOINT}/${process.env.REACT_APP_MINIO_BUCKET}/`;
  },
  secToText(allInterval) {
    let order_interval = '';
    if (allInterval.toFixed(2) > 60) {
      // จากนาทีเป็น ชม
      let interval = parseFloat(allInterval / 60);
      // job.orderDetail.order_interval =
      //     interval.toFixed(2) + " ชม.";
      let string = interval.toFixed(2) + '';
      let time = string.split('.');
      if (time.length === 2) {
        if (time[1] > 60) {
          let int = parseInt(time[1]);
          let min = parseFloat(int / 60);
          let intH = parseInt(time[0]);
          let hours = parseFloat(intH + min);
          let hToS = hours.toFixed(2).toString();
          let showTime = hToS.split('.');
          if (showTime[1] === 60) {
            order_interval = parseInt(showTime[0]) + 1 + ' ชม. ';
          } else {
            order_interval =
              showTime[0] +
              ' ชม. ' +
              ((showTime[1] / 100) * 60).toFixed(0) +
              ' นาที';
          }
        } else {
          // console.log(
          //     "testt",
          //     time[0] + " ชม. " + time[1] + " นาที"
          // );
          if (time[1] === 60) {
            order_interval = parseInt(time[0]) + 1 + ' ชม. ';
          } else {
            order_interval =
              time[0] + ' ชม. ' + ((time[1] / 100) * 60).toFixed(0) + ' นาที';
          }
        }
      }
      // else {
      //     console.log("test", time);
      //     job.orderDetail.order_interval =
      //         time[0] + " ชม. " + time[1] + " นาที";
      // }
    } else {
      if (allInterval % 1 !== 0) {
        order_interval =
          Math.floor(allInterval) +
          ' นาที ' +
          60 * (allInterval % 1) +
          ' วินาที';
      } else {
        order_interval = allInterval.toFixed(0) + ' นาที';
      }
    }

    return order_interval;
  },
  downloadFile({ api_response = null, download_file_name = null }) {
    try {
      if (!R.isNil(api_response)) {
        const blob = new Blob([api_response.data], {
          type: api_response.headers['content-type'],
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        // a.download = "quotation_template.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  },
  connectSocketio() {
    return io(process.env.REACT_APP_API_ENPOINT, {
      auth: { Authorization: this.getCookie('access_token') },
    });
  },

  onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  },

  sortArray(array, reverse = false) {
    array.sort(function (a, b) {
      if (reverse) {
        return -(a.id - b.id || a.name.localeCompare(b.name));
      } else {
        return a.id - b.id || a.name.localeCompare(b.name);
      }
    });
    return array;
  },
};
