import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import Loading from 'components/loading/LoadingBackground';
import styles from './TableAllProjectInProfile.module.css';
import { useParams } from 'react-router-dom';
import { Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ButtonCustom from 'components/ButtonCustom';
import { Controller, useForm } from 'react-hook-form';
import { Typography } from '@mui/material';
import FieldInput from 'components/field/FieldInput';
import FieldSelect from 'components/field/FieldSelect';
import FieldUpload from 'components/field/FieldUpload';
import { getAllService } from 'utils/api/masterData/service';
import { addProject } from 'utils/api/project/project';
import {
  ProjectFrequencyType,
  WorkType,
  FreightType,
  PaymentType,
  ReminderDate,
} from 'utils/enum';

const ModalAddProject = (props) => {
  const [, , removeAccessToken] = useCookies(['access_token']);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  //master data
  const [serviceMaster, setServiceMaster] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm();

  const shipper_payment_type = watch('shipper_payment_type');

  useEffect(() => {
    console.log('step', step);
  }, [step]);

  //get All masterData
  useEffect(() => {
    getServiceMS();
  }, []);

  useEffect(() => {
    console.log('service', serviceMaster);
  }, [serviceMaster]);

  const getServiceMS = async () => {
    await getAllService()
      .then((response) => {
        console.log(response);
        let results = response.data.data.results;
        setServiceMaster(
          ...serviceMaster,
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          })) || [],
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handlePrevious = () => {
    if (step == 1) {
      props?.handleClose();
    } else if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleNext = async (data) => {
    console.log('data handleNext', data);
    if (step < 2) {
      setStep(step + 1);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    console.log('data', data);
    const GForm = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === 'reminder_date' && data['reminder_date'] !== undefined) {
        GForm.append(key, JSON.stringify(data[key]));
      } else if (data[key] === undefined) {
        GForm.delete(key);
      } else {
        GForm.append(key, data[key]);
      }
    });
    console.log('do add project');
    await addProject(GForm)
      .then((response) => {
        console.log(response);
        if (response.data.code === 1000) {
          props.handleClose();
          setLoading(false);
          props.alertSuccess();
        }
      })
      .catch((error) => {
        console.log('error', error);
        if (error) {
          setLoading(false);
          if (error.response.data.code === 4106) {
            props.alertError({
              open: true,
              desc: error.response.data.description,
            });
          } else if (error.response.data.code === '4105') {
            removeAccessToken('access_token');
            window.location.reload();
          }
        }
      });
  };

  const renderFormPage1 = () => {
    return (
      <div>
        <div className={styles.titleCard}>
          <Typography fontSize={16} color={'grey.main'} fontWeight={500}>
            ข้อมูลโครงการ
          </Typography>
        </div>
        <Controller
          name="name"
          control={control}
          rules={{
            required: 'กรุณากรอกชื่อโครงการ',
          }}
          render={({ field: { onChange, value } }) => (
            <div style={{ paddingTop: '20px' }}>
              <FieldInput
                label={'ชื่อโครงการ*'}
                subLabel={'(ค่าเริ่มต้น:โครงการเริ่มต้น)'}
                style={{ width: '100%' }}
                value={value}
                onChange={onChange}
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ''}
              />
            </div>
          )}
        />
        <Controller
          name="service_id"
          control={control}
          rules={{
            required: 'กรุณาเลือกประเภทบริการ',
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldSelect
                name={'ประเภทการบริการ*'}
                nameDescription={'(ค่าเริ่มต้น:เหมาคัน)'}
                style={{ width: '100%' }}
                options={serviceMaster}
                value={value}
                onChange={onChange}
                error={!!errors.service_id}
                errorText={errors.service_id ? errors.service_id.message : ''}
              />
            </div>
          )}
        />
        <Controller
          name="project_frequency_type"
          control={control}
          rules={{
            required: 'กรุณาเลือกความถี่งาน',
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldSelect
                name={'ความถี่งาน*'}
                nameDescription={'(ค่าเริ่มต้น:ชั่วคราเที่ยวเดียว)'}
                style={{ width: '100%' }}
                options={ProjectFrequencyType}
                value={value}
                onChange={onChange}
                error={!!errors.project_frequency_type}
                errorText={
                  errors.project_frequency_type
                    ? errors.project_frequency_type.message
                    : ''
                }
              />
            </div>
          )}
        />
        <Controller
          name="work_type"
          control={control}
          rules={{
            required: 'กรุณาเลือกลักษณะงาน',
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldSelect
                name={'ลักษณะงาน*'}
                nameDescription={'(ค่าเริ่มต้น:งานภายในประเทศ)'}
                style={{ width: '100%' }}
                options={WorkType}
                value={value}
                onChange={onChange}
                error={!!errors.work_type}
                errorText={errors.work_type ? errors.work_type.message : ''}
              />
            </div>
          )}
        />
        <Controller
          name="has_truck_check"
          control={control}
          rules={{
            required: 'กรุณาเลือกการตรวจสภาพรถ',
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldSelect
                name={'มีการตรวจสภาพรถ*'}
                nameDescription={'(ค่าเริ่มต้น:ไม่ใช่)'}
                style={{ width: '100%' }}
                options={[
                  { value: 'true', name: 'ใช่' },
                  { value: 'false', name: 'ไม่ใช่' },
                ]}
                value={value}
                onChange={onChange}
                error={!!errors.has_truck_check}
                errorText={
                  errors.has_truck_check ? errors.has_truck_check.message : ''
                }
              />
            </div>
          )}
        />
        <Controller
          name="has_driver_training"
          control={control}
          rules={{
            required: 'กรุณาเลือกการอบรมพนักงานขับรถ',
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldSelect
                name={'มีการอบรมพนักงานขับรถ*'}
                nameDescription={'(ค่าเริ่มต้น:ไม่ใช่)'}
                style={{ width: '100%' }}
                options={[
                  { value: 'true', name: 'ใช่' },
                  { value: 'false', name: 'ไม่ใช่' },
                ]}
                value={value}
                onChange={onChange}
                error={!!errors.has_driver_training}
                errorText={
                  errors.has_driver_training
                    ? errors.has_driver_training.message
                    : ''
                }
              />
            </div>
          )}
        />
        <Controller
          name="has_driver_health"
          control={control}
          rules={{
            required: 'กรุณาเลือกการตรวจสุขภาพพนักงาน',
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldSelect
                name={'มีการตรวจสุขภาพพนักงาน*'}
                nameDescription={'(ค่าเริ่มต้น:ไม่ใช่)'}
                style={{ width: '100%' }}
                options={[
                  { value: 'true', name: 'ใช่' },
                  { value: 'false', name: 'ไม่ใช่' },
                ]}
                value={value}
                onChange={onChange}
                error={!!errors.has_driver_health}
                errorText={
                  errors.has_driver_health
                    ? errors.has_driver_health.message
                    : ''
                }
              />
            </div>
          )}
        />
        <Controller
          name="freight_type"
          control={control}
          rules={{
            required: 'กรุณาเลือกประเภทการคิดค่าขนส่ง',
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldSelect
                name={'ประเภทการคิดค่าขนส่ง*'}
                nameDescription={'(ค่าเริ่มต้น:งานขาไป)'}
                style={{ width: '100%' }}
                options={FreightType}
                value={value}
                onChange={onChange}
                error={!!errors.freight_type}
                errorText={
                  errors.freight_type ? errors.freight_type.message : ''
                }
              />
            </div>
          )}
        />
      </div>
    );
  };
  const renderFormPage2 = () => {
    return (
      <div>
        <div className={styles.titleCard}>
          <Typography fontSize={16} color={'grey.main'} fontWeight={500}>
            ข้อมูลโครงการ
          </Typography>
        </div>
        <Controller
          name="shipper_payment_type"
          control={control}
          rules={{
            required: 'กรุณาเลือกประเภทการชำระเงินของลูกค้า',
          }}
          render={({ field: { onChange, value } }) => (
            <div style={{ paddingTop: '20px' }}>
              <FieldSelect
                name={'ประเภทการชำระเงินของลูกค้า*'}
                nameDescription={'(ค่าเริ่มต้น:เงินสด)'}
                descriptionUnderField="กรณีเงินสดจะไม่สามารถระบุข้อมูลในช่องที่บังคับเฉพาะเครดิตได้"
                style={{ width: '100%' }}
                options={PaymentType}
                value={value}
                onChange={onChange}
                error={!!errors.shipper_payment_type}
                errorText={
                  errors.shipper_payment_type
                    ? errors.shipper_payment_type.message
                    : ''
                }
              />
            </div>
          )}
        />
        <Controller
          name="credit_term"
          control={control}
          rules={{
            required:
              shipper_payment_type === 0
                ? false
                : 'กรุณากรอกจำนวนวันเครดิตเทอม',
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldInput
                label={'จำนวนวันเครดิตเทอม*'}
                subLabel={'(บังคับถ้าเป็นเครดิต)'}
                style={{ width: '100%' }}
                value={value}
                onChange={onChange}
                readOnly={shipper_payment_type === 0 ? true : false}
                error={!!errors.credit_term}
                helperText={
                  errors.credit_term ? errors.credit_term.message : ''
                }
              />
            </div>
          )}
        />
        <Controller
          name="credit_length_date"
          control={control}
          rules={{
            required:
              shipper_payment_type === 0
                ? false
                : 'กรุณากรอกรอบเวลาที่นับการใช้งาน',
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldInput
                label={'รอบเวลาที่นับการใช้งาน*'}
                subLabel={'(บังคับถ้าเป็นเครดิต)'}
                style={{ width: '100%' }}
                value={value}
                onChange={onChange}
                readOnly={shipper_payment_type === 0 ? true : false}
                error={!!errors.credit_length_date}
                helperText={
                  errors.credit_length_date
                    ? errors.credit_length_date.message
                    : ''
                }
              />
            </div>
          )}
        />
        <Controller
          name="credit_bill_date"
          control={control}
          rules={{
            required:
              shipper_payment_type === 0
                ? false
                : 'กรุณากรอกวันที่วางบิลของทุกเดือน',
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldInput
                label={'วันที่วางบิลของทุกเดือน*'}
                subLabel={'(บังคับถ้าเป็นเครดิต)'}
                style={{ width: '100%' }}
                value={value}
                onChange={onChange}
                readOnly={shipper_payment_type === 0 ? true : false}
                error={!!errors.credit_bill_date}
                helperText={
                  errors.credit_bill_date ? errors.credit_bill_date.message : ''
                }
              />
            </div>
          )}
        />
        <Controller
          name="credit_remark"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldInput
                label={'หมายเหตุเครดิต'}
                style={{ width: '100%' }}
                readOnly={shipper_payment_type === 0 ? true : false}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <Controller
          name="reminder_date"
          control={control}
          rules={{
            required:
              shipper_payment_type === 0
                ? false
                : 'กรุณากรอกวันที่ต้องการเตือนวางบิล',
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldSelect
                name="วันที่ต้องการเตือนวางบิล*"
                nameDescription={'(บังคับถ้าเป็นเครดิต)'}
                options={ReminderDate}
                onChange={onChange}
                value={value}
                readOnly={shipper_payment_type === 0 ? true : false}
                multiSelect={true}
                defaultValue={[]}
                error={!!errors.reminder_date}
                helperText={
                  errors.reminder_date ? errors.reminder_date.message : ''
                }
              />
            </div>
          )}
        />
        <Controller
          name="contrat_file"
          control={control}
          rules={{
            required:
              shipper_payment_type === 0 ? false : 'กรุณาอัพโหลดไฟล์สัญญา',
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldUpload
                name="ไฟล์สัญญา"
                readOnly={shipper_payment_type === 0 ? true : false}
                value={value}
                onChange={onChange}
                error={!!errors.contrat_file}
                textError={
                  errors.contrat_file ? errors.contrat_file.message : ''
                }
              />
            </div>
          )}
        />
        <Controller
          name="credit_limit"
          control={control}
          rules={{
            required:
              shipper_payment_type === 0
                ? false
                : 'กรุณากรอกวงเงินเครดิตที่ได้รับ',
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldInput
                label="วงเงินเครดิตที่ได้รับ*"
                subLabel="(บังคับถ้าเป็นเครดิต)"
                style={{ width: '100%' }}
                value={value}
                onChange={onChange}
                readOnly={shipper_payment_type === 0 ? true : false}
                error={!!errors.credit_limit}
                helperText={
                  errors.credit_limit ? errors.credit_limit.message : ''
                }
              />
            </div>
          )}
        />
        <Controller
          name="is_public"
          control={control}
          rules={{
            required: 'กรุณาเลือกว่าเป็นโครงการสาธารณะหรือไม่?',
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldSelect
                name={'โครงการสาธารณะ*'}
                nameDescription={'(ค่าเริ่มต้น:ใช่)'}
                style={{ width: '100%' }}
                options={[
                  { value: 'true', name: 'ใช่' },
                  { value: 'false', name: 'ไม่ใช่' },
                ]}
                value={value}
                onChange={onChange}
                error={!!errors.is_public}
                errorText={errors.is_public ? errors.is_public.message : ''}
              />
            </div>
          )}
        />
      </div>
    );
  };
  return (
    <div>
      {loading && <Loading loading={loading} />}
      <Modal
        open={props?.open}
        onClose={(event, reason) =>
          reason !== 'backdropClick' ? props?.handleClose : ''
        }
        sx={{ zIndex: 1000 }}
      >
        <div className={styles.modalStyle}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseIcon
              sx={{ color: 'grey.main' }}
              onClick={props?.handleClose}
            />
          </div>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <form>
              {step === 1 && renderFormPage1()}
              {step === 2 && renderFormPage2()}
            </form>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name="ย้อนกลับ"
                  onClick={handlePrevious}
                  type={'cancel'}
                />
              </div>
              {step < 2 && (
                <div style={{ flex: 0.5 }}>
                  <ButtonCustom
                    name="ถัดไป"
                    onClick={handleSubmit(handleNext)}
                    fullWidth={true}
                  />
                </div>
              )}
              {step === 2 && (
                <div style={{ flex: 0.5 }}>
                  <ButtonCustom
                    name={'บันทึก'}
                    // type="submit"
                    onClick={handleSubmit(onSubmit)}
                    fullWidth={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalAddProject;
