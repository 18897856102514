import React from "react";
import { Button } from "antd";
import { IconButton, InputLabel } from "@mui/material";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { CheckBox } from "@mui/icons-material";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
const CheckboxOutline = ({ onClick, classNames, styles, value, label }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // alignItems: "center",
        // justifyContent: "center",
      }}
      onClick={onClick}
    >
      <IconButton
        className={classNames}
        variant="text"
        style={{
          ...styles,
        }}
      >
        {value ? (
          <CheckBox sx={{ color: "#121f43" }} />
        ) : (
          <CheckBoxOutlinedIcon sx={{ color: "#8ca3ba" }} />
        )}
      </IconButton>
      <InputLabel
        htmlFor="standard-adornment-password"
        className=""
        style={{
          cursor: "pointer",
          color: value ? "#121f43" : "#8ca3ba",
          flex: 1,
        }}
      >
        {label}
      </InputLabel>
    </div>
  );
};

export default CheckboxOutline;
