import React, { useState, useEffect } from 'react';
import DrawerContent from '../Drawer';
import { Avatar, Button } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { AccountCircle } from '@mui/icons-material';
import Logo from 'assets/images/WemoveLogo.png';
import LaungugeIcon from 'assets/icons/thailand.svg';
import modeIcon from 'assets/icons/moon.svg';
import NotiIcon from 'assets/icons/bell.svg';
import { useCookies } from 'react-cookie';
import { getUserLoggedIn } from 'utils/api/user/user';
const Header = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [, , removeAccessToken] = useCookies(['access_token']);
  useEffect(() => {
    setTimeout(async () => {
      await getUserLoggedIn()
        .then((res) => {
          if (res.data.code === 1000) {
            setData(res.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.response.data.code === '4105') {
            removeAccessToken('access_token');
            window.location.reload();
          }
        });
    }, 2000);
  }, []);
  return (
    <React.Fragment>
      <div
        style={{
          height: 90,
          width: '100%',
          position: 'fixed',
          boxShadow: '0px 4px 4px 0px rgba(140, 163, 186, 0.10)',
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px',
          zIndex: 100,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={() => setOpen(true)}
            sx={{ ':hover': { backgroundColor: 'whenhover' } }}
          >
            <MenuRoundedIcon sx={{ fontSize: 50, color: 'grey.main' }} />
          </Button>
          <div style={{ paddingLeft: '10px' }}>
            <img src={Logo} height={'50px'} alt="logo wemive" />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{ paddingLeft: 15 }}>
            <Avatar
              height="50px"
              width="50px"
              sx={{
                bgcolor: 'white',
                boxShadow: '0px 4px 4px 0px rgba(140, 163, 186, 0.20)',
              }}
            >
              <img src={LaungugeIcon} height={25} alt="" />
            </Avatar>
          </div>
          <div style={{ paddingLeft: 15 }}>
            <Avatar
              height="50px"
              width="50px"
              sx={{
                bgcolor: 'white',
                boxShadow: '0px 4px 4px 0px rgba(140, 163, 186, 0.20)',
              }}
            >
              <img src={modeIcon} height={25} alt="" />
            </Avatar>
          </div>
          <div style={{ paddingLeft: 15 }}>
            <Avatar
              height="50px"
              width="50px"
              sx={{
                bgcolor: 'white',
                boxShadow: '0px 4px 4px 0px rgba(140, 163, 186, 0.20)',
              }}
            >
              <img src={NotiIcon} height={25} alt="" />
            </Avatar>
          </div>
          <div style={{ paddingLeft: 15 }}>
            <div>
              {data?.image ? (
                <img
                  src={`http://${data?.image}`}
                  height={'50px'}
                  width={'50px'}
                  style={{
                    objectFit: 'cover',
                    borderRadius: '10px',
                    display: 'block',
                    margin: 'auto',
                  }}
                  alt=""
                />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    width: '50px',
                    height: '50px',
                    backgroundColor: `rgba(140, 163, 186, 0.2)`,
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '10px',
                  }}
                >
                  <AccountCircle
                    sx={{
                      fontSize: 32,
                      color: 'grey.main',
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <DrawerContent
        openDrawer={open}
        onClose={() => setOpen(false)}
        user_info={data}
      />
    </React.Fragment>
  );
};

export default Header;
