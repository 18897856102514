import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../pages/home/Home';
import Error from '../pages/error/Error';
import PrivateRoutes from 'utils/PrivateRoutes';
import DashboardKPI from 'pages/dashboard/DashboardKPI';
import DashbordWork from 'pages/dashboard/DashboardWork';
import TableUser from 'pages/user/TableUser';
import FavoriteShipperGroup from 'pages/favoriteShipperGroup/FavoriteShipperGroup';
import FavoriteShipperGroupInfo from 'pages/favoriteShipperGroup/FavoriteShipperGroupInfo';
import CarrierInFavoriteGroup from 'pages/carrierInFavoriteGroup/CarrierInFavoriteGroup';
import CarrierInFavoriteGroupInfo from 'pages/carrierInFavoriteGroup/CarrierInFavoriteGroupInfo';
import ProfileReceiptName from 'pages/profileReceiptName/ProfileReceiptName';
import ProfileReceiptNameInfo from 'pages/profileReceiptName/ProfileReceiptNameInfo';
import Login from 'pages/auth/Login';
import ForgetPassword from 'pages/auth/ForgetPassword';
import ResetPassword from 'pages/auth/ResetPassword';
import ExportData from 'pages/dashboard/ExportData';
import TableAllProjectInProfile from 'pages/project/TableAllProjectInProfile';
import DetailUser from 'pages/user/DetailUser';
import DetailProfile from 'pages/profile/DetailProfile';
import DetailProject from 'pages/project/DetailProject';
import TableFavoritePlace from 'pages/profile/favoritePlace/TableFavoritePlace';
import DetailFavoritePlace from 'pages/profile/favoritePlace/DetailFavoritePlace';

const routes = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Home />} />
            <Route path="/dashboard/work" element={<DashbordWork />} exact />
            <Route path="/dashboard/kpi" element={<DashboardKPI />} exact />
            <Route
              path="/dashboard/export-data"
              element={<ExportData />}
              exact
            />
            <Route path="/user" element={<TableUser />} />
            <Route path="/user/:id" element={<DetailUser />} exact />
            <Route
              path="/user/:user_id/profile/:profile_id"
              element={<DetailProfile />}
              exact
            />
            <Route
              path="/project/detail/:project_id"
              element={<DetailProject />}
              exact
            />
            <Route path="/project" element={<TableAllProjectInProfile />} />

            <Route
              path="/user/:user_id/:user_code/profile/:profile_id/favorite-place"
              element={<TableFavoritePlace />}
              exact
            />
            <Route
              path="/user/:user_id/:user_code/profile/:profile_id/favorite-place/detail/:favorite_place_id"
              element={<DetailFavoritePlace />}
              exact
            />
            <Route
              path="/user/:user_id/profile/:profile_id/shipper-favorite-group"
              element={<FavoriteShipperGroup />}
              exact
            />
            <Route
              path="/user/:user_id/profile/:profile_id/shipper-favorite-group/detail/:id"
              element={<FavoriteShipperGroupInfo />}
              exact
            />
            <Route
              path="/user/:user_id/profile/:profile_id/shipper-favorite-group/:shipper_favorite_group_id/carrier-in-favorite-group"
              element={<CarrierInFavoriteGroup />}
              exact
            />
            <Route
              path="/user/:user_id/profile/:profile_id/shipper-favorite-group/:shipper_favorite_group_id/carrier-in-favorite-group/:carrier_in_favorite_group_id"
              element={<CarrierInFavoriteGroupInfo />}
              exact
            />

            <Route
              path="/user/:user_id/profile/:profile_id/profile-receipt-name"
              element={<ProfileReceiptName />}
              exact
            />
            <Route
              path="/user/:user_id/profile/:profile_id/profile-receipt-name/detail/:profile_receipt_name_id"
              element={<ProfileReceiptNameInfo />}
              exact
            />

            <Route path="*" element={<Error />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      </Router>
    </div>
  );
};

export default routes;
