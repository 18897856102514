import instance from '../../axiosInstance';

export const getDashbaordWork = async (params = '') => {
  return await instance.get(`api/dashboard/tps/work`, {
    params: params,
  });
};

export const getDashbaordKPI = async (params = '') => {
  return await instance.get(`api/dashboard/tps/kpi`, {
    params: params,
  });
};

export const getDashbaordIcon = async (data) => {
  return await instance.post(`api/dashboard/icon`, data);
};
