import axios from 'axios';
import helper from './helper';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENPOINT,
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const getToken = () => {
  return helper.getCookie('access_token');
};

instance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default instance;
