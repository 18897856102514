import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Rating,
  Avatar,
  useTheme,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountCircle, StarRateRounded, Save } from '@mui/icons-material';
import { useCookies } from 'react-cookie';

import helper from 'utils/helper';
import styles from './DetailUser.module.css';
import SubMenu from 'components/layout/SubMenu.js';
import { useForm, Controller } from 'react-hook-form';
import { subUserID } from 'utils/submenuoptions';
import FieldSelect from 'components/field/FieldSelect';
import FieldUpload from 'components/field/FieldUpload';
import FieldInput from 'components/field/FieldInput';
import FieldPassword from 'components/field/FieldPassword';
import ButtonCustom from 'components/ButtonCustom';
import { getTruckType } from 'utils/api/masterData/truckType';
import FieldDatePicker from 'components/field/FieldDatePicker';
import AlertModal from 'components/modal/AlertModal';
import {
  getUserById,
  getDrivingLicense,
  getUserPermission,
  editUser,
  checkUniqueFields,
} from 'utils/api/user/user';
import { findCompanyName as findCompanyNameAPI } from 'utils/api/profile/profile';
import Loading from 'components/loading/LoadingBackground';
import { grey } from '@mui/material/colors';
import { ApproveStatus } from 'utils/enum';
// import { sendNoti } from "utils/api/noti/noti";
const DetailUser = () => {
  const { id } = useParams();
  const inputFile = useRef('');
  const theme = useTheme();
  const navigate = useNavigate();
  const [, , removeAccessToken] = useCookies(['access_token']);
  const [dataDetail, setDataDetail] = useState(null);
  const [masterTruck, setMasterTruck] = useState([]);
  const [masterDrivingLicense, setMasterDrivingLicense] = useState([]);
  const [permission, setPermission] = useState([]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});
  const [imageChange, setImageChange] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlert, setErrorAlert] = useState({ open: false, desc: '' });
  const [userCode, setUserCode] = useState('');
  const [roleM, setRoleM] = useState([]);
  const [roleS, setRoleS] = useState([]);
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm();

  const driver_approved_status = watch('driver_approved_status');
  const is_main = watch('is_main');
  const roles = watch('roles');
  const password = watch('password');
  const checkUserPermission = watch('userPermission');
  const driver_crime_history = watch('driver_crime_history');
  const driver_crime_history_consent = watch('driver_crime_history_consent');
  const driver_crime_history_file = watch('driver_crime_history_file');

  const MenuUser = [
    {
      label: 'ข้อมูลส่วนตัว',
      path: `/user/${id}`,
      active: true,
      header: 'ข้อมูลทั่วไป',
    },
    dataDetail?.is_main === true
      ? {
          label: 'ข้อมูลโปรไฟล์',
          path: `/user/${id}/profile/${dataDetail?.user_profile.profile.id}`,
        }
      : '',
    dataDetail?.is_main === true
      ? {
          label: 'สถานที่ที่บับทึกไว้',
          path: `/user/${id}/${dataDetail?.user_code}/profile/${dataDetail?.user_profile.profile.id}/favorite-place`,
          header: 'สำหรับลูกค้า',
        }
      : '',
    dataDetail?.is_main === true
      ? {
          label: 'กลุ่มผู้ให้บริการที่ชื่นชอบ',
          path: `/user/${id}/profile/${dataDetail?.user_profile.profile.id}/shipper-favorite-group`,
        }
      : '',
    dataDetail?.is_main === true
      ? {
          label: 'ข้อมูลใบเสร็จ',
          path: `/user/${id}/profile/${dataDetail.user_profile.profile.id}/profile-receipt-name`,
        }
      : '',
  ];

  const breadcrumbs = [
    {
      label: 'ข้อมูลผู้ใช้งาน',
      path: '/user',
    },
    {
      label: `${dataDetail?.user_code}`,
      path: '',
    },
    {
      label: 'ข้อมูลส่วนตัว',
      path: '',
    },
  ];

  useEffect(() => {
    console.log('dataDetail', dataDetail);
  }, [dataDetail]);

  useEffect(() => {
    if (driver_crime_history === 'true') {
      setValue('driver_crime_history_consent', '');
    } else if (driver_crime_history === 'false') {
      setValue('driver_crime_history_file', '');
    } else if (driver_crime_history === 'null') {
      setValue('driver_crime_history_consent', '');
      setValue('driver_crime_history_file', '');
      setValue('driver_crime_history', '');
    }
  }, [driver_crime_history]);

  //clear reason for edit when change driver_approved_status
  useEffect(() => {
    if (driver_approved_status !== 2) {
      setValue('reason_for_edit', '');
    }
  }, [driver_approved_status]);

  useEffect(() => {
    if (checkUserPermission && checkUserPermission.length === 0) {
      setError('userPermission', { message: 'กรุณาเลือกสิทธิ์การใช้งาน' });
    } else {
      clearErrors('userPermission');
    }
  }, [checkUserPermission]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      getUserById(id)
        .then((response) => {
          if (response.data.code === 1000) {
            const rest = response.data.data;
            const roles = [];

            if (rest.user_profile.user_profile_shipper.is_shipper === true) {
              roles.push('shipper');
            }

            if (rest.user_profile.user_profile_carrier.is_carrier === true) {
              roles.push('carrier');
            }

            if (rest.user_profile.user_profile_driver.is_driver === true) {
              roles.push('driver');
            }

            rest['roles'] = roles;
            setDataDetail(rest);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.code === 4106) {
            setErrorAlert({
              open: true,
              desc: error.response.data.description,
            });
          } else if (error.response.data.code === '4105') {
            removeAccessToken('access_token');
            window.location.reload();
          }
        });

      getTruckType({ sort_field: 'order_number', sort_order: 1 })
        .then((response) => {
          let results = response.data.data.results;
          setMasterTruck(
            ...masterTruck,
            results?.map((res) => ({
              value: res.id,
              name: res.name,
            })),
          );
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });

      getDrivingLicense()
        .then((response) => {
          let results = response.data.data.results;
          setMasterDrivingLicense(
            ...masterDrivingLicense,
            results?.map((res) => ({
              value: res.id,
              name: res.name,
            })),
          );
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });

      getUserPermission()
        .then((response) => {
          let results = response.data.data.results;
          if (response.data.code === 1000) {
            const perm = results.map((item) => {
              const { created_at, updated_at, ...rest } = item;
              return rest;
            });
            setPermission(perm);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, 2000);
  }, [id]);

  useEffect(() => {
    if (dataDetail && permission) {
      console.log('permissionnnn', permission);
      const mergePermission = permission?.map((itemPerm) => {
        const matchData = dataDetail?.user_profile?.userProfilePermission.find(
          (itemData) => itemPerm.id === itemData.user_permission_id,
        );
        if (matchData) {
          return { ...matchData, ...itemPerm, checked: true };
        }
        return itemPerm;
      });
      console.log('mergePermission', mergePermission);
      setItems(
        ...items,
        mergePermission?.map((res) => ({
          user_permission_id: res.id,
          name: res.name,
          visit: true,
          create: true,
          deleteP: true,
          edit: true,
          print: true,
          exportP: true,
          checked: res.checked ?? false,
        })),
      );
    }
  }, [dataDetail, permission]);

  useEffect(() => {
    if (dataDetail) {
      // Store initial values
      const newInitialFormValues = {};
      newInitialFormValues.image = dataDetail?.image ?? '';
      newInitialFormValues.active = String(dataDetail?.active) ?? '';
      newInitialFormValues.new_user = String(dataDetail?.new_user) ?? '';
      newInitialFormValues.is_main = String(dataDetail?.is_main) ?? '';
      newInitialFormValues.profile_id =
        dataDetail?.is_main === true ? '' : dataDetail?.user_profile.profile.id;
      newInitialFormValues.roles = dataDetail?.roles ?? '';
      newInitialFormValues.prefix = dataDetail?.prefix ?? '';
      newInitialFormValues.firstname = dataDetail?.firstname ?? '';
      newInitialFormValues.lastname = dataDetail?.lastname ?? '';
      newInitialFormValues.phone = dataDetail?.phone ?? '';
      newInitialFormValues.email = dataDetail?.email ?? '';
      newInitialFormValues.citizen_id = dataDetail?.citizen_id ?? '';
      newInitialFormValues.citizen_id_file = dataDetail?.citizen_id_file ?? '';
      newInitialFormValues.driver_truck_id = dataDetail?.driver_truck_id ?? '';
      newInitialFormValues.driving_license = dataDetail?.driving_license ?? '';
      newInitialFormValues.driver_license_front_file =
        dataDetail?.driver_license_front_file ?? '';
      newInitialFormValues.driver_license_back_file =
        dataDetail?.driver_license_back_file ?? '';
      newInitialFormValues.driver_license_expire_date =
        dataDetail?.driver_license_expire_date ?? '';
      newInitialFormValues.driver_crime_history =
        String(dataDetail?.driver_crime_history) ?? '';
      newInitialFormValues.driver_crime_history_file =
        dataDetail?.driver_crime_history_file ?? '';
      newInitialFormValues.driver_crime_history_consent =
        String(dataDetail?.driver_crime_history_consent) ?? '';
      newInitialFormValues.driver_test_status =
        dataDetail?.driver_test_status ?? '';
      newInitialFormValues.driver_approved_status =
        dataDetail?.driver_approved_status ?? '';
      newInitialFormValues.reason_for_edit = dataDetail?.reason_for_edit ?? '';

      const extractedData = dataDetail?.user_profile.userProfilePermission?.map(
        (item) => ({
          delete: item.delete,
          export: item.export,
          user_permission_id: item.user_permission_id,
          visit: item.visit,
          create: item.create,
          edit: item.edit,
          print: item.print,
        }),
      );
      newInitialFormValues.userPermission = extractedData ?? '';
      newInitialFormValues.profile =
        dataDetail?.is_main === true
          ? ''
          : {
              id: dataDetail?.user_profile.profile.id ?? '',
              company_name: dataDetail?.user_profile.profile.company_name ?? '',
            };
      console.log('newInitialFormValues', newInitialFormValues);
      setInitialFormValues(newInitialFormValues);
      dataDetail?.is_main === true
        ? setUserCode('')
        : setUserCode(dataDetail?.user_profile?.profile?.user_info?.user_code);
      dataDetail.is_main === true
        ? setRoleM(dataDetail.roles)
        : setRoleS(dataDetail.roles[0]);
      Object.keys(newInitialFormValues).forEach((fieldName) => {
        setValue(fieldName, newInitialFormValues[fieldName]);
      });
    }
  }, [dataDetail, setValue]);

  const handleIsMainChange = () => {
    if (is_main === 'true') {
      setValue('roles', roleM);
    } else if (is_main === 'false') {
      setValue('roles', roleS);
    }
  };

  useEffect(() => {
    console.log('rolesM', roleM);
    console.log('rolesS', roleS);
  }, [roleM, roleS]);

  useEffect(() => {
    handleIsMainChange();
  }, [is_main, roleM, roleS]);

  const validateImage = (file, fieldName) => {
    console.log('validateImage', file, fieldName);
    const allowedTypes = ['image/jpeg', 'image/png'];
    const maxSize = 10 * 1024 * 1024; // 10MB

    if (!allowedTypes.includes(file.type)) {
      setError(String(fieldName), {
        type: 'typeError',
        message:
          'อัพโหลดไฟล์นามสกุลไฟล์ JPG และ PNG เท่านั้น และขนาดไม่เกิน 10 MB',
      });
      return false;
    }

    if (file.size > maxSize) {
      setError(String(fieldName), {
        type: 'sizeError',
        message:
          'อัพโหลดไฟล์นามสกุลไฟล์ JPG และ PNG เท่านั้น และขนาดไม่เกิน 10 MB',
      });
      return false;
    }
    // clearErrors(String(fieldName));
    return true;
  };

  const validateFile = (file) => {
    const isUrl = typeof file === 'string';
    if (!isUrl) {
      const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
      const maxSize = 10 * 1024 * 1024; // 10MB

      if (!file) {
        return true; // No error when the file is empty
      }

      if (!allowedTypes.includes(file.type)) {
        return 'อัพโหลดไฟล์นามสกุลไฟล์ JPG PNG และ PDF เท่านั้น และขนาดไม่เกิน 10 MB';
      }
      if (file.size > maxSize) {
        return 'อัพโหลดไฟล์นามสกุลไฟล์ JPG PNG และ PDF เท่านั้น และขนาดไม่เกิน 10 MB';
      }
      return true;
    }
  };

  const SearchCompany = async (user_code) => {
    setLoading(true);
    await findCompanyNameAPI(user_code)
      .then((response) => {
        console.log('usercode', response.data.data);
        if (response.data.code === 1000) {
          setLoading(false);
          clearErrors('profile');
          setValue('profile', response.data.data);
          setValue('profile_id', response.data.data.id);
        }
      })
      .catch(async (error) => {
        setLoading(false);
        console.log(error);
        if (error.response.data) {
          setValue('profile', '');
          setValue('profile_id', '');
          setError('profile', {
            type: 400,
            message: error.response.data.description,
          });
        } else {
          clearErrors('profile');
        }
      });
  };

  const handleCheckboxClick = (itemId) => {
    console.log('itemIditemId', itemId);
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.user_permission_id === itemId
          ? { ...item, checked: !item['checked'] }
          : item,
      ),
    );
  };

  useEffect(() => {
    const modifiedItems = items?.map(
      ({ deleteP, exportP, name, checked, ...item }) =>
        checked === true && {
          delete: deleteP,
          export: exportP,
          ...item,
        },
    );
    const filteredItems = modifiedItems.filter((item) => {
      return Object.keys(item).some(
        (key) => key !== 'user_permission_id' && item[key],
      );
    });

    if (filteredItems) {
      setValue('userPermission', filteredItems);
    }
  }, [items]);

  const handleMaxDigit = (e, field, max) => {
    const newValue = e.target.value;
    // Limit input to a maximum of 10 characters
    if (newValue.length <= max) {
      setValue(field, newValue);
    }
    if (newValue.length > 0) {
      clearErrors(field);
    }
  };

  const onClick = (event) => {
    inputFile.current.click();
    event.stopPropagation();
  };

  const renderCard1 = () => {
    return (
      <div className={styles.cardDetail}>
        <Controller
          name="image"
          control={control}
          //   defaultValue={dataDetail?.image}
          render={({ field: { onChange, value } }) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                paddingTop: '39px',
              }}
            >
              <input
                style={{ display: 'none' }}
                ref={inputFile}
                accept="image/jpeg, image/png"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    validateImage(file, 'image');
                    setImageChange(true);
                    onChange(file);
                  }
                  // setLinkFile(URL.createObjectURL(e.target.files[0]));
                }}
                type="file"
              />

              {value ? (
                <img
                  src={
                    imageChange ? URL.createObjectURL(value) : `http://${value}`
                  }
                  width={'118px'}
                  height={'118px'}
                  alt="image profile"
                  style={{ objectFit: 'cover', borderRadius: '10px' }}
                  onClick={onClick}
                />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    width: '118px',
                    height: '118px',
                    backgroundColor: `rgba(140, 163, 186, 0.2)`,
                    alignItems: 'center',
                    justifyContent: 'center',

                    borderRadius: '10px',
                  }}
                  onClick={onClick}
                >
                  <AccountCircle
                    sx={{
                      fontSize: 134,
                      color: 'grey.main',
                    }}
                  />
                </div>
              )}
              {errors?.image && (
                <p
                  style={{ color: theme.palette.error.main, fontSize: '10px' }}
                >
                  {errors?.image.message}
                </p>
              )}
            </div>
          )}
        />

        <Typography fontSize={'20px'} fontWeight={500} sx={{ p: '13px' }}>
          {dataDetail?.user_code ? dataDetail?.user_code : ''}
        </Typography>
        <Typography
          fontSize={'14px'}
          fontWeight={300}
          fontStyle={'italic'}
          color={'grey.main'}
        >
          วันที่สร้างรายการ{' '}
          {dataDetail?.created_at
            ? helper.ToConvertDateTimeForTable(dataDetail?.created_at)
            : ''}
        </Typography>
        <Typography
          fontSize={'14px'}
          fontWeight={300}
          fontStyle={'italic'}
          color={'grey.main'}
        >
          วันที่แก้ไขข้อมูลล่าสุด{' '}
          {dataDetail?.updated_at
            ? helper.ToConvertDateTimeForTable(dataDetail?.updated_at)
            : ''}
        </Typography>
        <div className={styles.titleUserRole} style={{ paddingTop: '27px' }}>
          <Typography fontSize={16} color={'grey.main'} fontWeight={500}>
            ประเภทและบทบาทผู้ใช้งาน
          </Typography>
        </div>
        <Controller
          name="active"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ paddingTop: '20px', width: '100%' }}>
              <FieldSelect
                name={'สถานะใช้งาน*'}
                options={[
                  { value: 'true', name: 'ใช้งาน' },
                  { value: 'false', name: 'ระงับ' },
                ]}
                value={value}
                onChange={onChange}
                style={{ width: '-webkit-fill-available' }}
                error={!!errors.active}
                errorText={errors.active ? errors.active.message : ''}
              />
            </div>
          )}
        />
        <Controller
          name="is_main"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldSelect
                name={'ประเภทผู้ใช้งาน*'}
                options={[
                  { value: 'true', name: 'ผู้ใช้งานหลัก' },
                  { value: 'false', name: 'ผู้ใช้งานรอง' },
                ]}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  setItems(
                    items.map((res) => ({
                      ...res,
                      checked: false,
                    })),
                  );
                }}
                style={{ width: '-webkit-fill-available' }}
                error={!!errors.is_main}
                errorText={errors.is_main ? errors.is_main.message : ''}
              />
            </div>
          )}
        />

        <div style={{ width: '100%' }}>
          <FieldInput
            label="รหัสผู้ใช้งานหลัก*"
            subLabel={`(บังคับถ้าเพิ่มผู้ใช้รอง)`}
            style={{ width: '-webkit-fill-available' }}
            value={is_main === 'false' ? userCode : ''}
            onChange={(e) => setUserCode(e.target.value)}
            readOnly={is_main === 'true' ? true : false}
          />
        </div>
        <div style={{ width: '100%' }}>
          <ButtonCustom
            name="ค้นหา"
            onClick={() => SearchCompany(userCode)}
            fullWidth={true}
            disabled={is_main === 'true' ? true : false}
          />
        </div>
        <Controller
          name="profile"
          control={control}
          rules={{ required: is_main === 'false' ? true : false }}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%', paddingTop: '20px' }}>
              <FieldInput
                label="ชื่อโปรไฟล์"
                style={{ width: '-webkit-fill-available' }}
                onChange={onChange}
                readOnly={true}
                value={
                  is_main === 'true'
                    ? ''
                    : value?.company_name
                    ? value?.company_name
                    : ''
                }
                error={!!errors.profile}
                helperText={errors.profile ? 'ไม่พบโปรไฟล์' : ''}
              />
            </div>
          )}
        />

        {is_main === 'false' ? (
          <div style={{ width: '100%' }}>
            <FieldSelect
              name={'บทบาทที่ต้องใช้งานในโปรไฟล์*'}
              options={[
                { value: 'shipper', name: 'ลูกค้า' },
                { value: 'carrier', name: 'ผู้ขนส่ง' },
                { value: 'driver', name: 'พนักงานขับรถ' },
              ]}
              value={roleS}
              onChange={(e) => {
                setRoleS(e);
                setItems(
                  items.map((res) => ({
                    ...res,
                    checked: false,
                  })),
                );
              }}
              style={{ width: '-webkit-fill-available' }}
              error={dataDetail && roleS.length === 0 ? true : false}
              errorText={
                dataDetail && roleS.length === 0 ? 'กรุณาเลือกบทบาท' : ''
              }
            />
          </div>
        ) : (
          <div style={{ width: '100%' }}>
            <FieldSelect
              name={'บทบาทที่ต้องใช้งานในโปรไฟล์*'}
              options={[
                { value: 'shipper', name: 'ลูกค้า' },
                { value: 'carrier', name: 'ผู้ขนส่ง' },
                { value: 'driver', name: 'พนักงานขับรถ' },
              ]}
              value={roleM}
              onChange={(e) => {
                setRoleM(e);
                setItems(
                  items.map((res) => ({
                    ...res,
                    checked: false,
                  })),
                );
              }}
              defaultValue={[]}
              style={{ width: '-webkit-fill-available' }}
              multiSelect={true}
              error={dataDetail && roleM.length === 0 ? true : false}
              errorText={
                dataDetail && roleM.length === 0 ? 'กรุณาเลือกบทบาท' : ''
              }
            />
          </div>
        )}
      </div>
    );
  };
  const renderCard2 = () => {
    return (
      <div className={styles.cardDetail}>
        <div className={styles.titleUserRole} style={{ paddingTop: '27px' }}>
          <Typography fontSize={16} color={'grey.main'} fontWeight={500}>
            ข้อมูลส่วนบุคคล
          </Typography>
        </div>
        <Controller
          name="prefix"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%', paddingTop: '20px' }}>
              <FieldSelect
                name={'คำนำหน้า*'}
                options={[
                  { value: 0, name: 'นาย' },
                  { value: 1, name: 'นางสาว' },
                  { value: 2, name: 'นาง' },
                ]}
                value={value}
                onChange={onChange}
                style={{ width: '-webkit-fill-available' }}
                error={!!errors.prefix}
                errorText={errors.prefix ? errors.prefix.message : ''}
              />
            </div>
          )}
        />

        <Controller
          name="firstname"
          control={control}
          rules={{
            pattern: {
              value: /^[A-Za-zก-๏]+$/,
              message: 'ระบุเป็นภาษาไทยและภาษาอังกฤษได้เท่านั้น',
            },
          }}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldInput
                label="ชื่อ*"
                value={value}
                onChange={onChange}
                style={{ width: '-webkit-fill-available' }}
                error={!!errors.firstname}
                helperText={errors.firstname ? errors.firstname.message : ''}
              />
            </div>
          )}
        />
        <Controller
          name="lastname"
          control={control}
          rules={{
            pattern: {
              value: /^[A-Za-zก-๏ ]+$/,
              message: 'ระบุเป็นภาษาไทยและภาษาอังกฤษได้เท่านั้น',
            },
          }}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldInput
                label="นามสกุล*"
                value={value}
                onChange={onChange}
                style={{ width: '-webkit-fill-available' }}
                error={!!errors.lastname}
                helperText={errors.lastname ? errors.lastname.message : ''}
              />
            </div>
          )}
        />
        <Controller
          name="phone"
          control={control}
          rules={{
            pattern: {
              value: /^(?:\+66|0)[689]\d{8}$/,
              message: 'ระบุเป็นตัวเลขได้เท่านั้น',
            },
          }}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldInput
                label="เบอร์มือถือ*"
                type="number"
                value={value}
                onChange={(e) => {
                  handleMaxDigit(e, 'phone', 10);
                }}
                style={{ width: '-webkit-fill-available' }}
                error={!!errors.phone}
                helperText={errors.phone ? errors.phone.message : ''}
              />
            </div>
          )}
        />
        <Controller
          name="email"
          control={control}
          rules={{
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: 'รูปแบบอีเมลไม่ถูกต้อง',
            },
          }}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldInput
                label="อีเมล"
                value={value}
                onChange={onChange}
                style={{ width: '-webkit-fill-available' }}
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ''}
              />
            </div>
          )}
        />
        <Controller
          name="password"
          control={control}
          rules={{
            minLength: {
              value: 6,
              message:
                'รหัสผ่านต้องประกอบด้วยภาษาอังกฤษและตัวเลขอย่างน้อย 6 ตัว',
            },
            pattern: {
              value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@#$%^&+=!_\-]+$/,
              message:
                'รหัสผ่านต้องประกอบด้วยภาษาอังกฤษและตัวเลขอย่างน้อย 6 ตัว',
            },
          }}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldPassword
                name="เปลี่ยนรหัสผ่าน"
                style={{ width: '-webkit-fill-available' }}
                value={value}
                onChange={onChange}
                error={!!errors.password}
                textError={errors.password ? errors.password.message : ''}
              />
            </div>
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          rules={{
            minLength: {
              value: 6,
              message:
                'รหัสผ่านต้องประกอบด้วยภาษาอังกฤษและตัวเลขอย่างน้อย 6 ตัว',
            },
            pattern: {
              value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@#$%^&+=!_\-]+$/,
              message:
                'รหัสผ่านต้องประกอบด้วยภาษาอังกฤษและตัวเลขอย่างน้อย 6 ตัว',
            },
            validate: (value) => {
              if (!password) return true;
              return value === password || 'รหัสผ่านไม่ตรงกัน';
            },
          }}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldPassword
                name="ยืนยันรหัสผ่าน"
                style={{ width: '-webkit-fill-available' }}
                value={value}
                onChange={onChange}
                error={!!errors.confirmPassword}
                textError={
                  errors.confirmPassword ? errors.confirmPassword.message : ''
                }
              />
            </div>
          )}
        />
        <Controller
          name="citizen_id"
          control={control}
          rules={{
            required:
              roles === 'shipper' ||
              (roles?.length === 1 && roles[0] === 'shipper')
                ? false
                : 'กรุณากรอกรหัสประจำตัวบัตรประชาชน',
            pattern: {
              value: /^[0-9]+$/,
              message: 'ระบุเป็นตัวเลขได้เท่านั้น',
            },
            minLength: {
              value: 13,
              message: 'รหัสประจำตัวบัตรประชาชนต้องมี 13 ตัว',
            },
          }}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldInput
                label="รหัสประจำตัวบัตรประชาชน*"
                subLabel={`(บังคับเฉพาะผู้ใช้งานที่มีบทบาทเป็นผู้ขนส่งและพนักงานขับรถ)`}
                style={{ width: '-webkit-fill-available' }}
                value={helper.formatThaiCitizenId(value)}
                onChange={(e) => handleMaxDigit(e, 'citizen_id', 17)}
                error={!!errors.citizen_id}
                helperText={errors.citizen_id ? errors.citizen_id.message : ''}
              />
            </div>
          )}
        />
        <Controller
          name="citizen_id_file"
          control={control}
          rules={{
            required:
              roles === 'shipper' ||
              (roles?.length === 1 && roles[0] === 'shipper')
                ? false
                : 'กรุณาอัพโหลดไฟล์บัตรประจำตัวประชาชน',
            validate: validateFile,
          }}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldUpload
                name="ไฟล์บัตรประจำตัวประชาชน*"
                accept="image/jpeg, image/png, application/pdf"
                nameDescription={`(บังคับเฉพาะผู้ใช้งานที่มีบทบาทเป็นผู้ขนส่งและพนักงานขับรถ)`}
                style={{ width: '-webkit-fill-available' }}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('citizen_id_file');
                }}
                error={errors.citizen_id_file}
                textError={
                  errors.citizen_id_file
                    ? errors.citizen_id_file.message
                    : false
                }
              />
            </div>
          )}
        />
        <Controller
          name="new_user"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldSelect
                name={'ยืนยัน OTP*'}
                options={[
                  { value: 'false', name: 'ใช่' },
                  { value: 'true', name: 'ไม่' },
                ]}
                value={value}
                readOnly={true}
                // onChange={onChange}
                style={{ width: '-webkit-fill-available' }}
                // error={!!errors.prefix}
                // errorText={errors.prefix ? errors.prefix.message : ""}
              />
            </div>
          )}
        />
      </div>
    );
  };
  // const renderCard3 = () => {
  //   return (
  //     <div className={styles.cardDetail}>
  //       <div className={styles.titleUserRole} style={{ paddingTop: '27px' }}>
  //         <Typography fontSize={16} color={'grey.main'} fontWeight={500}>
  //           ข้อมูลคนขับ
  //         </Typography>
  //       </div>
  //       {masterTruck && (
  //         <Controller
  //           name="driver_truck_id"
  //           control={control}
  //           render={({ field: { onChange, value } }) => (
  //             <div style={{ paddingTop: '20px', width: '100%' }}>
  //               <FieldSelect
  //                 name={'ประเภทรถที่ขับ*'}
  //                 options={masterTruck}
  //                 value={value}
  //                 onChange={onChange}
  //                 style={{ width: '-webkit-fill-available' }}
  //                 error={!!errors.driver_truck_id}
  //                 errorText={
  //                   errors.driver_truck_id ? errors.driver_truck_id.message : ''
  //                 }
  //               />
  //             </div>
  //           )}
  //         />
  //       )}
  //       {masterDrivingLicense && (
  //         <Controller
  //           name="driving_license"
  //           control={control}
  //           render={({ field: { onChange, value } }) => (
  //             <div style={{ width: '100%' }}>
  //               <FieldSelect
  //                 name={'ประเภทใบอนุญาตขับขี่*'}
  //                 options={masterDrivingLicense}
  //                 value={value}
  //                 onChange={onChange}
  //                 style={{ width: '-webkit-fill-available' }}
  //                 error={!!errors.driving_license}
  //                 errorText={
  //                   errors.driving_license ? errors.driving_license.message : ''
  //                 }
  //               />
  //             </div>
  //           )}
  //         />
  //       )}
  //       <Controller
  //         name="driver_license_front_file"
  //         control={control}
  //         rules={{ validate: validateFile }}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldUpload
  //               name="ไฟล์เอกสารใบขับขี่ด้านหน้า*"
  //               style={{ width: '-webkit-fill-available' }}
  //               accept="image/jpeg, image/png, application/pdf"
  //               value={value}
  //               onChange={onChange}
  //               error={!!errors.driver_license_front_file}
  //               textError={
  //                 errors.driver_license_front_file
  //                   ? errors.driver_license_front_file.message
  //                   : ''
  //               }
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="driver_license_back_file"
  //         control={control}
  //         rules={{ validate: validateFile }}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldUpload
  //               name="ไฟล์เอกสารใบขับขี่ด้านหลัง*"
  //               style={{ width: '-webkit-fill-available' }}
  //               accept="image/jpeg, image/png, application/pdf"
  //               value={value}
  //               onChange={onChange}
  //               error={!!errors.driver_license_back_file}
  //               textError={
  //                 errors.driver_license_back_file
  //                   ? errors.driver_license_back_file.message
  //                   : ''
  //               }
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="driver_license_expire_date"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldDatePicker
  //               label="วันหมดอายุใบขับขี่*"
  //               onChange={onChange}
  //               value={value ?? ''}
  //               // showTime
  //               disabledDay={true}
  //               className="w-100"
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="driver_crime_history"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldSelect
  //               name={'การตรวจประวัติอาชญากรรม*'}
  //               options={[
  //                 { value: 'true', name: 'มี' },
  //                 { value: 'false', name: 'ไม่มี' },
  //               ]}
  //               style={{ width: '-webkit-fill-available' }}
  //               onChange={onChange}
  //               value={value}
  //               error={!!errors.driver_crime_history}
  //               textError={
  //                 errors.driver_crime_history ? errors.driver_crime_history : ''
  //               }
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="driver_crime_history_file"
  //         control={control}
  //         rules={{
  //           required: driver_crime_history === 'true' ? 'กรุณาอัพโหลด' : false,
  //           validate: validateFile,
  //         }}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldUpload
  //               name="ไฟล์หลักฐานการตรวจประวัติอชญากรรม*"
  //               nameDescription={`(บังคับถ้ามีการตรวจประวัติอชญากรรม)`}
  //               accept="image/jpeg, image/png, application/pdf"
  //               style={{ width: '-webkit-fill-available' }}
  //               onChange={onChange}
  //               value={value}
  //               readOnly={driver_crime_history === 'true' ? false : true}
  //               error={!!errors.driver_crime_history_file}
  //               textError={
  //                 errors.driver_crime_history_file
  //                   ? errors.driver_crime_history_file.message
  //                   : ''
  //               }
  //             />
  //           </div>
  //         )}
  //       />

  //       <Controller
  //         name="driver_crime_history_consent"
  //         control={control}
  //         rules={{
  //           required: driver_crime_history === 'false' ? 'กรุณาเลือก' : false,
  //         }}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldSelect
  //               name={'ยินยอมส่งเอกสารในภายหลัง*'}
  //               nameDescription={`(บังคับถ้าไม่มีการตรวจประวัติอชญากรรม)`}
  //               descriptionUnderField="กรณีไม่มีเอกสารฯ จะยินยอมให้ส่งเอกสารภายใน 15 วันหากมีการร้องขอ"
  //               options={[
  //                 { value: 'true', name: 'ยินยอม' },
  //                 { value: 'false', name: 'ไม่ยินยอม' },
  //               ]}
  //               style={{ width: '-webkit-fill-available' }}
  //               onChange={onChange}
  //               readOnly={driver_crime_history === 'true' ? true : false}
  //               value={value}
  //               error={!!errors.driver_crime_history_consent}
  //               errorText={
  //                 errors.driver_crime_history_consent
  //                   ? errors.driver_crime_history_consent.message
  //                   : ''
  //               }
  //             />
  //           </div>
  //         )}
  //       />
  //     </div>
  //   );
  // };
  // const renderCard4 = () => {
  //   return (
  //     <div className={styles.cardDetail}>
  //       <div className={styles.titleUserRole} style={{ paddingTop: '27px' }}>
  //         <Typography fontSize={16} color={'grey.main'} fontWeight={500}>
  //           ข้อมูลคนขับ
  //         </Typography>
  //       </div>
  //       <Controller
  //         name="driver_test_status"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%', paddingTop: '20px' }}>
  //             <FieldSelect
  //               name={'สถานะการทำแบบทดสอบ*'}
  //               options={[
  //                 { value: 'true', name: 'ผ่าน' },
  //                 { value: 'false', name: 'ไม่ผ่าน' },
  //               ]}
  //               value={value}
  //               onChange={onChange}
  //               style={{ width: '-webkit-fill-available' }}
  //               error={!!errors.driver_test_status}
  //               errorText={
  //                 errors.driver_test_status
  //                   ? errors.driver_test_status.message
  //                   : ''
  //               }
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="driver_approved_status"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldSelect
  //               name={'สถานะการอนุมัติคนขับ*'}
  //               options={ApproveStatus}
  //               value={value}
  //               onChange={onChange}
  //               style={{ width: '-webkit-fill-available' }}
  //               error={!!errors.driver_approved_status}
  //               errorText={
  //                 errors.driver_approved_status
  //                   ? errors.driver_approved_status.message
  //                   : ''
  //               }
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="reason_for_edit"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldInput
  //               label="เหตุผล"
  //               descriptionUnderField="เหตุผลสำหรับกรณีมีสถานะเอกสารเป็นแก้ไขเพิ่มเติม"
  //               readOnly={driver_approved_status === 2 ? false : true}
  //               value={value}
  //               onChange={onChange}
  //               style={{ width: '-webkit-fill-available' }}
  //               error={!!errors.reason_for_edit}
  //               errorText={
  //                 errors.reason_for_edit ? errors.reason_for_edit.message : ''
  //               }
  //             />
  //           </div>
  //         )}
  //       />

  //       <div style={{ width: '100%' }}>
  //         <FieldInput
  //           label="วันที่อนุมัติล่าสุด"
  //           readOnly={true}
  //           value={
  //             dataDetail?.driver_approved_at
  //               ? helper.ToConvertDateTimeForTable(
  //                   dataDetail?.driver_approved_at,
  //                 )
  //               : ''
  //           }
  //           style={{ width: '-webkit-fill-available' }}
  //         />
  //       </div>

  //       <div style={{ width: '100%' }}>
  //         <FieldInput
  //           label="ผู้ดูแลระบบ(ผู้ตรวจสอบ)"
  //           readOnly={true}
  //           value={
  //             dataDetail?.admin_approved
  //               ? `${dataDetail?.admin_approved?.firstname}  ${dataDetail?.admin_approved?.lastname}`
  //               : ''
  //           }
  //           style={{ width: '-webkit-fill-available' }}
  //         />
  //       </div>
  //       <div
  //         style={{
  //           width: '100%',
  //           display: 'flex',
  //           flexDirection: 'row',
  //           alignItems: 'center',
  //           height: '48px',
  //           backgroundColor: 'rgba(140, 163, 186, 0.20)',
  //           borderBottom: `1px solid rgba(18, 31, 67, 0.5)`,
  //         }}
  //       >
  //         <Rating
  //           disabled
  //           value={parseFloat(dataDetail?.driver_score)}
  //           precision={0.01}
  //           style={{ opacity: 1 }}
  //           icon={
  //             <StarRateRounded
  //               sx={{ fontSize: '38px', color: 'warning.main' }}
  //             />
  //           }
  //           emptyIcon={
  //             <StarRateRounded sx={{ fontSize: '38px', color: 'grey.main' }} />
  //           }
  //         />
  //         <Typography color={'grey.main'}>
  //           {dataDetail?.driver_score?.toFixed(2)} คะแนนเฉลี่ยสะสม
  //         </Typography>
  //       </div>
  //     </div>
  //   );
  // };
  const renderCard5 = () => {
    return (
      <div className={styles.cardDetail}>
        <div style={{ width: '100%' }}>
          <div className={styles.titleUserRole} style={{ paddingTop: '27px' }}>
            <Typography fontSize={16} color={'grey.main'} fontWeight={500}>
              สิทธิ์การใช้งาน
            </Typography>
          </div>
          {errors?.userPermission ? (
            <Typography color={'error.main'} fontSize={'10px'}>
              {errors?.userPermission.message}
            </Typography>
          ) : (
            ''
          )}
          {items && (
            <FormGroup>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: '20px',
                }}
              >
                <Typography fontSize={16} color={'grey.main'} fontWeight={500}>
                  ข้อมูลทั่วไป*
                </Typography>
                <FormControlLabel
                  control={<Checkbox />}
                  label={String(items[0]?.name)}
                  checked={items[0]?.checked ?? false}
                  onChange={() =>
                    handleCheckboxClick(items[0].user_permission_id)
                  }
                  disabled={is_main === 'false' ? true : false}
                  sx={
                    is_main === 'false'
                      ? {
                          background: `rgba(140, 163, 186, 0.2)`,
                          color: 'grey.main',
                          margin: 0,
                        }
                      : { margin: 0 }
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label={items[1]?.name}
                  checked={items[1]?.checked ?? false}
                  onChange={() =>
                    handleCheckboxClick(items[1].user_permission_id)
                  }
                  disabled={is_main === 'false' ? true : false}
                  sx={
                    is_main === 'false'
                      ? {
                          background: `rgba(140, 163, 186, 0.2)`,
                          color: 'grey.main',
                          margin: 0,
                        }
                      : { margin: 0 }
                  }
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography fontSize={16} color={'grey.main'} fontWeight={500}>
                  ลูกค้า*
                </Typography>
                <FormControlLabel
                  control={<Checkbox />}
                  label={items[2]?.name}
                  checked={items[2]?.checked ?? false}
                  onChange={() =>
                    handleCheckboxClick(items[2].user_permission_id)
                  }
                  disabled={roles?.includes('shipper') ? false : true}
                  sx={
                    !roles?.includes('shipper')
                      ? {
                          background: `rgba(140, 163, 186, 0.2)`,
                          color: 'grey.main',
                          margin: 0,
                        }
                      : { margin: 0 }
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label={items[3]?.name}
                  checked={items[3]?.checked ?? false}
                  onChange={() =>
                    handleCheckboxClick(items[3].user_permission_id)
                  }
                  disabled={roles?.includes('shipper') ? false : true}
                  sx={
                    !roles?.includes('shipper')
                      ? {
                          background: `rgba(140, 163, 186, 0.2)`,
                          color: 'grey.main',
                          margin: 0,
                        }
                      : { margin: 0 }
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label={items[4]?.name}
                  checked={items[4]?.checked ?? false}
                  onChange={() =>
                    handleCheckboxClick(items[4].user_permission_id)
                  }
                  disabled={roles?.includes('shipper') ? false : true}
                  sx={
                    !roles?.includes('shipper')
                      ? {
                          background: `rgba(140, 163, 186, 0.2)`,
                          color: 'grey.main',
                          margin: 0,
                        }
                      : { margin: 0 }
                  }
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography fontSize={16} color={'grey.main'} fontWeight={500}>
                  ผู้ขนส่ง*
                </Typography>
                <FormControlLabel
                  control={<Checkbox />}
                  label={items[5]?.name}
                  checked={items[5]?.checked ?? false}
                  onChange={() =>
                    handleCheckboxClick(items[5].user_permission_id)
                  }
                  disabled={roles?.includes('carrier') ? false : true}
                  sx={
                    !roles?.includes('carrier')
                      ? {
                          background: `rgba(140, 163, 186, 0.2)`,
                          color: 'grey.main',
                          margin: 0,
                        }
                      : { margin: 0 }
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label={items[6]?.name}
                  checked={items[6]?.checked ?? false}
                  onChange={() =>
                    handleCheckboxClick(items[6].user_permission_id)
                  }
                  disabled={roles?.includes('carrier') ? false : true}
                  sx={
                    !roles?.includes('carrier')
                      ? {
                          background: `rgba(140, 163, 186, 0.2)`,
                          color: 'grey.main',
                          margin: 0,
                        }
                      : { margin: 0 }
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label={items[7]?.name}
                  checked={items[7]?.checked ?? false}
                  onChange={() =>
                    handleCheckboxClick(items[7].user_permission_id)
                  }
                  disabled={roles?.includes('carrier') ? false : true}
                  sx={
                    !roles?.includes('carrier')
                      ? {
                          background: `rgba(140, 163, 186, 0.2)`,
                          color: 'grey.main',
                          margin: 0,
                        }
                      : { margin: 0 }
                  }
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label={items[8]?.name}
                  checked={items[8]?.checked ?? false}
                  onChange={() =>
                    handleCheckboxClick(items[8].user_permission_id)
                  }
                  disabled={roles?.includes('carrier') ? false : true}
                  sx={
                    !roles?.includes('carrier')
                      ? {
                          background: `rgba(140, 163, 186, 0.2)`,
                          color: 'grey.main',
                          margin: 0,
                        }
                      : { margin: 0 }
                  }
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography fontSize={16} color={'grey.main'} fontWeight={500}>
                  พนักงานขับรถ*
                </Typography>
                <FormControlLabel
                  control={<Checkbox />}
                  label={items[9]?.name}
                  checked={items[9]?.checked ?? false}
                  onChange={() =>
                    handleCheckboxClick(items[9].user_permission_id)
                  }
                  disabled={
                    roles?.includes('driver') || roles?.includes('carrier')
                      ? false
                      : true
                  }
                  sx={
                    !(roles?.includes('driver') || roles?.includes('carrier'))
                      ? {
                          background: `rgba(140, 163, 186, 0.2)`,
                          color: 'grey.main',
                          margin: 0,
                        }
                      : { margin: 0 }
                  }
                />
              </div>
            </FormGroup>
          )}
        </div>
      </div>
    );
  };

  const onSubmit = async (data) => {
    setLoading(true);
    console.log('data edit', data);
    console.log('initialFormValues', initialFormValues);
    const changedData = {};
    if (
      Array.isArray(data.userPermission) &&
      JSON.stringify(data.userPermission) !==
        JSON.stringify(initialFormValues.userPermission)
    ) {
      changedData.userPermission = data.userPermission;
    }

    if (
      data.roles &&
      JSON.stringify(data.roles) !== JSON.stringify(initialFormValues.roles)
    ) {
      changedData.roles = data.roles;
    }

    if (
      data.profile &&
      JSON.stringify(data.profile) !== JSON.stringify(initialFormValues.profile)
    ) {
      changedData.profile = data.profile;
    }

    Object.keys(data).forEach((fieldName) => {
      if (
        fieldName !== 'userPermission' &&
        fieldName !== 'roles' &&
        fieldName !== 'profile' &&
        data[fieldName] !== initialFormValues[fieldName]
      ) {
        if (data[fieldName] === '') {
          changedData[fieldName] = null;
        } else {
          changedData[fieldName] = data[fieldName];
        }
      }
    });

    const uniqueError = {};
    if (changedData.email || changedData.phone || changedData.citizen_id) {
      await checkUniqueFields(changedData)
        .then((res) => {
          console.log('res');
        })
        .catch((error) => {
          if (error.response.data.code === 4404) {
            Object.keys(error.response.data.description).forEach((field) => {
              uniqueError[field] = error.response.data.description[field];
            });
          }
        });
      if (Object.keys(uniqueError).length > 0) {
        Object.keys(uniqueError).forEach((field) => {
          setError(field, {
            type: 'manual',
            message: uniqueError[field],
          });
        });
      }
    }

    console.log('changedData after', changedData);
    const GForm = new FormData();
    Object.keys(changedData).forEach((key) => {
      if (key === 'userPermission') {
        GForm.append(key, JSON.stringify(changedData[key]));
      } else if (key === 'driver_license_expire_date') {
        GForm.append(key, new Date(changedData[key]).toISOString());
      } else if (key === 'roles') {
        const roles = changedData[key];
        GForm.append('is_shipper', roles.includes('shipper'));
        GForm.append('is_carrier', roles.includes('carrier'));
        GForm.append('is_driver', roles.includes('driver'));
      } else if (changedData[key] === undefined) {
        GForm.delete(key);
      } else {
        GForm.append(key, changedData[key]);
      }
    });

    GForm.delete('roles');
    GForm.delete('profile');
    GForm.delete('confirmPassword');
    for (const [key, value] of GForm.entries()) {
      console.log(`${key}: ${value}`);
    }

    if (id) {
      await editUser(id, GForm)
        .then(async (response) => {
          console.log('response', response);
          if (response.data.code === 1000) {
            setLoading(false);
            setAlertModal(true);
            console.log('edit Success');
            if (changedData?.driver_approved_status !== undefined) {
              const driverApprove = ApproveStatus.find(
                (res) => res.value === changedData?.driver_approved_status,
              );

              const param_noti = {
                user_code:
                  dataDetail.is_main === true
                    ? [dataDetail?.user_code]
                    : [
                        dataDetail?.user_code,
                        dataDetail?.user_profile.profile.user_info.user_code,
                      ],
                title: `คนขับ ${
                  changedData?.prefix
                    ? changedData?.prefix === 0
                      ? 'นาย'
                      : changedData?.prefix === 1
                      ? 'นางสาว'
                      : 'นาง'
                    : dataDetail?.prefix === 0
                    ? 'นาย'
                    : dataDetail?.prefix === 1
                    ? 'นางสาว'
                    : 'นาง'
                }${
                  changedData?.firstname
                    ? changedData?.firstname
                    : dataDetail?.firstname
                } ${
                  changedData?.lastname
                    ? changedData?.lastname
                    : dataDetail?.lastname
                }`,
                message: `${driverApprove.name} ${
                  changedData?.driver_approved_status === 2
                    ? 'เหตุผล : ' +
                      `${
                        changedData?.reason_for_edit
                          ? changedData?.reason_for_edit
                          : ''
                      }`
                    : ''
                }`,
                for_shipper: false,
                for_carrier: true,
                for_driver: true,
                important: false,
              };
              console.log('noti_param', param_noti);
              // await sendNoti(param_noti)
              //   .then((res) => console.log("send_noti success"))
              //   .catch((error) => {
              //     console.log("error", error);
              //   });
            }
          }
        })
        .catch(async (error) => {
          console.log('error', error);
          if (error) {
            setLoading(false);
            if (error.response.data.code === 4106) {
              setErrorAlert({
                open: true,
                desc: error.response.data.description,
              });
            } else if (error.response.data.code === '4105') {
              removeAccessToken('access_token');
              window.location.reload();
            }
          }
        });
    }
  };

  return (
    <React.Fragment>
      {loading && <Loading />}
      <AlertModal
        open={alertModal}
        title="บันทึก"
        description="บันทึกข้อมูลสำเร็จ"
        onClose={() => {
          setAlertModal(false);
          window.location.reload();
        }}
      />
      <AlertModal
        open={errorAlert.open}
        title="เกิดข้อผิดพลาด"
        description={errorAlert.desc}
        onClose={() => {
          setErrorAlert({
            open: false,
            desc: '',
          });
          navigate(-1);
        }}
      />
      <Container>
        <SubMenu menus={MenuUser} breadcrumbs={breadcrumbs} pathbackward={-1} />
      </Container>
      <form
        className={styles.containerDetailUser}
        onSubmit={handleSubmit(onSubmit)}
      >
        {renderCard1()}
        {renderCard2()}
        {renderCard5()}
        <Avatar
          alt="Avatar Save"
          sx={{
            position: 'fixed',
            bottom: '35px',
            right: '97px',
            width: 100,
            height: 100,
            bgcolor: 'secondary.main',
            cursor: 'pointer',
            zIndex: 200,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          <Save sx={{ color: 'white', fontSize: '70px' }} />
        </Avatar>
      </form>
    </React.Fragment>
  );
};

export default DetailUser;
