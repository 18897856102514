import React, { useEffect, useRef, useState } from "react";
import { TextField } from "@mui/material";
const OtpInput = ({ onChange, classNames, styles, label, error, otpRef }) => {
  //   console.log(value[0]);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();

  const setValue = (index, value) => {
    if (!/\D/.test(value)) {
      let tmp = [...otp];
      if (tmp[index].length > 0) {
        tmp[index] = value ? value[value.length - 1] : "";
      } else {
        tmp[index] = value ?? "";
      }

      setOtp(tmp);
    }
  };
  useEffect(() => {
    onChange(otp);
  }, [otp]);
  return (
    <div>
      {otpRef ? <div style={{ fontSize: 20 }}>ref[{otpRef}]</div> : null}
      <div
        style={{
          display: "flex",
          // flexDirection: "row",
          // alignItems: "center",
        }}
      >
        <div style={{ flex: 1, padding: 5 }}>
          <TextField
            style={{ width: 50 }}
            variant="standard"
            onChange={(value) => {
              setValue(0, value.target.value);

              if (value.target.value) {
                ref2.current.select();
              }
            }}
            error={error}
            inputRef={ref1}
            value={otp[0]}
          />
        </div>
        <div style={{ flex: 1, padding: 5 }}>
          <TextField
            style={{ width: 50 }}
            variant="standard"
            error={error}
            onChange={(value) => {
              setValue(1, value.target.value);
              if (!value.target.value) {
                ref1.current.focus();
              } else {
                ref3.current.focus();
              }
            }}
            inputRef={ref2}
            value={otp[1]}
          />
        </div>
        <div style={{ flex: 1, padding: 5 }}>
          <TextField
            style={{ width: 50 }}
            variant="standard"
            error={error}
            onChange={(value) => {
              setValue(2, value.target.value);
              if (!value.target.value) {
                ref2.current.focus();
              } else {
                ref4.current.focus();
              }
            }}
            inputRef={ref3}
            value={otp[2]}
          />
        </div>
        <div style={{ flex: 1, padding: 5 }}>
          <TextField
            style={{ width: 50 }}
            variant="standard"
            error={error}
            onChange={(value) => {
              setValue(3, value.target.value);
              if (!value.target.value) {
                ref3.current.focus();
              } else {
                ref5.current.focus();
              }
            }}
            inputRef={ref4}
            value={otp[3]}
          />
        </div>
        <div style={{ flex: 1, padding: 5 }}>
          <TextField
            style={{ width: 50 }}
            variant="standard"
            error={error}
            onChange={(value) => {
              setValue(4, value.target.value);
              ref6.current.focus();
              if (!value.target.value) {
                ref4.current.focus();
              } else {
                ref6.current.focus();
              }
            }}
            inputRef={ref5}
            value={otp[4]}
          />
        </div>
        <div style={{ flex: 1, padding: 5 }}>
          <TextField
            style={{ width: 50 }}
            error={error}
            variant="standard"
            onChange={(value) => {
              setValue(5, value.target.value);
              if (!value.target.value) {
                ref5.current.focus();
              }
            }}
            inputRef={ref6}
            value={otp[5]}
          />
        </div>
      </div>
    </div>
  );
};

export default OtpInput;
