import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useCookies } from 'react-cookie';
import Loading from 'components/loading/LoadingBackground';
import {
  Container,
  Avatar,
  useTheme,
  Typography,
  Rating,
  Paper,
} from '@mui/material';
import { Save, AddCircle, DoDisturbOnOutlined } from '@mui/icons-material';
import styles from './DetailProfile.module.css';
import helper from 'utils/helper';
import { AccountCircle, StarRateRounded } from '@mui/icons-material';
import FieldSelect from 'components/field/FieldSelect';
import FieldInput from 'components/field/FieldInput';
import FieldUpload from 'components/field/FieldUpload';
// import AreaTreeSelect from "components/areaTreeSelect/AreaTreeSelect";
import {
  GradeType,
  ActiveStatus,
  ProfileType,
  PaymentType,
  ApproveStatus,
} from 'utils/enum';
import FieldSelectArea from 'components/field/FieldSelectArea';

//api
import { getUserProfile, editProfile } from 'utils/api/profile/profile';
import masterProvice from 'utils/api/masterData/provice';
import {
  getAllProductType,
  getAllOptional,
  getAllQualification,
  getAllBank,
  getAllAssociation,
  getAllPlatform,
} from 'utils/api/masterData/service';
import { getTruckType as getTruckTypeMS } from 'utils/api/masterData/truckType';
import { getAllAccessory as getAccessoriesMS } from 'utils/api/masterData/accessory';
// import { getAllParking, deleteParking } from 'utils/api/profile/parking';
import AlertModal from 'components/modal/AlertModal';
// import ModalAddParking from './ModalAddParking';
import UserDetailSubMenu from 'components/layout/SubMenu.js/UserDetailSubMenu';
// import { sendNoti } from 'utils/api/noti/noti';

const DetailProfile = () => {
  const { profile_id } = useParams();
  const inputFile = useRef('');
  const theme = useTheme();
  const navigate = useNavigate();
  const [, , removeAccessToken] = useCookies(['access_token']);
  const [loading, setLoading] = useState(false);
  const [dataDetail, setDataDetail] = useState(null);
  const [imageChange, setImageChange] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});
  const [truckCounts, setTruckCounts] = useState({});
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: '',
    desc: '',
  });
  const [openModalAddParking, setOpenModalAddParking] = useState(false);
  const [openModalEditParking, setOpenModalEditParking] = useState({
    open: false,
    data: {},
  });
  const [errorAlert, setErrorAlert] = useState({ open: false, desc: '' });

  //master
  const [province, setProvince] = useState([]);
  const [district, setDistrict] = useState([]);
  const [subdistrict, setSubdistrict] = useState([]);
  const [productType, setProductType] = useState([]);
  const [truckType, setTruckType] = useState([]);
  const [accessories, setAccessories] = useState([]);
  const [optional, setOptional] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [bank, setBank] = useState([]);
  const [association, setAssociation] = useState([]);
  const [profileParking, setProfileParking] = useState([]);
  const [platform, setPlatform] = useState([]);
  const [checkPlatform, setCheckPlatform] = useState({ tps: false });
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm();
  const profile_type = watch('profile_type');
  const checkRoles = watch('roles');
  const selectProvince = watch('province_id');
  const selectDistrict = watch('district_id');
  const selectSubdistrict = watch('subdistrict_id');
  const carrier_approved_status = watch('carrier_approved_status');
  const shipper_approved_status = watch('shipper_approved_status');
  const carrier_transport_license = watch('carrier_transport_license');
  const profile_shipper_platform = watch('profile_shipper_platform');

  useEffect(() => {
    if (carrier_transport_license === false) {
      setValue('carrier_transport_license_file', '');
    }
  }, [carrier_transport_license]);

  const onClick = (event) => {
    inputFile.current.click();
    event.stopPropagation();
  };

  const breadcrumbs = [
    {
      label: 'ข้อมูลผู้ใช้งาน',
      path: '/user',
    },
    {
      label: `${dataDetail?.user_info.user_code}`,
      path: '',
    },
    {
      label: 'ข้อมูลโปรไฟล์',
      path: '',
    },
  ];

  useEffect(() => {
    console.log('truckCounts', truckCounts);
    console.log('profileParking', profileParking);
  }, [truckCounts, profileParking]);

  useEffect(() => {
    setLoading(true);
    setTimeout(async () => {
      getDetailProfile();
    }, 2000);
  }, [profile_id]);

  const getDetailProfile = async () => {
    await getUserProfile(profile_id)
      .then((response) => {
        if (response.data.code === 1000) {
          const rest = response.data.data;
          const roles = [];
          if (
            rest.user_info.user_profile.user_profile_shipper.is_shipper === true
          ) {
            roles.push('shipper');
          }

          if (
            rest.user_info.user_profile.user_profile_carrier.is_carrier === true
          ) {
            roles.push('carrier');
          }

          if (
            rest.user_info.user_profile.user_profile_driver.is_driver === true
          ) {
            roles.push('driver');
          }
          rest['user_info']['roles'] = roles;
          delete rest.user_info.user_profile;

          // if (rest.profileCarrierAccessories.length > 0) {
          //   const carrier_accessories = rest.profileCarrierAccessories.map(
          //     (item) => item.accessories_id,
          //   );
          //   rest['profileCarrierAccessories'] = carrier_accessories;
          // }
          // if (rest.profileCarrierAssociation.length > 0) {
          //   const carrier_association = rest.profileCarrierAssociation.map(
          //     (item) => item.association_id,
          //   );
          //   rest['profileCarrierAssociation'] = carrier_association;
          // }
          // if (rest.profileCarrierOptional.length > 0) {
          //   const carrier_optional = rest.profileCarrierOptional.map(
          //     (item) => item.optional_id,
          //   );
          //   rest['profileCarrierOptional'] = carrier_optional;
          // }
          // if (rest.profileCarrierProductType.length > 0) {
          //   const carrier_product = rest.profileCarrierProductType.map(
          //     (item) => item.product_type_id,
          //   );
          //   rest['profileCarrierProductType'] = carrier_product;
          // }
          // if (rest.profileCarrierQualification.length > 0) {
          //   const carrier_qualification = rest.profileCarrierQualification.map(
          //     (item) => item.qualification_id,
          //   );
          //   rest['profileCarrierQualification'] = carrier_qualification;
          // }
          if (rest.profileShipperAccessories.length > 0) {
            const shipper_accessories = rest.profileShipperAccessories.map(
              (item) => item.accessories_id,
            );
            rest['profileShipperAccessories'] = shipper_accessories;
          }
          if (rest.profileShipperOptional.length > 0) {
            const shipper_optional = rest.profileShipperOptional.map(
              (item) => item.optional_id,
            );
            rest['profileShipperOptional'] = shipper_optional;
          }
          if (rest.profileShipperProductType.length > 0) {
            const shipper_product = rest.profileShipperProductType.map(
              (item) => item.product_type_id,
            );
            rest['profileShipperProductType'] = shipper_product;
          }
          if (rest.profileShipperQualification.length > 0) {
            const shipper_qualification = rest.profileShipperQualification.map(
              (item) => item.qualification_id,
            );
            rest['profileShipperQualification'] = shipper_qualification;
          }
          if (rest.profileShipperTruckType.length > 0) {
            const shipper_truck_type = rest.profileShipperTruckType.map(
              (item) => item.truck_type_id,
            );
            rest['profileShipperTruckType'] = shipper_truck_type;
          }
          if (rest?.profileShipperPlatform.length > 0) {
            const shipper_platform = rest.profileShipperPlatform.map(
              (item) => item.platform_id,
            );
            rest['profileShipperPlatform'] = shipper_platform;
          }
          // if (rest.truck.length > 0) {
          //   const truck = rest.truck.map((item) => item.truck_type_id);
          //   rest['truck'] = truck;
          // }
          setDataDetail(rest);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        if (error.response.data.code === 4106) {
          setErrorAlert({
            open: true,
            desc: error.response.data.description,
          });
        } else if (error.response.data.code === '4105') {
          removeAccessToken('access_token');
          // window?.location?.reload();
        }
      });
  };
  useEffect(() => {
    console.log('platform', platform);
  }, [platform]);
  useEffect(() => {
    //get master
    getProvince();
    getProductType();
    getTruckType();
    getAccessories();
    getOptional();
    getQualification();
    getBank();
    getAssociation();
    // allParking();
    getPlatform();
  }, []);

  useEffect(() => {
    getDistrict();
  }, [selectProvince]);

  useEffect(() => {
    getSubDistrict();
  }, [selectDistrict]);

  useEffect(() => {
    if (selectSubdistrict) {
      const matchPostCode = subdistrict.filter(
        (val) => val.value === selectSubdistrict,
      );
      const postalCode = matchPostCode ? matchPostCode[0]?.postal_code : '';
      setValue('postcode', postalCode);
    }
  }, [selectSubdistrict, subdistrict]);

  // const allParking = async () => {
  //   await getAllParking(profile_id)
  //     .then((response) => {
  //       if (response.data.code === 1000) {
  //         setProfileParking(response.data.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       if (error.response.data.code === 4106) {
  //         setErrorAlert({
  //           open: true,
  //           desc: error.response.data.description,
  //         });
  //       } else if (error.response.data.code === '4105') {
  //         removeAccessToken('access_token');
  //         window?.location?.reload();
  //       }
  //     });
  // };
  // const deleteParkingById = async (parking_id) => {
  //   await deleteParking(parking_id)
  //     .then((response) => {
  //       if (response.data.code === 1000) {
  //         setAlertModal({
  //           open: true,
  //           title: 'ลบจุดจอด',
  //           desc: 'ลบจุดจอดสำเร็จ',
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       if (error.response.data.code === 4106) {
  //         setErrorAlert({
  //           open: true,
  //           desc: error.response.data.description,
  //         });
  //       } else if (error.response.data.code === '4105') {
  //         removeAccessToken('access_token');
  //         window?.location?.reload();
  //       }
  //     });
  // };
  const getProvince = async () => {
    await masterProvice
      .Province()
      .then((response) => {
        let results = response.data.data.results;
        setProvince(
          ...province,
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          })),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getDistrict = async () => {
    if (selectProvince) {
      await masterProvice
        .DistrictID(selectProvince)
        .then((response) => {
          let results = response.data.data.results;
          setDistrict(
            results?.map((res) => ({
              value: res.id,
              name: res.name,
            })),
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const getSubDistrict = async () => {
    if (selectDistrict) {
      await masterProvice
        .SubDistrictID(selectDistrict)
        .then((response) => {
          let results = response.data.data.results;
          setSubdistrict(
            results?.map((res) => ({
              value: res.id,
              name: res.name,
              postal_code: res.postal_code,
            })),
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const getProductType = async () => {
    await getAllProductType()
      .then((response) => {
        let results = response.data.data.results;
        setProductType(
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          })),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTruckType = async () => {
    const params = {
      sort_field: 'order_number',
      sort_order: 1,
    };
    await getTruckTypeMS(params)
      .then((response) => {
        let results = response.data.data.results;
        setTruckType(
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          })),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getAccessories = async () => {
    await getAccessoriesMS()
      .then((response) => {
        let results = response.data.data.results;
        setAccessories(
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          })),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getOptional = async () => {
    await getAllOptional()
      .then((response) => {
        let results = response.data.data.results;
        setOptional(
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          })),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getQualification = async () => {
    await getAllQualification()
      .then((response) => {
        let results = response.data.data.results;
        setQualification(
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          })),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getBank = async () => {
    await getAllBank()
      .then((response) => {
        let results = response.data.data.results;
        setBank(
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          })),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPlatform = async () => {
    await getAllPlatform()
      .then((response) => {
        let results = response.data.data.results;
        setPlatform(
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          })),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAssociation = async () => {
    await getAllAssociation()
      .then((response) => {
        let results = response.data.data.results;
        setAssociation(
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          })),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log('dataDetail', dataDetail);
    console.log('checkRoles', checkRoles);
  }, [dataDetail, checkRoles]);

  useEffect(() => {
    console.log('accessories', accessories);
  }, [accessories]);
  useEffect(() => {
    console.log('productType', productType);
  }, [productType]);
  useEffect(() => {
    console.log('qualification', qualification);
  }, [qualification]);

  useEffect(() => {
    if (dataDetail) {
      const newInitialFormValues = {};
      //card1
      newInitialFormValues.profile_image = dataDetail?.profile_image ?? '';
      newInitialFormValues.active = dataDetail?.active ?? '';
      newInitialFormValues.profile_type = dataDetail?.profile_type ?? '';
      newInitialFormValues.company_name = dataDetail?.company_name ?? '';
      newInitialFormValues.tax_id = dataDetail?.tax_id ?? '';
      newInitialFormValues.certificate_file =
        dataDetail?.certificate_file ?? '';
      newInitialFormValues.token_line_group =
        dataDetail?.token_line_group ?? '';

      newInitialFormValues.roles = dataDetail?.user_info.roles ?? '';
      newInitialFormValues.branch_code = dataDetail?.branch_code ?? '';
      newInitialFormValues.address = dataDetail?.address ?? '';
      newInitialFormValues.province_id = dataDetail?.province_id ?? '';
      newInitialFormValues.district_id = dataDetail?.district_id ?? '';
      newInitialFormValues.subdistrict_id = dataDetail?.subdistrict_id ?? '';
      newInitialFormValues.bank_id = dataDetail?.bank_id ?? '';
      newInitialFormValues.bank_account_name =
        dataDetail?.bank_account_name ?? '';
      newInitialFormValues.bank_account_number =
        dataDetail?.bank_account_number ?? '';
      newInitialFormValues.bank_account_file =
        dataDetail?.bank_account_file ?? '';
      newInitialFormValues.shipper_grade_type =
        dataDetail?.shipper_grade_type ?? '';
      newInitialFormValues.shipper_payment_type =
        dataDetail?.shipper_payment_type ?? '';
      const shipper_unload = dataDetail?.shipper_unload_area.map(
        (item) => item.province_id,
      );
      newInitialFormValues.shipper_unload_area = shipper_unload ?? '';
      const shipper_load = dataDetail?.shipper_load_area.map(
        (item) => item.province_id,
      );
      newInitialFormValues.shipper_load_area = shipper_load ?? '';
      newInitialFormValues.shipper_accessories =
        dataDetail?.profileShipperAccessories ?? [];
      newInitialFormValues.shipper_truck_type =
        dataDetail?.profileShipperTruckType ?? [];
      newInitialFormValues.shipper_product_type =
        dataDetail?.profileShipperProductType ?? [];
      newInitialFormValues.shipper_optional =
        dataDetail?.profileShipperOptional ?? [];
      newInitialFormValues.shipper_qualification =
        dataDetail?.profileShipperQualification ?? [];
      newInitialFormValues.shipper_platform =
        dataDetail?.profileShipperPlatform ?? [];
      newInitialFormValues.shipper_approved_status =
        dataDetail?.shipper_approved_status ?? '';
      newInitialFormValues.shipper_reason_for_edit =
        dataDetail?.shipper_reason_for_edit ?? '';
      newInitialFormValues.shipper_approved_at =
        dataDetail?.shipper_approved_at ?? '';
      newInitialFormValues.shipper_platform =
        dataDetail?.profileShipperPlatform ?? [];

      newInitialFormValues.carrier_launch_year =
        dataDetail?.carrier_launch_year ?? '';
      newInitialFormValues.carrier_transport_license =
        dataDetail?.carrier_transport_license ?? '';
      newInitialFormValues.carrier_transport_license_file =
        dataDetail?.carrier_transport_license_file ?? '';
      newInitialFormValues.carrier_approved_status =
        dataDetail?.carrier_approved_status ?? '';
      newInitialFormValues.carrier_reason_for_edit =
        dataDetail?.carrier_reason_for_edit ?? '';
      newInitialFormValues.carrier_approved_at =
        dataDetail?.carrier_approved_at ?? '';
      newInitialFormValues.carrier_grade_type =
        dataDetail?.carrier_grade_type ?? '';
      newInitialFormValues.carrier_payment_type =
        dataDetail?.carrier_payment_type ?? '';
      // const carrier_unload = dataDetail?.carrier_unload_area.map(
      //   (item) => item.province_id,
      // );
      // newInitialFormValues.carrier_unload_area = carrier_unload ?? '';
      // const carrier_load = dataDetail?.carrier_load_area.map(
      //   (item) => item.province_id,
      // );
      // newInitialFormValues.carrier_load_area = carrier_load ?? '';
      newInitialFormValues.carrier_accessories =
        dataDetail?.profileCarrierAccessories ?? [];
      newInitialFormValues.carrier_truck_type =
        dataDetail?.profileCarrierTruckType ?? [];
      newInitialFormValues.carrier_product_type =
        dataDetail?.profileCarrierProductType ?? [];
      newInitialFormValues.carrier_optional =
        dataDetail?.profileCarrierOptional ?? [];
      newInitialFormValues.carrier_qualification =
        dataDetail?.profileCarrierQualification ?? [];
      newInitialFormValues.carrier_approved_status =
        dataDetail?.carrier_approved_status ?? '';
      newInitialFormValues.carrier_reason_for_edit =
        dataDetail?.carrier_reason_for_edit ?? '';
      newInitialFormValues.carrier_approved_at =
        dataDetail?.carrier_approved_at ?? '';
      newInitialFormValues.carrier_association =
        dataDetail?.profileCarrierAssociation ?? '';

      //card8
      newInitialFormValues.wheels_4 = dataDetail?.wheels_4 ?? '';
      newInitialFormValues.wheels_6 = dataDetail?.wheels_6 ?? '';
      newInitialFormValues.wheels_10 = dataDetail?.wheels_10 ?? '';
      newInitialFormValues.wheels_12 = dataDetail?.wheels_12 ?? '';
      newInitialFormValues.trailer = dataDetail?.trailer ?? '';
      newInitialFormValues.double_truck = dataDetail?.double_truck ?? '';

      console.log('newInitialFormValues', newInitialFormValues);
      setInitialFormValues(newInitialFormValues);
      Object.keys(newInitialFormValues).forEach((fieldName) => {
        setValue(fieldName, newInitialFormValues[fieldName]);
      });
    }
  }, [dataDetail, setValue]);

  const handleMaxDigit = (e, field, max) => {
    const newValue = e.target.value;
    // Limit input to a maximum of 10 characters
    if (newValue.length <= max) {
      setValue(field, newValue);
    }
    if (newValue.length > 0) {
      clearErrors(field);
    }
  };

  useEffect(() => {
    const newTruckCounts = {};

    dataDetail?.truck.forEach((id) => {
      const truck = truckType.find((item) => item.value === id);
      if (truck) {
        if (newTruckCounts[truck.value]) {
          newTruckCounts[truck.value]++;
        } else {
          newTruckCounts[truck.value] = 1;
        }
      }
    });
    setTruckCounts(newTruckCounts);
  }, [dataDetail?.truck, truckType]);

  const handleCheckPlatform = (name, val) => {
    if (name === 'tps' && val === true) {
      setValue('profile_shipper_platform', [...profile_shipper_platform, 0]);
    }
  };

  const renderCard1 = () => {
    return (
      <div className={styles.cardDetail}>
        <Controller
          name="profile_image"
          control={control}
          //   defaultValue={dataDetail?.profile_image}
          render={({ field: { onChange, value } }) => (
            <div className={styles.uploadImageProfile}>
              <input
                style={{ display: 'none' }}
                ref={inputFile}
                accept="image/jpeg, image/png"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    // validateImage(file, "image");
                    setImageChange(true);
                    onChange(file);
                  }
                  // setLinkFile(URL.createObjectURL(e.target.files[0]));
                }}
                type="file"
              />

              {value ? (
                <img
                  src={
                    imageChange ? URL.createObjectURL(value) : `http://${value}`
                  }
                  width={'118px'}
                  height={'118px'}
                  alt="image profile"
                  style={{ objectFit: 'cover', borderRadius: '10px' }}
                  onClick={onClick}
                />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    width: '118px',
                    height: '118px',
                    backgroundColor: `rgba(140, 163, 186, 0.2)`,
                    alignItems: 'center',
                    justifyContent: 'center',

                    borderRadius: '10px',
                  }}
                  onClick={onClick}
                >
                  <AccountCircle
                    sx={{
                      fontSize: 75,
                      color: 'grey.main',
                    }}
                  />
                </div>
              )}
              {errors?.profile_image && (
                <p
                  style={{ color: theme.palette.error.main, fontSize: '10px' }}
                >
                  {errors?.profile_image.message}
                </p>
              )}
            </div>
          )}
        />

        <Typography fontSize={'20px'} fontWeight={500} sx={{ p: '13px' }}>
          {dataDetail?.user_info.user_code || ''}
        </Typography>
        <Typography
          fontSize={'14px'}
          fontWeight={300}
          fontStyle={'italic'}
          color={'grey.main'}
        >
          วันที่สร้างรายการ{' '}
          {dataDetail?.created_at
            ? helper.ToConvertDateTimeForTable(dataDetail?.created_at)
            : ''}
        </Typography>
        <Typography
          fontSize={'14px'}
          fontWeight={300}
          fontStyle={'italic'}
          color={'grey.main'}
        >
          วันที่แก้ไขข้อมูลล่าสุด{' '}
          {dataDetail?.updated_at
            ? helper.ToConvertDateTimeForTable(dataDetail?.updated_at)
            : ''}
        </Typography>

        <div className={styles.titleCard} style={{ paddingTop: '27px' }}>
          <Typography fontSize={16} color={'grey.main'} fontWeight={500}>
            ข้อมูลทั่วไป
          </Typography>
        </div>
        <Controller
          name="active"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ paddingTop: '20px', width: '100%' }}>
              <FieldSelect
                name="สถานะใช้งาน"
                nameDescription="(ค่าเริ่มต้น : ใช้งาน)"
                options={ActiveStatus}
                value={value}
                onChange={onChange}
                style={{ width: '-webkit-fill-available' }}
              />
            </div>
          )}
        />
        <Controller
          name="profile_type"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldSelect
                name="ประเภทโปรไฟล์*"
                options={ProfileType}
                value={value}
                onChange={onChange}
                style={{ width: '-webkit-fill-available' }}
              />
            </div>
          )}
        />
        <Controller
          name="profile_type"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldInput
                label="ชื่อผู้ใช้งานหลักในโปรไฟล์"
                value={`${
                  dataDetail?.user_info.prefix === 0
                    ? 'นาย'
                    : dataDetail?.user_info.prefix === 1
                    ? 'นางสาว'
                    : 'นาง'
                }${dataDetail?.user_info.firstname} ${
                  dataDetail?.user_info.lastname
                }`}
                readOnly={true}
                style={{ width: '-webkit-fill-available' }}
              />
            </div>
          )}
        />
        <Controller
          name="company_name"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldInput
                label="ชื่อบริษัท*"
                subLabel="(บังคับเฉพาะนิติบุคคลส่วนบุคลทั่วไปจะดึงข้อมูลส่วนของตัวของผู้ใช้หลักมาระบุ)"
                value={value}
                onChange={onChange}
                readOnly={profile_type === 0 ? true : false}
                style={{ width: '-webkit-fill-available' }}
              />
            </div>
          )}
        />
        <Controller
          name="tax_id"
          control={control}
          rules={{
            pattern: {
              value: /^[0-9]+$/,
              message: 'ระบุเป็นตัวเลขได้เท่านั้น',
            },
          }}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldInput
                label="เลขประจำตัวผู้เสียภาษี*"
                subLabel="(บังคับเฉพาะนิติบุคคลส่วนบุคลทั่วไปจะดึงข้อมูลส่วนตัวของผู้ใช้หลักมาระบุ)"
                value={value}
                onChange={(e) => handleMaxDigit(e, 'tax_id', 13)}
                // readOnly={profile_type === 0 ? true : false}
                style={{ width: '-webkit-fill-available' }}
              />
            </div>
          )}
        />
        <Controller
          name="certificate_file"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldUpload
                name="ไฟล์หนังสือรับรอง*"
                nameDescription="(บังคับเฉพาะนิติบุคคลส่วนบุคลทั่วไปจะดึงข้อมูลส่วนตัวของผู้ใช้หลักมาระบุ)"
                value={value}
                onChange={onChange}
                accept="image/jpeg, image/png, application/pdf"
                readOnly={profile_type === 0 ? true : false}
                style={{ width: '-webkit-fill-available' }}
              />
            </div>
          )}
        />
      </div>
    );
  };
  const renderCard2 = () => {
    return (
      <div className={styles.cardDetail}>
        <div className={styles.titleCard} style={{ paddingTop: '27px' }}>
          <Typography fontSize={16} color={'grey.main'} fontWeight={500}>
            ข้อมูลทั่วไป
          </Typography>
        </div>
        <Controller
          name="branch_code"
          control={control}
          rules={{
            required: 'กรุณากรอก',
          }}
          render={({ field: { onChange, value } }) => (
            <div style={{ paddingTop: '20px', width: '100%' }}>
              <FieldInput
                label="รหัสสาขา*"
                value={value}
                onChange={onChange}
                style={{ width: '-webkit-fill-available' }}
                error={!!errors.branch_code}
                helperText={
                  errors.branch_code ? errors.branch_code.message : ''
                }
              />
            </div>
          )}
        />
        <Controller
          name="address"
          control={control}
          rules={{
            required: checkRoles?.includes('carrier') ? 'กรุณากรอก' : false,
          }}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldInput
                label="ที่อยู่*"
                subLabel="(บังคับเฉพาะถ้าผู้ใช้หลักมีบทบาทเป็นผู้ขนส่ง)"
                value={value}
                onChange={onChange}
                style={{ width: '-webkit-fill-available' }}
                error={!!errors.address}
                helperText={errors.address ? errors.address.message : ''}
              />
            </div>
          )}
        />
        <Controller
          name="province_id"
          control={control}
          rules={{
            required: checkRoles?.includes('carrier') ? 'กรุณากรอก' : false,
          }}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldSelect
                name="จังหวัด*"
                nameDescription="(บังคับเฉพาะถ้าผู้ใช้หลักมีบทบาทเป็นผู้ขนส่ง)"
                options={province}
                value={value}
                onChange={onChange}
                style={{ width: '-webkit-fill-available' }}
                error={!!errors.province_id}
                errorText={errors.province_id ? errors.province_id.message : ''}
              />
            </div>
          )}
        />
        <Controller
          name="district_id"
          control={control}
          rules={{
            required: checkRoles?.includes('carrier') ? 'กรุณากรอก' : false,
          }}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldSelect
                name="เขต/อำเภอ*"
                nameDescription="(บังคับเฉพาะถ้าผู้ใช้หลักมีบทบาทเป็นผู้ขนส่ง)"
                options={district}
                value={value}
                onChange={onChange}
                style={{ width: '-webkit-fill-available' }}
                error={!!errors.district_id}
                errorText={errors.district_id ? errors.district_id.message : ''}
              />
            </div>
          )}
        />
        <Controller
          name="subdistrict_id"
          control={control}
          rules={{
            required: checkRoles?.includes('carrier') ? 'กรุณากรอก' : false,
          }}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldSelect
                name="แขวง/ตำบล*"
                nameDescription="(บังคับเฉพาะถ้าผู้ใช้หลักมีบทบาทเป็นผู้ขนส่ง)"
                options={subdistrict}
                value={value}
                onChange={onChange}
                style={{ width: '-webkit-fill-available' }}
                error={!!errors.subdistrict_id}
                errorText={
                  errors.subdistrict_id ? errors.subdistrict_id.message : ''
                }
              />
            </div>
          )}
        />
        <Controller
          name="postcode"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldInput
                label="รหัสไปรษณีย์"
                subLabel="(ระบบจะดึงจากฐานข้อมูล)"
                value={value}
                readOnly={true}
                style={{ width: '-webkit-fill-available' }}
              />
            </div>
          )}
        />
        <Controller
          name="bank_id"
          control={control}
          rules={{
            required: checkRoles?.includes('carrier') ? 'กรุณากรอก' : false,
            pattern: {
              value: /^[0-9]+$/,
              message: 'ระบุเป็นตัวเลขได้เท่านั้น',
            },
          }}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldSelect
                name={`ธนาคาร*`}
                nameDescription="(บังคับเฉพาะถ้าผู้ใช้หลักมีบทบาทเป็นผู้ขนส่ง)"
                options={bank}
                value={value}
                onChange={onChange}
                style={{ width: '-webkit-fill-available' }}
                error={!!errors.bank_id}
                errorText={errors.bank_id ? errors.bank_id.message : ''}
              />
            </div>
          )}
        />
        <Controller
          name="bank_account_name"
          control={control}
          rules={{
            required: checkRoles?.includes('carrier') ? 'กรุณากรอก' : false,
          }}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldInput
                label="ชื่อบัญชีธนาคาร*"
                subLabel="(บังคับเฉพาะถ้าผู้ใช้หลักมีบทบาทเป็นผู้ขนส่ง)"
                value={value}
                onChange={onChange}
                style={{ width: '-webkit-fill-available' }}
                readOnly={profile_type === 0 ? true : false}
                error={!!errors.bank_account_name}
                helperText={
                  errors.bank_account_name
                    ? errors.bank_account_name.message
                    : ''
                }
              />
            </div>
          )}
        />
        <Controller
          name="bank_account_number"
          control={control}
          rules={{
            required: checkRoles?.includes('carrier') ? 'กรุณากรอก' : false,
          }}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldInput
                label="เลขที่บัญชีธนาคาร*"
                subLabel="(บังคับเฉพาะถ้าผู้ใช้หลักมีบทบาทเป็นผู้ขนส่ง)"
                value={value}
                onChange={onChange}
                style={{ width: '-webkit-fill-available' }}
                error={!!errors.bank_account_number}
                helperText={
                  errors.bank_account_number
                    ? errors.bank_account_number.message
                    : ''
                }
              />
            </div>
          )}
        />
        <Controller
          name="bank_account_file"
          control={control}
          rules={{
            required: checkRoles?.includes('carrier') ? 'กรุณากรอก' : false,
          }}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldUpload
                name="ไฟล์เอกสารบัญชีธนาคาร*"
                nameDescription="(บังคับเฉพาะถ้าผู้ใช้หลักมีบทบาทเป็นผู้ขนส่ง)"
                value={value}
                onChange={onChange}
                accept="image/jpeg, image/png, application/pdf"
                style={{ width: '-webkit-fill-available' }}
                error={!!errors.bank_account_file}
                textError={
                  errors.bank_account_file
                    ? errors.bank_account_file.message
                    : ''
                }
              />
            </div>
          )}
        />
      </div>
    );
  };
  const renderCard3 = () => {
    return (
      <div className={styles.cardDetail}>
        <div className={styles.titleCard} style={{ paddingTop: '27px' }}>
          <Typography fontSize={16} color={'grey.main'} fontWeight={500}>
            ข้อมูลลูกค้า
          </Typography>
        </div>
        <Controller
          name="shipper_grade_type"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ paddingTop: '20px', width: '100%' }}>
              <FieldSelect
                name="เกรดลูกค้า"
                options={GradeType}
                value={value}
                onChange={onChange}
                readOnly={checkRoles?.includes('shipper') ? false : true}
                style={{ width: '-webkit-fill-available' }}
              />
            </div>
          )}
        />
        <Controller
          name="shipper_payment_type"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldSelect
                name="ประเภทการชำระเงินของลูกค้า*"
                nameDescription="(ค่าเริ่มต้น : เงินสด)"
                options={PaymentType}
                value={value}
                onChange={onChange}
                readOnly={checkRoles?.includes('shipper') ? false : true}
                style={{ width: '-webkit-fill-available' }}
              />
            </div>
          )}
        />
        <Controller
          name="shipper_load_area"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: `100%` }}>
              <FieldSelectArea
                levelArea={['provinces']}
                name="พื้นที่รับสินค้า"
                onChange={onChange}
                value={value}
                readOnly={checkRoles?.includes('shipper') ? false : true}
                showChild={true}
              />
            </div>
          )}
        />
        <Controller
          name="shipper_unload_area"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: `100%` }}>
              <FieldSelectArea
                levelArea={['provinces']}
                name="พื้นที่ส่งสินค้า"
                value={value}
                onChange={onChange}
                readOnly={checkRoles?.includes('shipper') ? false : true}
                showChild={true}
              />
            </div>
          )}
        />
        <Controller
          name="shipper_product_type"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldSelect
                name="ประเภทสินค้าที่ขนส่ง"
                options={productType}
                value={value}
                onChange={onChange}
                defaultValue={[]}
                multiSelect={true}
                readOnly={checkRoles?.includes('shipper') ? false : true}
                style={{ width: '-webkit-fill-available' }}
              />
            </div>
          )}
        />
        <Controller
          name="shipper_truck_type"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldSelect
                name="ประเภทรถที่ต้องการใช้"
                options={truckType}
                value={value}
                onChange={onChange}
                defaultValue={[]}
                multiSelect={true}
                readOnly={checkRoles?.includes('shipper') ? false : true}
                style={{ width: '-webkit-fill-available' }}
              />
            </div>
          )}
        />
        <Controller
          name="shipper_accessories"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldSelect
                name="อุปกรณ์เสริมติดรถที่ต้องการใช้"
                options={accessories}
                value={value}
                onChange={onChange}
                defaultValue={[]}
                multiSelect={true}
                readOnly={checkRoles?.includes('shipper') ? false : true}
                style={{ width: '-webkit-fill-available' }}
              />
            </div>
          )}
        />
        <Controller
          name="shipper_optional"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldSelect
                name="บริการเสริมที่ต้องการใช้"
                options={optional}
                value={value}
                onChange={onChange}
                defaultValue={[]}
                multiSelect={true}
                readOnly={checkRoles?.includes('shipper') ? false : true}
                style={{ width: '-webkit-fill-available' }}
              />
            </div>
          )}
        />
        <Controller
          name="shipper_qualification"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldSelect
                name="คุณสมบัติที่เกี่ยวข้องที่ต้องการของลูกค้า"
                options={qualification}
                value={value}
                onChange={onChange}
                defaultValue={[]}
                multiSelect={true}
                readOnly={checkRoles?.includes('shipper') ? false : true}
                style={{ width: '-webkit-fill-available' }}
              />
            </div>
          )}
        />
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '48px',
            backgroundColor: 'rgba(140, 163, 186, 0.20)',
            borderBottom: `1px solid rgba(18, 31, 67, 0.5)`,
          }}
        >
          <Rating
            disabled
            value={parseFloat(dataDetail?.shipper_score)}
            precision={0.1}
            style={{ opacity: 1 }}
            icon={
              <StarRateRounded
                sx={{ fontSize: '38px', color: 'warning.main' }}
              />
            }
            emptyIcon={
              <StarRateRounded sx={{ fontSize: '38px', color: 'grey.main' }} />
            }
          />
          <Typography color={'grey.main'}>
            {dataDetail?.shipper_score.toFixed(2)} คะแนนเฉลี่ยสะสม
          </Typography>
        </div>
        {/* <Controller
          name="token_line_group"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldInput
                label="Token กลุ่มไลน์"
                value={value}
                onChange={onChange}
                style={{ width: '-webkit-fill-available' }}
              />
            </div>
          )}
        />
        <Controller
          name="shipper_platform"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <FieldSelect
                name="แพลตฟอร์มที่ใช้"
                options={platform}
                value={value}
                onChange={onChange}
                defaultValue={[]}
                multiSelect={true}
                style={{ width: '-webkit-fill-available' }}
              />
            </div>
          )}
        /> */}
      </div>
    );
  };
  // const renderCard4 = () => {
  //   return (
  //     <div className={styles.cardDetail}>
  //       <div className={styles.titleCard} style={{ paddingTop: '27px' }}>
  //         <Typography fontSize={16} color={'grey.main'} fontWeight={500}>
  //           ข้อมูลลูกค้า
  //         </Typography>
  //       </div>
  //       <Controller
  //         name="shipper_approved_status"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ paddingTop: '20px', width: '100%' }}>
  //             <FieldSelect
  //               name="สถานะการตรวจสอบเอกสาร*"
  //               nameDescription="(ค่าเริ่มต้น : อนุมัติแล้ว)"
  //               options={ApproveStatus}
  //               value={value}
  //               onChange={onChange}
  //               readOnly={checkRoles?.includes('shipper') ? false : true}
  //               style={{ width: '-webkit-fill-available' }}
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="shipper_reason_for_edit"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldInput
  //               label="เหตุผล"
  //               descriptionUnderField="เหตุผลสำหรับกรณีมีสถานะเอกสารเป็นแก้ไขเพิ่มเติม"
  //               value={value}
  //               onChange={onChange}
  //               readOnly={shipper_approved_status === 2 ? false : true}
  //               style={{ width: '-webkit-fill-available' }}
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="shipper_approved_at"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldInput
  //               label="วันที่อนุมัติล่าสุด"
  //               value={value ? helper.ToConvertDateTimeForTable(value) : ''}
  //               onChange={onChange}
  //               style={{ width: '-webkit-fill-available' }}
  //               readOnly={true}
  //             />
  //           </div>
  //         )}
  //       />
  //       <div style={{ width: '100%' }}>
  //         <FieldInput
  //           label="ผู้ดูแลระบบ (ผู้ตรวจสอบ)"
  //           value={
  //             dataDetail?.shipper_admin_approved
  //               ? `${dataDetail?.shipper_admin_approved?.firstname}  ${dataDetail?.shipper_admin_approved?.lastname}`
  //               : ''
  //           }
  //           style={{ width: '-webkit-fill-available' }}
  //           readOnly={true}
  //         />
  //       </div>
  //       <div
  //         style={{
  //           width: '100%',
  //           display: 'flex',
  //           flexDirection: 'row',
  //           alignItems: 'center',
  //           height: '48px',
  //           backgroundColor: 'rgba(140, 163, 186, 0.20)',
  //           borderBottom: `1px solid rgba(18, 31, 67, 0.5)`,
  //         }}
  //       >
  //         <Rating
  //           disabled
  //           value={parseFloat(dataDetail?.shipper_score)}
  //           precision={0.1}
  //           style={{ opacity: 1 }}
  //           icon={
  //             <StarRateRounded
  //               sx={{ fontSize: '38px', color: 'warning.main' }}
  //             />
  //           }
  //           emptyIcon={
  //             <StarRateRounded sx={{ fontSize: '38px', color: 'grey.main' }} />
  //           }
  //         />
  //         <Typography color={'grey.main'}>
  //           {dataDetail?.shipper_score.toFixed(2)} คะแนนเฉลี่ยสะสม
  //         </Typography>
  //       </div>
  //     </div>
  //   );
  // };
  // const renderCard5 = () => {
  //   return (
  //     <div className={styles.cardDetail}>
  //       <div className={styles.titleCard} style={{ paddingTop: '27px' }}>
  //         <Typography fontSize={16} color={'grey.main'} fontWeight={500}>
  //           ข้อมูลผู้ขนส่ง
  //         </Typography>
  //       </div>
  //       <Controller
  //         name="carrier_launch_year"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ paddingTop: '20px', width: '100%' }}>
  //             <FieldInput
  //               label="ปีที่เริ่มกิจการ*"
  //               subLabel="(บังคับเฉพาะถ้าผู้ใช้หลักมีบทบาทเป็นผู้ขนส่ง)"
  //               value={value}
  //               onChange={onChange}
  //               style={{ width: '-webkit-fill-available' }}
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="carrier_transport_license"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldSelect
  //               name="ใบอนุญาตประกอบการขนส่งไม่ประจำทาง*"
  //               nameDescription="(บังคับเฉพาะถ้าผู้ใช้หลักมีบทบาทเป็นผู้ขนส่ง)"
  //               options={[
  //                 { value: true, name: 'มี' },
  //                 { value: false, name: 'ไม่มี' },
  //               ]}
  //               value={value}
  //               onChange={onChange}
  //               style={{ width: '-webkit-fill-available' }}
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="carrier_transport_license_file"
  //         control={control}
  //         rules={{
  //           required:
  //             carrier_transport_license === true
  //               ? 'กรุณาอัพโหลดไฟล์ใบอนุญาตประกอบการขนส่งไม่ประจำทาง'
  //               : false,
  //         }}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldUpload
  //               name="ไฟล์ใบอนุญาตประกอบการขนส่งไม่ประจำทาง*"
  //               nameDescription="(บังคับเฉพาะถ้าผู้ใช้หลักมีบทบาทเป็นผู้ขนส่ง)"
  //               value={value}
  //               onChange={onChange}
  //               accept="image/jpeg, image/png, application/pdf"
  //               style={{ width: '-webkit-fill-available' }}
  //               readOnly={carrier_transport_license === true ? false : true}
  //               error={!!errors.carrier_transport_license_file}
  //               textError={
  //                 errors.carrier_transport_license_file
  //                   ? errors.carrier_transport_license_file.message
  //                   : ''
  //               }
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="carrier_grade_type"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldSelect
  //               name="เกรดผู้ขนส่ง"
  //               options={GradeType}
  //               value={value}
  //               onChange={onChange}
  //               style={{ width: '-webkit-fill-available' }}
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="carrier_payment_type"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldSelect
  //               name="ประเภทการรับเงินของผู้ขนส่ง*"
  //               nameDescription="(ค่าเริ่มต้น: เงินสด)"
  //               options={PaymentType}
  //               value={value}
  //               onChange={onChange}
  //               style={{ width: '-webkit-fill-available' }}
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="carrier_load_area"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: `100%` }}>
  //             <FieldSelectArea
  //               levelArea={['provinces']}
  //               name="พื้นที่รับสินค้า"
  //               onChange={onChange}
  //               value={value}
  //               showChild={true}
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="carrier_unload_area"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: `100%` }}>
  //             <FieldSelectArea
  //               levelArea={['provinces']}
  //               name="พื้นที่ส่งสินค้า"
  //               onChange={onChange}
  //               value={value}
  //               showChild={true}
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="carrier_product_type"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldSelect
  //               name="ประเภทสินค้าที่รับขนส่ง*"
  //               nameDescription="(บังคับเฉพาะถ้าผู้ใช้หลักมีบทบาทเป็นผู้ขนส่ง)"
  //               options={productType}
  //               value={value}
  //               onChange={onChange}
  //               defaultValue={[]}
  //               multiSelect={true}
  //               style={{ width: '-webkit-fill-available' }}
  //             />
  //           </div>
  //         )}
  //       />
  //     </div>
  //   );
  // };
  // const renderCard6 = () => {
  //   return (
  //     <div className={styles.cardDetail}>
  //       <div className={styles.titleCard} style={{ paddingTop: '27px' }}>
  //         <Typography fontSize={16} color={'grey.main'} fontWeight={500}>
  //           ข้อมูลผู้ขนส่ง
  //         </Typography>
  //       </div>
  //       <Controller
  //         name="carrier_accessories"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ paddingTop: '20px', width: '100%' }}>
  //             <FieldSelect
  //               name="อุปกรณ์เสริมติดรถที่มี"
  //               options={accessories}
  //               value={value}
  //               onChange={onChange}
  //               defaultValue={[]}
  //               multiSelect={true}
  //               style={{ width: '-webkit-fill-available' }}
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="carrier_optional"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldSelect
  //               name="บริการเสริมที่มี"
  //               options={optional}
  //               value={value}
  //               onChange={onChange}
  //               defaultValue={[]}
  //               multiSelect={true}
  //               style={{ width: '-webkit-fill-available' }}
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="carrier_qualification"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldSelect
  //               name="คุณสมบัติที่เกี่ยวข้องที่มี"
  //               options={qualification}
  //               value={value}
  //               onChange={onChange}
  //               defaultValue={[]}
  //               multiSelect={true}
  //               style={{ width: '-webkit-fill-available' }}
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="carrier_association"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldSelect
  //               name="สังกัดสมาคม"
  //               options={association}
  //               value={value}
  //               onChange={onChange}
  //               defaultValue={[]}
  //               multiSelect={true}
  //               style={{ width: '-webkit-fill-available' }}
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="carrier_approved_status"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldSelect
  //               name="สถานะการตรวจสอบเอกสาร*"
  //               nameDescription="(ค่าเริ่มต้น: รอการอนุมัติและคืนค่าเริ่มต้นเมื่อมีแก้ไขข้อมูล) "
  //               options={ApproveStatus}
  //               value={value}
  //               onChange={onChange}
  //               style={{ width: '-webkit-fill-available' }}
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="carrier_reason_for_edit"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldInput
  //               label="เหตุผล"
  //               descriptionUnderField="เหตุผลสำหรับกรณีมีสถานะเอกสารเป็นแก้ไขเพิ่มเติม"
  //               value={value}
  //               onChange={onChange}
  //               readOnly={carrier_approved_status === 2 ? false : true}
  //               style={{ width: '-webkit-fill-available' }}
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="carrier_approved_at"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldInput
  //               label="วันที่อนุมัติล่าสุด"
  //               value={value ? helper.ToConvertDateTimeForTable(value) : ''}
  //               onChange={onChange}
  //               readOnly={true}
  //               style={{ width: '-webkit-fill-available' }}
  //             />
  //           </div>
  //         )}
  //       />

  //       <div style={{ width: '100%' }}>
  //         <FieldInput
  //           label="ผู้ดูแลระบบ(ผู้ตรวจสอบ)"
  //           value={
  //             dataDetail?.carrier_admin_approved
  //               ? `${dataDetail?.carrier_admin_approved?.firstname}  ${dataDetail?.carrier_admin_approved?.lastname}`
  //               : ''
  //           }
  //           readOnly={true}
  //           style={{ width: '-webkit-fill-available' }}
  //         />
  //       </div>

  //       <div
  //         style={{
  //           width: '100%',
  //           display: 'flex',
  //           flexDirection: 'row',
  //           alignItems: 'center',
  //           height: '48px',
  //           backgroundColor: 'rgba(140, 163, 186, 0.20)',
  //           borderBottom: `1px solid rgba(18, 31, 67, 0.5)`,
  //         }}
  //       >
  //         <Rating
  //           disabled
  //           value={parseFloat(dataDetail?.carrier_score)}
  //           style={{ opacity: 1 }}
  //           precision={0.01}
  //           icon={
  //             <StarRateRounded
  //               sx={{ fontSize: '38px', color: 'warning.main' }}
  //             />
  //           }
  //           emptyIcon={
  //             <StarRateRounded sx={{ fontSize: '38px', color: 'grey.main' }} />
  //           }
  //         />
  //         <Typography color={'grey.main'}>
  //           {dataDetail?.carrier_score.toFixed(2)} คะแนนเฉลี่ยสะสม
  //         </Typography>
  //       </div>
  //     </div>
  //   );
  // };
  // const renderCard7 = () => {
  //   return (
  //     <div className={styles.cardDetail}>
  //       <div className={styles.titleCard} style={{ paddingTop: '27px' }}>
  //         <Typography fontSize={16} color={'grey.main'} fontWeight={500}>
  //           จุดจอดรถ
  //         </Typography>
  //       </div>
  //       {profileParking.map((val, index) => {
  //         return (
  //           <div
  //             style={{
  //               width: '100%',
  //               paddingBottom: '23px',
  //               paddingTop: index === 0 ? '20px' : '',
  //             }}
  //             key={index}
  //           >
  //             <div
  //               style={{
  //                 borderBottom: `1px solid var( --mui-palette-primary-main)`,
  //                 height: '48px',
  //                 display: 'flex',
  //                 alignItems: 'center',
  //                 justifyContent: 'space-between',
  //               }}
  //             >
  //               <div
  //                 style={{ paddingLeft: '16px' }}
  //                 onClick={() =>
  //                   setOpenModalEditParking({ open: true, data: val })
  //                 }
  //               >
  //                 <Typography fontSize={'10px'} style={{ lineHeight: '1' }}>
  //                   จุดจอดที่ {index + 1}
  //                 </Typography>
  //                 <Typography fontSize={'16px'}>{val.name}</Typography>
  //               </div>
  //               <div
  //                 style={{
  //                   paddingRight: '10px',
  //                   alignItems: 'center',
  //                   display: 'flex',
  //                 }}
  //               >
  //                 <DoDisturbOnOutlined
  //                   sx={{
  //                     fontSize: '25px',
  //                     color: 'primary.main',
  //                     ':hover': {
  //                       color: 'error.main',
  //                     },
  //                     cursor: 'pointer',
  //                   }}
  //                   onClick={() => {
  //                     deleteParkingById(val.id);
  //                   }}
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //         );
  //       })}
  //       <div
  //         style={{
  //           width: '100%',
  //           paddingBottom: '23px',
  //           paddingTop: profileParking.length === 0 ? '23px' : 0,
  //         }}
  //       >
  //         <Paper
  //           elevation={0}
  //           sx={{
  //             border: `1px dashed var(--Grey, #8CA3BA)`,
  //             borderRadius: '10px',
  //             height: '50px',
  //             display: 'flex',
  //             justifyContent: 'center',
  //             alignItems: 'center',
  //             cursor: 'pointer',
  //           }}
  //           onClick={() => setOpenModalAddParking(true)}
  //         >
  //           <AddCircle
  //             sx={{ fontSize: '25px', color: theme.palette.grey.main }}
  //           />
  //           <Typography
  //             sx={{
  //               fontSize: '16px',
  //               p: ' 0 10px',
  //               color: 'grey.main',
  //               fontWeight: 500,
  //             }}
  //           >
  //             เพิ่มจุดจอดรถ
  //           </Typography>
  //         </Paper>
  //       </div>
  //     </div>
  //   );
  // };
  // const renderCard8 = () => {
  //   return (
  //     <div className={styles.cardDetail}>
  //       <div className={styles.titleCard} style={{ paddingTop: '27px' }}>
  //         <Typography fontSize={16} color={'grey.main'} fontWeight={500}>
  //           {`ประเภทรถที่มี(Manual)`}
  //         </Typography>
  //       </div>
  //       <Controller
  //         name="wheels_4"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ paddingTop: '20px', width: '100%' }}>
  //             <FieldInput
  //               label="รถ 4 ล้อ*"
  //               subLabel="(ค่าเริ่มต้น 0)"
  //               type="number"
  //               value={value}
  //               onChange={onChange}
  //               style={{ width: '-webkit-fill-available' }}
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="wheels_6"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldInput
  //               label="รถ 6 ล้อ*"
  //               subLabel="(ค่าเริ่มต้น 0)"
  //               type="number"
  //               value={value}
  //               onChange={onChange}
  //               style={{ width: '-webkit-fill-available' }}
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="wheels_10"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldInput
  //               label="รถ 10 ล้อ*"
  //               subLabel="(ค่าเริ่มต้น 0)"
  //               type="number"
  //               value={value}
  //               onChange={onChange}
  //               style={{ width: '-webkit-fill-available' }}
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="wheels_12"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldInput
  //               label="รถ 12 ล้อ*"
  //               subLabel="(ค่าเริ่มต้น 0)"
  //               type="number"
  //               value={value}
  //               onChange={onChange}
  //               style={{ width: '-webkit-fill-available' }}
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="trailer"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldInput
  //               label="รถเทรลเลอร์*"
  //               subLabel="(ค่าเริ่มต้น 0)"
  //               type="number"
  //               value={value}
  //               onChange={onChange}
  //               style={{ width: '-webkit-fill-available' }}
  //             />
  //           </div>
  //         )}
  //       />
  //       <Controller
  //         name="double_truck"
  //         control={control}
  //         render={({ field: { onChange, value } }) => (
  //           <div style={{ width: '100%' }}>
  //             <FieldInput
  //               label="รถพ่วง*"
  //               subLabel="(ค่าเริ่มต้น 0)"
  //               type="number"
  //               value={value}
  //               onChange={onChange}
  //               style={{ width: '-webkit-fill-available' }}
  //             />
  //           </div>
  //         )}
  //       />
  //     </div>
  //   );
  // };
  // const renderCard9 = () => {
  //   return (
  //     <div className={styles.cardDetail}>
  //       <div className={styles.titleCard} style={{ paddingTop: '27px' }}>
  //         <Typography fontSize={16} color={'grey.main'} fontWeight={500}>
  //           {`ประเภทรถที่มี(Count from database)`}
  //         </Typography>
  //       </div>
  //       {truckType &&
  //         truckType?.map((val, i) => {
  //           return (
  //             <div
  //               key={i}
  //               style={{ paddingTop: i === 0 ? '20px' : 0, width: '100%' }}
  //             >
  //               <FieldInput
  //                 label={val.name}
  //                 subLabel="(ค่าเริ่มต้น 0)"
  //                 value={truckCounts[val.value] || 0}
  //                 // onChange={onChange}
  //                 readOnly={true}
  //                 style={{ width: '-webkit-fill-available' }}
  //               />
  //             </div>
  //           );
  //         })}
  //     </div>
  //   );
  // };

  useEffect(() => {
    if (carrier_approved_status !== 2) {
      setValue('carrier_reason_for_edit', '');
    }
  }, [carrier_approved_status]);

  useEffect(() => {
    if (shipper_approved_status !== 2) {
      setValue('shipper_reason_for_edit', '');
    }
  }, [shipper_approved_status]);

  const onSubmit = async (data) => {
    setLoading(true);
    console.log('onSubmit', data);
    const changedData = {};
    //check array
    if (
      Array.isArray(data.carrier_accessories) &&
      JSON.stringify(data.carrier_accessories) !==
        JSON.stringify(initialFormValues.carrier_accessories)
    ) {
      changedData.carrier_accessories = data.carrier_accessories;
    }
    if (
      data.roles &&
      JSON.stringify(data.roles) !== JSON.stringify(initialFormValues.roles)
    ) {
      changedData.roles = data.roles;
    }

    Object.keys(data).forEach((fieldName) => {
      if (
        Array.isArray(data[fieldName]) &&
        JSON.stringify(data[fieldName]) !==
          JSON.stringify(initialFormValues[fieldName])
      ) {
        changedData[fieldName] = data[fieldName];
      } else if (
        !Array.isArray(data[fieldName]) &&
        data[fieldName] !== initialFormValues[fieldName]
      ) {
        changedData[fieldName] = data[fieldName];
        if (data[fieldName] === '') {
          changedData[fieldName] = null;
        } else {
          changedData[fieldName] = data[fieldName];
        }
      }
    });

    console.log('changedData', changedData);
    const GForm = new FormData();
    Object.keys(changedData).forEach((key) => {
      if (Array.isArray(changedData[key])) {
        GForm.append(key, JSON.stringify(changedData[key]));
      } else if (changedData[key] === undefined) {
        GForm.delete(key);
      } else {
        GForm.append(key, changedData[key]);
      }
    });
    GForm.delete('confirmPassword');

    if (profile_id) {
      await editProfile(profile_id, GForm)
        .then(async (response) => {
          console.log('response', response);
          if (response.data.code === 1000) {
            setLoading(false);
            setAlertModal({
              open: true,
              title: 'บันทึก',
              desc: 'บันทึกข้อมูลสำเร็จ',
            });
            console.log('edit Success');
            if (changedData?.carrier_approved_status !== undefined) {
              const carrierApprove = ApproveStatus.find(
                (res) => res.value === changedData?.carrier_approved_status,
              );

              const param_noti = {
                user_code: [dataDetail?.user_info.user_code],
                title: `โปรไฟล์ของคุณ`,
                message: `${carrierApprove.name} ${
                  changedData?.carrier_approved_status === 2
                    ? 'เหตุผล : ' +
                      `${
                        changedData?.carrier_reason_for_edit
                          ? changedData?.carrier_reason_for_edit
                          : ''
                      }`
                    : ''
                }`,
                for_shipper: false,
                for_carrier: true,
                for_driver: true,
                important: false,
              };
              console.log('noti_param', param_noti);
              // await sendNoti(param_noti)
              //   .then((res) => console.log('send_noti success'))
              //   .catch((error) => {
              //     console.log('error', error);
              //   });
            }
          }
        })
        .catch(async (error) => {
          console.log('error', error);
          if (error) {
            setLoading(false);
            if (error.response.data.code === 4106) {
              setErrorAlert({
                open: true,
                desc: error.response.data.description,
              });
            } else if (error.response.data.code === '4105') {
              removeAccessToken('access_token');
              window?.location?.reload();
            }
          }
        });
    }
  };
  return (
    <React.Fragment>
      {loading && <Loading />}
      {alertModal.open === true && (
        <AlertModal
          open={alertModal.open}
          title={alertModal.title}
          description={alertModal.desc}
          onClose={() => {
            setAlertModal({
              open: false,
              title: '',
              desc: '',
            });
            window.location.reload();
            // allParking();
          }}
        />
      )}
      {errorAlert.open === true && (
        <AlertModal
          open={errorAlert.open}
          title="เกิดข้อผิดพลาด"
          description={errorAlert.desc}
          onClose={() => {
            setErrorAlert({
              open: false,
              desc: '',
            });
            navigate(-1);
          }}
        />
      )}
      <Container>
        <UserDetailSubMenu
          label={`ข้อมูลโปรไฟล์`}
          pathbackward={-1}
          profile_id={dataDetail?.id}
          user_id={dataDetail?.user_info.id}
          user_code={dataDetail?.user_info.user_code}
          breadcrumbs={breadcrumbs}
        />
        {/* <SubMenu menus={subUserID} breadcrumbs={breadcrumbs} /> */}
      </Container>
      <form
        className={styles.containerDetailProfile}
        onSubmit={handleSubmit(onSubmit)}
      >
        {renderCard1()}
        {renderCard2()}
        {renderCard3()}
        {/* {renderCard4()} 
        {renderCard5()}
        {renderCard6()} 
        {renderCard7()}
        {renderCard8()}
        {renderCard9()} */}
        <Avatar
          alt="Avatar Save"
          sx={{
            position: 'fixed',
            bottom: '35px',
            right: '97px',
            width: 100,
            height: 100,
            bgcolor: 'secondary.main',
            cursor: 'pointer',
            zIndex: 200,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          <Save sx={{ color: 'white', fontSize: '70px' }} />
        </Avatar>
      </form>
      {/* {openModalAddParking === true ? (
        <ModalAddParking
          open={openModalAddParking}
          handleClose={() => {
            setOpenModalAddParking(false);
            allParking();
          }}
          alertSuccess={() => {
            setAlertModal({
              open: true,
              title: 'บันทึก',
              desc: 'บันทึกข้อมูลสำเร็จ',
            });
          }}
          alertError={(e) => {
            setErrorAlert(e);
          }}
        />
      ) : openModalEditParking.open === true ? (
        <ModalAddParking
          open={openModalEditParking.open}
          data={openModalEditParking.data}
          type="edit"
          handleClose={() => {
            setOpenModalEditParking({ open: false, data: {} });
            allParking();
          }}
          alertSuccess={() => {
            setAlertModal({
              open: true,
              title: 'บันทึก',
              desc: 'บันทึกข้อมูลสำเร็จ',
            });
          }}
          alertError={(e) => {
            setErrorAlert(e);
          }}
        />
      ) : (
        ''
      )} */}
    </React.Fragment>
  );
};

export default DetailProfile;
