import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import './modal.css';
import ButtonCustom from 'components/button/ButtonCustom';
const AlertModal = ({
  open = false,
  onClose = () => {},
  title = '',
  description = '',
}) => {
  useEffect(() => {
    // console.log("do modal alert");
  }, []);
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiPaper-root': {
            minWidth: '466px',
            minHeight: '218px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '20px',
          },
        }}
      >
        <DialogTitle sx={{ fontSize: '20px', pt: '31px', pl: 0, pr: 0, pb: 0 }}>
          {title}
        </DialogTitle>
        <DialogContent sx={{ alignItems: 'center', p: 0, display: 'flex' }}>
          <DialogContentText
            sx={{
              color: 'grey.main',
              fontSize: '20px',
            }}
          >
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            pb: '30px',
          }}
        >
          <ButtonCustom onClick={onClose} name="ปิด" />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AlertModal;
