import React from "react";
import { Button } from "antd";
import SearchIcon from "@mui/icons-material/Search";

const IconButton = ({
  onClick,
  classNames,
  renderIcon = () => <SearchIcon sx={{ fontSize: "40px", color: "#121F43" }} />,
  styles,
}) => {
  const handleOnClick = () => {
    onClick();
  };

  return (
    <Button
      className={classNames}
      variant="text"
      onClick={handleOnClick}
      style={{
        width: "50px",
        height: "50px",
        flexShrink: "0",
        filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
        padding: "8px",
        border: 0,
        ...styles,
      }}
    >
      {renderIcon()}
    </Button>
  );
};

export default IconButton;
