export const GradeType = [
  { value: 0, name: "Bronze" },
  { value: 1, name: "Silver" },
  { value: 2, name: "Gold" },
  { value: 3, name: "Platinum" },
  { value: 4, name: "Diamond" },
];

export const ActiveStatus = [
  { value: true, name: "ใช้งาน" },
  { value: false, name: "ระงับ" },
];

export const ProfileType = [
  { value: 0, name: "บุคคลธรรมดา" },
  { value: 1, name: "นิติบุคล" },
];

export const PaymentType = [
  { value: 0, name: "เงินสด" },
  { value: 1, name: "เครดิต" },
];

export const ApproveStatus = [
  { value: 0, name: "รอการอนุมัติ" },
  { value: 1, name: "อนุมัติแล้ว" },
  { value: 2, name: "แก้ไขเพิ่มเติม" },
  { value: 3, name: "ไม่อนุมัติ" },
  { value: 4, name: "เอกสารหมดอายุ" },
];

export const ApproveStatusFilter = [
  { value: null, name: "รอเพิ่มข้อมูล" },
  { value: 0, name: "รอการอนุมัติ" },
  { value: 1, name: "อนุมัติแล้ว" },
  { value: 2, name: "แก้ไขเพิ่มเติม" },
  { value: 3, name: "ไม่อนุมัติ" },
  { value: 4, name: "เอกสารหมดอายุ" },
];

export const AdminRole = [
  { value: 0, name: "SuperAdmin" },
  { value: 1, name: "ฝ่ายขาย" },
  { value: 2, name: "ฝ่ายบริการลูกค้า" },
  { value: 3, name: "ฝ่ายปฏิบัติการ" },
  { value: 4, name: "ฝ่ายการเงินและบัญชี" },
  { value: 5, name: "ฝ่ายเทคโนโลยีสารสนเทศ" },
];

export const truck_document_approve_status = [
  {
    id: 0,
    name: "รอการอนุมัติ",
  },
  {
    id: 1,
    name: "อนุมัติแล้ว",
  },
  {
    id: 2,
    name: "แก้ไขเพิ่มเติม",
  },
  {
    id: 3,
    name: "ไม่อนุมัติ",
  },
  {
    id: 4,
    name: "เอกสารหมดอายุ",
  },
  // {
  //   id: 5,
  //   name: "รอเพิ่มข้อมูล",
  // },
];

export const cover_rate_insurance_product = [
  {
    id: 1,
    name: "0 - 99999",
  },
  {
    id: 2,
    name: "100000 - 199999",
  },
  {
    id: 3,
    name: "200000 - 299999",
  },
  {
    id: 4,
    name: "300000 - 399999",
  },
  {
    id: 5,
    name: "400000 - 499999",
  },
  {
    id: 6,
    name: "500000 - 599999",
  },
  {
    id: 7,
    name: "600000 - 699999",
  },
  {
    id: 8,
    name: "700000 - 799999",
  },
  {
    id: 9,
    name: "800000 - 899999",
  },
  {
    id: 10,
    name: "900000 - 999999",
  },
  {
    id: 11,
    name: "1000000 - 1499999",
  },
  {
    id: 12,
    name: "1500000 - 1999999",
  },
  {
    id: 13,
    name: "2000000 - 2499999",
  },
  {
    id: 14,
    name: "2500000 - 2999999",
  },
  {
    id: 15,
    name: "3000000 - 3499999",
  },
  {
    id: 16,
    name: "3500000 - 3999999",
  },
  {
    id: 17,
    name: "4000000 - 4499999",
  },
  {
    id: 18,
    name: "4500000 - 4999999",
  },
  {
    id: 19,
    name: "5000000 - 5499999",
  },
  {
    id: 20,
    name: "5500000 - 5999999",
  },
  {
    id: 21,
    name: "6000000 - 6499999",
  },
  {
    id: 22,
    name: "6500000 - 6999999",
  },
  {
    id: 23,
    name: "7000000 - 7499999",
  },
  {
    id: 24,
    name: "7500000 - 7999999",
  },
  {
    id: 25,
    name: "8000000 - 8499999",
  },
  {
    id: 26,
    name: "8500000 - 8999999",
  },
  {
    id: 27,
    name: "9000000 - 9499999",
  },
  {
    id: 28,
    name: "9500000 - 9999999",
  },
  {
    id: 29,
    name: "ตั้งแต่ 10000000 ขึ้นไป",
  },
];

export const ProjectProgression = [
  { value: 1, name: "ติดต่อลูกค้า" },
  { value: 2, name: "นัดหมายขอเข้าพบลูกค้า" },
  { value: 3, name: "นำเสนอขายลูกค้า" },
  { value: 4, name: "เก็บข้อมูลการวิ่งงาน" },
  { value: 5, name: "จัดทำใบเสนอราคา" },
  { value: 6, name: "รอทีมผู้บริหารอนุมัติใบเสนอราคา" },
  { value: 7, name: "ส่งใบเสนอราคาลูกค้า" },
  { value: 8, name: "ลูกค้าเลือกใบเสนอราคา" },
  { value: 9, name: "ลูกค้าไม่เลือกใบเสนอราคา" },
  { value: 10, name: "แก้ไขใบเสนอราคา" },
  { value: 11, name: "ทดลองวิ่งงาน" },
  { value: 12, name: "วัดKPIในการทดลองวิ่งงาน" },
  { value: 13, name: "ผ่านเซ็นสัญญาวิ่งงาน" },
  { value: 14, name: "ไม่ผ่านหยุดวิ่งงาน" },
  { value: 15, name: "saleติดตามผลลูกค้าใหม่" },
  { value: 16, name: "AMติดตามผลลูกค้าเก่า" },
  { value: 17, name: "ปิดโครงการ" },
];

export const ProjectCarrierProgression = [
  { value: 1, name: "ติดต่อผู้ขนส่ง" },
  { value: 2, name: "ได้รับRFIจากsale" },
  { value: 3, name: "OPคัดเลือกผู้ขนส่ง" },
  { value: 4, name: "ส่งรายละเอียดงานให้ผู้ขนส่ง" },
  { value: 5, name: "ขอใบเสนอราคา" },
  { value: 6, name: "คัดเลือกใบเสนอราคา" },
  { value: 7, name: "ส่งใบเสนอราคาให้Sale" },
];

export const ProjectFrequencyType = [
  { value: 0, name: "งานชั่วคราวเที่ยวเดียว" },
  { value: 1, name: "งานชั่วคราวหลายเที่ยว" },
  { value: 2, name: "งานประจำไม่มีสัญญา" },
  { value: 3, name: "งานประจำมีสัญญา" },
];

export const WorkType = [
  { value: 0, name: "งานภายในประเทศ" },
  { value: 1, name: "งานนำเข้า" },
  { value: 2, name: "งานส่งออก" },
  { value: 3, name: "งานข้ามแดน" },
];

export const FreightType = [
  { value: 0, name: "งานขาไป" },
  { value: 1, name: "งานขากลับ" },
  { value: 2, name: "งานไปกลับ" },
];

export const ReminderDate = [
  { value: 1, name: "1" },
  { value: 2, name: "2" },
  { value: 3, name: "3" },
  { value: 4, name: "4" },
  { value: 5, name: "5" },
  { value: 6, name: "6" },
  { value: 7, name: "7" },
  { value: 8, name: "8" },
  { value: 9, name: "9" },
  { value: 10, name: "10" },
  { value: 11, name: "11" },
  { value: 12, name: "12" },
  { value: 13, name: "13" },
  { value: 14, name: "14" },
  { value: 15, name: "15" },
  { value: 16, name: "16" },
  { value: 17, name: "17" },
  { value: 18, name: "18" },
  { value: 19, name: "19" },
  { value: 20, name: "20" },
  { value: 21, name: "21" },
  { value: 22, name: "22" },
  { value: 23, name: "23" },
  { value: 24, name: "24" },
  { value: 25, name: "25" },
  { value: 26, name: "26" },
  { value: 27, name: "27" },
  { value: 28, name: "28" },
];

export const PlaceType = [
  { value: 0, name: "ที่พักอาศัย" },
  { value: 1, name: "บริษัทห้างร้าน" },
  { value: 2, name: "ห้างสรรพสินค้า" },
  { value: 3, name: "มีชานชลาขึ้น-ลงสินค้า / รถโฟคลิฟต์" },
  { value: 4, name: "โรงพยาบาล / สถานบริการทางการแพทย์" },
  { value: 5, name: "ศาสนสถาน / วัด / โบสถ์ / มัสยิด" },
  { value: 6, name: "หน่วยงานราชการ" },
  { value: 7, name: "ท่าเรือ / สนามบิน" },
  { value: 8, name: "หน้างานก่อสร้าง" },
  { value: 9, name: "อื่น ๆ" },
];

export const TruckManual = [
  { value: 0, name: "รถ 4 ล้อ" },
  { value: 1, name: "รถ 6 ล้อ" },
  { value: 2, name: "รถ 10 ล้อ" },
  { value: 3, name: "รถ 12 ล้อ" },
  { value: 4, name: "รถเทรลเลอร์" },
  { value: 5, name: "รถพ่วง" },
];

// export const truck_brand = [
//   {
//     value: 0,
//     name: "SUZU",
//   },
//   {
//     value: 1,
//     name: "HINO",
//   },
//   {
//     value: 2,
//     name: "MITSUBISHIFUSO",
//   },
//   {
//     value: 3,
//     name: "NISSANUD",
//   },
//   {
//     value: 4,
//     name: "TOYOTA",
//   },
//   {
//     value: 5,
//     name: "VOLVO",
//   },
//   {
//     value: 6,
//     name: "UDTRUCKS",
//   },
//   {
//     value: 7,
//     name: "SCANIA",
//   },
//   {
//     value: 8,
//     name: "TADANO",
//   },
//   {
//     value: 9,
//     name: "SINOTRUK",
//   },
//   {
//     value: 10,
//     name: "FOTON",
//   },
//   {
//     value: 11,
//     name: "SANY",
//   },
//   {
//     value: 12,
//     name: "FAW",
//   },
//   {
//     value: 13,
//     name: "CAMC",
//   },
//   {
//     value: 14,
//     name: "PANUS",
//   },
//   {
//     value: 15,
//     name: "SAMMIT",
//   },
// ];
