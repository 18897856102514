import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { formLabelClasses } from '@mui/material';

const FieldSelect = ({
  name = ``,
  nameDescription = ``,
  defaultValue = null,
  options = [{ value: undefined, name: undefined }],
  onChange = () => {},
  onClose = () => {},
  className = ``,
  style = {},
  error = false,
  errorText = ``,
  value = ``,
  closePaddingBottom = false,
  readOnly = false,
  multiSelect = false,
  showSelectAll = false,
  descriptionUnderField = ``,
}) => {
  const [valueSelect, setValueSelect] = useState(
    multiSelect ? defaultValue : '',
  );

  const handleSelectAll = () => {
    const allValues = options.map((option) => option.value);
    const newSelected = valueSelect.length === options.length ? [] : allValues;
    onChange(newSelected);
    if (multiSelect) {
      setValueSelect(newSelected);
    }
  };

  return (
    <div
      style={{
        pointerEvents: readOnly ? 'none' : '',
        paddingBottom:
          error || closePaddingBottom || descriptionUnderField ? 0 : '23px',
        display: 'flex',
        flexDirection: 'column',
        // marginBottom: 23,
      }}
    >
      <FormControl
        variant="standard"
        className={className}
        sx={{
          // width: "-webkit-fill-available",
          // paddingBottom: error || closePaddingBottom ? 0 : "23px",
          bgcolor: readOnly ? 'rgba(140, 163, 186, 0.20)' : 'white',
          ...style,
        }}
        // disabled={readOnly}
        error={error || false}
      >
        <InputLabel
          id="demo-simple-select-standard-label"
          className="label"
          sx={{
            color: error ? 'error.main' : 'grey.main',
            fontWeight: 500,
            lineHeight: nameDescription ? 1 : '',
            '& p': {
              fontSize: '10px',
            },
            [`&.${formLabelClasses.focused}`]: {
              marginTop: `0 !important`,
              '& p': {
                fontSize: '10px',
              },
            },
            [`&.${formLabelClasses.filled}`]: {
              marginTop: `0 !important`,
              '& p': {
                fontSize: '10px',
              },
            },
            marginTop: nameDescription ? '-10px' : '',
            padding: '0 14px',
          }}
        >
          {name}
          <br />
          {nameDescription && (
            <p style={{ margin: '5px 0' }}>{nameDescription}</p>
          )}
        </InputLabel>

        <Select
          // labelId="demo-simple-select-standard-label"
          // id="demo-simple-select-standard"
          value={
            value || value === 0 || value === false
              ? value
              : valueSelect
              ? valueSelect
              : ''
          }
          multiple={multiSelect}
          renderValue={
            multiSelect
              ? (selected) => {
                  if (showSelectAll && selected.length === options.length) {
                    return 'ทั้งหมด';
                  } else {
                    return options
                      .filter((option) => value.includes(option.value))
                      .map((option) => option.name)
                      .join(', ');
                  }
                }
              : null
          }
          onChange={(event) => {
            if (multiSelect && event?.target.value.includes('selectAll')) {
              handleSelectAll();
            } else {
              onChange(event.target.value);
              if (multiSelect) {
                setValueSelect(event.target.value);
              }
            }
          }}
          onClose={(event) => {
            onClose(event);
          }}
          // label={name}
          sx={{
            '& .MuiInputBase-input': {
              padding: '4px 14px 5px 14px',
            },
            '& .MuiSvgIcon-root ': {
              color: error ? 'error.main' : 'grey.main',
            },
          }}
          // helperText={errorText}
        >
          {/* <MenuItem value="">
          <em>--</em>
        </MenuItem> */}
          {multiSelect && showSelectAll && (
            <MenuItem value="selectAll">
              <Checkbox checked={value.length === options.length} />
              <ListItemText primary="ทั้งหมด" />
            </MenuItem>
          )}
          {options &&
            options?.map((val) =>
              multiSelect ? (
                <MenuItem key={val.value} value={val.value}>
                  <Checkbox checked={value.includes(val.value)} />
                  <ListItemText primary={val.name} />
                </MenuItem>
              ) : (
                <MenuItem key={val.value} value={val.value}>
                  {val.name}
                </MenuItem>
              ),
            )}
        </Select>
      </FormControl>
      {errorText ? (
        <FormHelperText
          sx={{
            color: 'error.main',
            padding: '0 14px',
          }}
        >
          {errorText}
        </FormHelperText>
      ) : descriptionUnderField ? (
        <FormHelperText
          sx={{
            color: value ? 'primary.main' : 'grey.main',
            fontSize: '11px',
            padding: '0 14px',
          }}
        >
          {descriptionUnderField}
        </FormHelperText>
      ) : (
        ''
      )}
    </div>
  );
};

export default FieldSelect;
