import { useState } from "react";
import Title from "./Title";
import { Box, Grid } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CircleIcon from "@mui/icons-material/Circle";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

export default function Chart({ data, title, color, unit }) {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const page1 = data.slice(0, 5);
  const page2 = data.slice(5, 10);

  const barBgColor = color.slice(0, -1) + ", 0.2)";

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: `${barBgColor}`,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: `${color}`,
    },
  }));
  const title_want = [
    "ปริมาณงานที่จับคู่แล้วแยกตามประเภทรถ",
    "รายได้จากงานที่จับคู่แล้วแยกตามประเภทรถ",
  ];
  const objectFit = title_want.includes(title) ? "contain" : "cover";

  const RenderRow = ({ item }) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={1}>
          {item.icon !== null && (
            <img
              src={item.icon}
              style={{
                height: "100%",
                width: "100%",
                maxHeight: 30,
                maxWidth: 30,
                objectFit: objectFit,
              }}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <Box
            component="div"
            sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
          >
            {item.name}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Grid
            container
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <Grid item xs={8} sx={{ pr: "13px" }}>
              <BorderLinearProgress
                variant="determinate"
                value={item.percent}
              />
            </Grid>
            <Grid item xs={4}>
              {item.percent}%
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: "right", pr: "4px" }}>
          {item.value}
        </Grid>
        <Grid item xs={1}>
          {unit}
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Title>{title}</Title>
      <Box sx={{ width: "100%" }} className="ProgressChart">
        <TabContext value={value}>
          <TabPanel value="1">
            <Box className="ProgressChart-TabPanel-Box">
              {page1.map((item, index) => (
                <RenderRow item={item} key={index} />
              ))}
            </Box>
          </TabPanel>
          <TabPanel value="2">
            <Box className="ProgressChart-TabPanel-Box">
              {page2.map((item, index) => (
                <RenderRow item={item} key={index} />
              ))}
            </Box>
          </TabPanel>
          <Box
            sx={{
              height: "15px",
            }}
          >
            <TabList onChange={handleChange} centered>
              <Tab label={<CircleIcon />} value="1" />
              <Tab label={<CircleIcon />} value="2" />
            </TabList>
          </Box>
        </TabContext>
      </Box>
    </>
  );
}
