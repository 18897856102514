// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const Theme = () => {
  const greyColors = { main: '#8CA3BA' };
  const contrastText = '#fff';

  return {
    primary: {
      main: '#121F43',
      contrastText,
    },
    secondary: {
      main: '#121F43',
    },
    error: {
      main: '#FF316F',
      contrastText,
    },
    warning: {
      main: '#FFC675',
    },
    success: {
      main: '#27CE88',
      contrastText,
    },
    grey: greyColors,

    whenhover: 'rgba(0, 0, 0, 0.04)',

    purple_theme: '#8C8DFF',
    orange_theme: '#F9A38A',
  };
};

export default Theme;
