import instance from '../../axiosInstance';

export const getAllProject = async (param) => {
  try {
    return instance.get(`/api/tps/project`, {
      params: param,
    });
  } catch (error) {
    console.log('error', error);
  }
};

export const addProject = async (body) => {
  try {
    return instance.post(`/api/tps/project`, body, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  } catch (error) {
    console.log('error', error);
  }
};

export const getProjectById = async (project_id) => {
  try {
    return instance.get(`/api/tps/project/${project_id}`);
  } catch (error) {
    console.log('error', error);
  }
};

export const editProject = async (project_id, body) => {
  try {
    return instance.patch(`/api/tps/project/${project_id}`, body, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  } catch (error) {
    console.log('error', error);
  }
};

export const calculateCreditProject = async (project_id, param) => {
  try {
    return instance.get(
      `/api/tps/project/calculate-credit-project/${project_id}`,
      {
        params: param,
      },
    );
  } catch (error) {
    console.log(error);
  }
};
