import React, { useEffect } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  ComposedChart,
  ReferenceLine,
} from "recharts";
import Title from "./Title";
import * as currency from "currency.js";

const testdata = [
  {
    name: "Page 4",
    "On time": 800,
    Delay: 200,
    "Waiting Confirm": 23,
    percent: 50,
  },
  {
    name: "Page 2",
    "On time": 800,
    Delay: 200,
    "Waiting Confirm": 23,
    percent: 50,
  },
  {
    name: "Page 3",
    "On time": 800,
    Delay: 200,
    "Waiting Confirm": 23,
    percent: 50,
  },
  {
    name: "Page 4",
    "On time": 800,
    Delay: 200,
    "Waiting Confirm": 23,
    percent: 50,
  },
];

export default function BiaxialBarChart({ title, data = testdata }) {
  const [rawData, setRawData] = React.useState(null);
  const [maxY, setMaxY] = React.useState(0);
  const maxY2 = 100;

  useEffect(() => {
    const maxY = data.reduce((oldVal, newVal) => {
      const sum =
        parseInt(newVal["On time"]) +
        parseInt(newVal.Delay) +
        parseInt(newVal["Waiting Confirm"]);
      if (sum > oldVal) {
        return sum;
      }
      return oldVal;
    }, 0);
    const newData = data.map((item) => {
      const ontime = parseInt(item["On time"]) ?? 0;
      const delay = parseInt(item.Delay) ?? 0;
      const waiting = parseInt(item["Waiting Confirm"]) ?? 0;
      const n = ontime + delay + waiting;
      const percent = (ontime / n) * 100;
      const formatPercent = currency(percent, { symbol: "" }).format();
      return {
        name: item.name,
        "On time": ontime,
        Delay: delay,
        "Waiting Confirm": waiting,
        "On Time%": formatPercent,
      };
    });
    setMaxY(maxY);
    setRawData(newData);
  }, [data]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Title>{title}</Title>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={500}
          height={300}
          data={rawData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis yAxisId="left" orientation="left" domain={[0, maxY]} />
          <YAxis yAxisId="right" orientation="right" domain={[0, maxY2]} />
          <ReferenceLine
            yAxisId="right"
            y={95}
            stroke="red"
            label="Target 95%"
          />
          <Tooltip />
          <Legend />
          <Bar yAxisId="left" dataKey="On time" fill="#0a1247" />
          <Bar yAxisId="left" dataKey="Delay" fill="#af4424" />
          <Bar yAxisId="left" dataKey="Waiting Confirm" fill="#089bcc" />
          <Line yAxisId="right" dataKey="On Time%" stroke="#6a329f" />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
}
