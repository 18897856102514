import React, { useState } from "react";
import { Button } from "antd";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Box, Drawer } from "@mui/material";

const AdvanceSearchButton = ({
  classNames = "",
  styles = {},
  onReset = () => {},
  children,
  onSearch = () => {},
  value = {},
}) => {
  const [anchor, setAnchor] = useState(false);
  const [oldValue, setOldValue] = useState({});
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (open) {
      setOldValue(value);
    } else if (open === false && value !== oldValue) {
      onSearch();
    }
    setAnchor(open);
  };

  return (
    <>
      <Button
        className={classNames}
        variant="text"
        onClick={toggleDrawer(anchor, true)}
        style={{
          width: "50px",
          height: "50px",
          flexShrink: "0",
          filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
          padding: "8px",
          border: 0,
          ...styles,
        }}
      >
        <FilterAltIcon sx={{ fontSize: "40px", color: "#121F43" }} />
      </Button>
      <Drawer
        anchor={`right`}
        open={anchor}
        onClose={toggleDrawer(anchor, false)}
      >
        <Box sx={{ width: "350px", margin: "34px 31px " }}>
          <Box
            sx={{ paddingBottom: "26px" }}
            onClick={toggleDrawer(anchor, false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
            >
              <rect
                width="30"
                height="30"
                rx="10"
                transform="matrix(-1 0 0 1 30 0)"
                fill="#8CA3BA"
                fillOpacity="0.1"
              />
              <line
                y1="-0.5"
                x2="20.4"
                y2="-0.5"
                transform="matrix(0 1 1 0 23.4001 4.79688)"
                stroke="#8CA3BA"
              />
              <path
                d="M19.307 14.2929C19.6975 14.6834 19.6975 15.3166 19.307 15.7071L12.943 22.0711C12.5525 22.4616 11.9193 22.4616 11.5288 22.0711C11.1383 21.6805 11.1383 21.0474 11.5288 20.6569L17.1857 15L11.5288 9.34315C11.1383 8.95262 11.1383 8.31946 11.5288 7.92893C11.9193 7.53841 12.5525 7.53841 12.943 7.92893L19.307 14.2929ZM5.99988 14L18.5999 14V16H5.99988V14Z"
                fill="#8CA3BA"
              />
            </svg>
          </Box>
          <Box sx={{ paddingBottom: "26px" }}>
            <span
              style={{
                color: "#000",
                fontFamily: "Kanit",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              }}
            >
              การกรองและจัดเรียง
            </span>
          </Box>
          <Box>{children}</Box>
          <Box className="text-center">
            <Button
              onClick={onReset}
              style={{
                width: "350px",
                height: "50px",
                borderRadius: "10px",
                background: "var(--dark, #121F43)",
              }}
            >
              <span
                style={{
                  color: "#FFF",
                  textAlign: "center",
                  fontFamily: "Kanit",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
              >
                ล้างการกรอง
              </span>
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default AdvanceSearchButton;
