import React from "react";
import { Button } from "@mui/material";

const ButtonCustom = ({ type, name, style, onClick, fullWidth, disabled }) => {
  return type === "cancel" ? (
    <Button
      variant="outlined"
      size="large"
      onClick={onClick}
      style={{
        ...style,
        width: "160px",
        height: "50px",
        boxShadow: "0px 4px 4px 0px rgba(140, 163, 186, 0.20)",
        borderRadius: "10px",
        border: "1px solid #8CA3BA",
        backgroundColor: "#FFF",
        color: "#8CA3BA",
        fontWeight: 500,
      }}
    >
      {name}
    </Button>
  ) : type === "submit" ? (
    <Button
      variant="outlined"
      size="large"
      onClick={onClick}
      type="submit"
      sx={{
        ...style,
        width: "160px",
        height: "50px",
        boxShadow: "0px 4px 4px 0px rgba(140, 163, 186, 0.20)",
        borderRadius: "10px",
        border: "none",
        backgroundColor: "primary.main",
        color: "primary.contrastText",
        fontWeight: 500,
        ":hover": {
          color: "secondary.main",
          borderColor: "secondary.main",
        },
      }}
    >
      {name}
    </Button>
  ) : disabled === true ? (
    <Button
      variant="outlined"
      size="large"
      onClick={onClick}
      sx={{
        // width: "160px",
        ...style,
        height: "50px",
        boxShadow: "0px 4px 4px 0px rgba(140, 163, 186, 0.20)",
        borderRadius: "10px",
        border: "none",
        backgroundColor: "grey.main",
        color: "white",
        fontWeight: 500,
        pointerEvents: "none",
        ":hover": {
          color: "secondary.main",
          borderColor: "grey.main",
        },
      }}
      fullWidth={fullWidth}
    >
      {name}
    </Button>
  ) : (
    <Button
      variant="outlined"
      size="large"
      onClick={onClick}
      sx={{
        // width: "160px",
        ...style,
        height: "50px",
        boxShadow: "0px 4px 4px 0px rgba(140, 163, 186, 0.20)",
        borderRadius: "10px",
        border: "none",
        backgroundColor: "primary.main",
        color: "primary.contrastText",
        fontWeight: 500,
        ":hover": {
          color: "secondary.main",
          borderColor: "secondary.main",
        },
      }}
      fullWidth={fullWidth}
    >
      {name}
    </Button>
  );
};

export default ButtonCustom;
