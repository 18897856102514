import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as R from 'ramda';
import { useCookies } from 'react-cookie';
import CustomModal from 'components/modal/CustomModal';
import API from 'utils/api/auth/auth';
import styles from './Home.module.css';
import helper from 'utils/helper';
const api = API.create();

const Home = () => {
  const navigate = useNavigate();
  const { windowWidth: width, windowHeight: height } = helper.useWindowSize();
  const [listMenu, setListMenu] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
  });
  const [openModal, setOpenModal] = useState({
    type: '',
    open: false,
    title: '',
    description: '',
  });
  const [, , removeAccessToken] = useCookies(['access_token']);

  const initial_menu = [
    {
      image: require('../../assets/images/home/User.png'),
      label: 'ข้อมูลผู้ใช้งาน',
      link: '/user',
    },
    {
      image: require('../../assets/images/home/Project.png'),
      label: 'ข้อมูลโครงการ',
      link: '/project',
    },
    {
      image: require('../../assets/images/home/PostWork.png'),
      label: 'ประกาศงาน',
      link: '/post-work',
    },
    {
      image: require('../../assets/images/home/Work.png'),
      label: 'ข้อมูลงาน',
      link: '/work',
    },
    {
      image: require('../../assets/images/home/Finance.png'),
      label: 'ข้อมูลการเงิน',
      link: '/finance/shipper/unpaid',
    },
    {
      image: require('../../assets/images/home/Graph.png'),
      label: 'แผนภูมิและกราฟ',
      link: '/dashboard/work',
    },
    {
      image: require('../../assets/images/home/LogOut.png'),
      label: 'ออกจากระบบ',
      // link: "",
      onClick: () => {
        setOpenModal({
          type: 'logOut',
          open: true,
          title: 'ยืนยันออกระบบ',
          description: 'ยืนยันออกระบบ?',
        });
      },
    },
  ];

  useEffect(() => {
    const total_page = Math.ceil(initial_menu.length / pagination.per_page);
    const last_menu_index = pagination.page * pagination.per_page;
    const start_menu_index = last_menu_index - pagination.per_page;

    let _list_menu = [...initial_menu];
    // _list_menu = _list_menu.splice(start_menu_index, last_menu_index);
    if (pagination.page === 2 && pagination.per_page === 4) {
      _list_menu = _list_menu.splice(start_menu_index, last_menu_index - 4);
    } else {
      _list_menu = _list_menu.splice(start_menu_index, last_menu_index);
    }
    setListMenu(_list_menu);

    // eslint-disable-next-line
  }, [pagination]);

  useEffect(() => {
    if (width > 576 && width <= 768) {
      setPagination({ ...pagination, per_page: 4 });
    } else if (width > 768 && width <= 992) {
      setPagination({ ...pagination, per_page: 6 });
    } else if (width > 992 && width <= 1460) {
      setPagination({ ...pagination, per_page: 8 });
    } else {
      setPagination({ ...pagination, per_page: 10 });
    }

    // eslint-disable-next-line
  }, [width]);

  const renderModal = () => {
    if (openModal.open === true) {
      return (
        <CustomModal
          open={openModal.open}
          title={openModal.title}
          onCancel={() => {
            setOpenModal({
              type: '',
              open: false,
              title: '',
              description: '',
            });
          }}
          onConfirm={async () => {
            if (openModal.type === 'logOut') {
              await api
                .logout()
                .then((res) => {
                  removeAccessToken('access_token');
                  navigate('/login');
                })
                .catch((e) => console.log(e));
            }

            setOpenModal({
              type: '',
              open: false,
              title: '',
              description: '',
            });
          }}
        >
          <div>{openModal.description}</div>
        </CustomModal>
      );
    }
  };

  const renderMenu = () => {
    return (
      <div className={styles.cardContainer}>
        {listMenu.map((menu) => {
          return (
            <div
              className={styles.card}
              onClick={() => {
                if (!R.isNil(menu.link)) {
                  navigate(menu.link);
                }

                if (!R.isNil(menu.onClick)) {
                  menu.onClick();
                }
              }}
            >
              <div className={styles.imageContainer}>
                <img src={menu.image} alt={menu.name} draggable={false} />
              </div>
              <p>{menu.label}</p>
            </div>
          );
        })}
      </div>
    );
  };

  const renderPagination = () => {
    const number_of_page = Math.ceil(initial_menu.length / pagination.per_page);

    return (
      <div className={styles.paginationContainer}>
        {Array.from({ length: number_of_page }, (_, index) => {
          return (
            <div
              key={index + 1}
              className={`${styles.paginationSpot} ${
                index + 1 === pagination.page ? styles.active : styles.inActive
              }`}
              onClick={() => {
                setPagination({ ...pagination, page: index + 1 });
              }}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {renderMenu()}
      {renderPagination()}

      {renderModal()}
    </div>
  );
};

export default Home;
