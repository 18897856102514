import { Box, Container, Grid } from '@mui/material';
import SubMenu from 'components/layout/SubMenu.js';
import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import { subMenuDasdboard } from 'utils/submenuoptions';
import moment from 'moment';
import FieldSelect from 'components/field/FieldSelect';
import FieldDateRangePicker from 'components/field/FieldDateRangePicker';
import SearchButton from 'components/button/SearchButton';
import ResetButton from 'components/button/ResetButton';
import Loading from 'components/loading/Loading';
import { getDashbaordKPI } from 'utils/api/dashboard/dashbaord';
import FieldAutoComplete from 'components/field/FieldAutoComplete';
import { getAllProjectTPS, getAllShipper } from 'utils/api/masterData/service';
import * as R from 'ramda';
import BiaxialBarChart from 'components/chart/BiaxialBarChart';
import BiaxialBarChart2 from 'components/chart/BiaxialBarChart2';

const DashboardKPI = () => {
  const [isReady, setIsReady] = useState(false);
  const [subMenu_menus, setSubMenuMenus] = useState([...subMenuDasdboard]);
  const [subMenu_breadcrumbs, setSubMenuBreadcrumbs] = useState([]);
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(6, 'days').format('YYYY-MM-DD 00:00:00'),
  );
  const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD 23:59:59'));
  const [groupBy, setGroupBy] = useState(2);
  const [filterBy, setFilterBy] = useState('projectID');
  const [filter, setFilter] = useState(null);
  const [dashboard1, setDashboard1] = useState(null);
  const [dashboard2, setDashboard2] = useState(null);
  const [dashboard3, setDashboard3] = useState(null);
  const [dashboard4, setDashboard4] = useState(null);
  const [acProject, setAcProject] = useState([]);
  const [acShipper, setAcShipper] = useState([]);

  useEffect(() => {
    setActiveSubMenu(1);
    setSubMenuBreadcrumbs([
      {
        label: 'แผนภูมิและกราฟ',
        path: null,
      },
      {
        label: 'แผนภูมิและกราฟ KPI รับงาน',
        path: null,
      },
    ]);
    fetchDashboard();
    getAutoCompleteProject();
    // getAutoCompleteShipper();
  }, []);

  async function getAutoCompleteProject() {
    try {
      const res = await getAllProjectTPS();
      if (res) {
        setAcProject(res.data.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAutoCompleteShipper() {
    try {
      const res = await getAllShipper();
      if (res) {
        setAcShipper(res.data.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const monthToNumber = (month) => {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return monthNames.indexOf(month);
  };

  const getWeekNumber = (date) => {
    const d = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    );
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    const weekNumber = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return weekNumber < 10 ? '0' + weekNumber : weekNumber.toString();
  };

  const generateDates = (dateFrom, dateTo, groupBy) => {
    const fromDate = new Date(dateFrom);
    const toDate = new Date(dateTo);
    const generatedDates = [];

    if (groupBy === 1) {
      for (
        let year = fromDate.getFullYear();
        year <= toDate.getFullYear();
        year++
      ) {
        generatedDates.push(year.toString());
      }
    } else if (groupBy === 2) {
      const startYear = fromDate.getFullYear();
      const endYear = toDate.getFullYear();
      const startMonth = fromDate.getMonth();
      const endMonth = toDate.getMonth();

      for (let year = startYear; year <= endYear; year++) {
        const monthStart = year === startYear ? startMonth : 0;
        const monthEnd = year === endYear ? endMonth : 11;

        for (let month = monthStart; month <= monthEnd; month++) {
          const date = new Date(year, month, 1);
          generatedDates.push(formatDate2(date));
        }
      }
    } else if (groupBy === 3) {
      const currentDate = new Date(dateFrom);
      while (currentDate <= toDate) {
        const weekNumber = getWeekNumber(currentDate);
        const year = currentDate.getFullYear();
        generatedDates.push(`w${weekNumber}/${year}`);
        currentDate.setDate(currentDate.getDate() + 7);
      }
    } else if (groupBy === 4) {
      const currentDate = new Date(dateFrom);
      while (currentDate <= toDate) {
        generatedDates.push(formatDate4(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }
    return generatedDates;
  };

  const formatDate1 = (dateString) => {
    return moment(dateString).format('YYYY');
  };

  const formatDate2 = (dateString) => {
    return moment(dateString).format('MMM YYYY');
  };
  const formatDate3 = (dateString) => {
    return dateString;
  };

  const formatDate4 = (dateString) => {
    return moment(dateString).format('DD MMM YYYY');
  };

  async function fetchDashboard() {
    setIsReady(false);
    const requestData = {
      dateFrom: moment(dateFrom).toISOString(),
      dateTo: moment(dateTo).toISOString(),
      groupBy: groupBy,
    };
    if (filterBy !== '' && filter !== null) {
      requestData[filterBy] = filter.value;
    }
    try {
      const response = await getDashbaordKPI(requestData);
      if (response.status === 200) {
        const res = response.data.data;
        const rawData = res.map((item) => {
          if (groupBy === 1) {
            item.groupdate = formatDate1(item.groupdate);
          } else if (groupBy === 2) {
            item.groupdate = formatDate2(item.groupdate);
          } else if (groupBy === 3) {
            item.groupdate = formatDate3(item.groupdate);
          } else if (groupBy === 4) {
            item.groupdate = formatDate4(item.groupdate);
          }
          return item;
        });
        const generatedDates = generateDates(dateFrom, dateTo, groupBy);
        generatedDates.forEach((generatedDate) => {
          if (!rawData.some((item) => item.groupdate === generatedDate)) {
            rawData.push({
              groupdate: generatedDate,
              kpi: [],
            });
          }
        });

        if (groupBy === 2) {
          rawData.sort((a, b) => {
            const [aMonth, aYear] = a.groupdate.split(' ');
            const [bMonth, bYear] = b.groupdate.split(' ');
            const aMonthNumber = monthToNumber(aMonth);
            const bMonthNumber = monthToNumber(bMonth);

            if (aYear === bYear) {
              return aMonthNumber - bMonthNumber;
            } else {
              return aYear.localeCompare(bYear);
            }
          });
        } else if (groupBy === 3) {
          rawData.sort((a, b) => {
            const aWeekYear = a.groupdate.split(' ')[0];
            const bWeekYear = b.groupdate.split(' ')[0];
            const [aWeek, aYear] = aWeekYear.split('/');
            const [bWeek, bYear] = bWeekYear.split('/');
            const aWeekNumber = parseInt(aWeek.split('w')[1]);
            const bWeekNumber = parseInt(bWeek.split('w')[1]);
            if (aYear === bYear) {
              return aWeekNumber - bWeekNumber;
            } else {
              return aYear.localeCompare(bYear);
            }
          });
        } else if (groupBy === 4) {
          rawData.sort((a, b) => {
            const dateA = new Date(a.groupdate);
            const dateB = new Date(b.groupdate);
            return dateA - dateB;
          });
        } else {
          rawData.sort((a, b) => a.groupdate.localeCompare(b.groupdate));
        }

        function getRandomColor() {
          const letters = '0123456789ABCDEF';
          let color = '';
          for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
          }
          return color;
        }

        const data = rawData.map((value, index) => {
          return {
            name: value.groupdate,
            active: value.kpi.active,
            cancel: value.kpi.cancel,
          };
        });

        const data2 = rawData.map((value, index) => {
          return {
            name: value.groupdate,
            'On time': value.kpi_inbound_ontime,
            Delay: value.kpi_inbound_delay,
            'Waiting Confirm': value.kpi_inbound_waiting,
          };
        });

        const data3 = rawData.map((value, index) => {
          return {
            name: value.groupdate,
            'On time': value.kpi_delivery_ontime,
            Delay: value.kpi_delivery_delay,
            'Waiting Confirm': value.kpi_delivery_waiting,
          };
        });

        const data4 = rawData.map((value, index) => {
          return {
            name: value.groupdate,
            'On time': value.kpi_document_ontime,
            Delay: value.kpi_document_delay,
            'Waiting Confirm': value.kpi_document_waiting,
          };
        });

        setDashboard1(data);
        setDashboard2(data2);
        setDashboard3(data3);
        setDashboard4(data4);
      }
      setIsReady(true);
    } catch (error) {
      setIsReady(true);
      console.error('Error fetching Dashboard:', error);
    }
  }

  const setActiveSubMenu = (index) => {
    const updatedMenus = subMenu_menus.map((menu, idx) => {
      return { ...menu, active: idx === index };
    });
    setSubMenuMenus(updatedMenus);
  };

  const handleGroupby = (val) => {
    setGroupBy(val);
  };

  const handleDate = (val) => {
    setDateFrom(val[0]);
    setDateTo(val[1]);
  };

  const handleFilterBy = (val) => {
    setFilterBy(val);
  };

  const handleFilter = (val) => {
    setFilter(val);
  };

  const handleSearch = () => {
    fetchDashboard();
  };

  const handleReset = () => {
    setDateFrom(moment().subtract(6, 'days').format('YYYY-MM-DD'));
    setDateTo(moment().format('YYYY-MM-DD'));
    setGroupBy(2);
    setFilterBy('profileID');
    setFilter(null);
  };

  const renderFilterBar = () => {
    return (
      <Box
        sx={{
          height: '100px',
          width: '100%',
          padding: '26px 37px',
          borderRadius: '10px',
          background: '#fff',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FieldSelect
              className="w-100"
              name={`กรองตาม`}
              options={[
                // { value: 1, name: "ปี" },
                { value: 2, name: 'เดือน' },
                // { value: 3, name: "สัปดาห์" },
                // { value: 4, name: "วัน" },
              ]}
              value={groupBy}
              onChange={handleGroupby}
            />
          </Grid>
          <Grid item xs={2}>
            <FieldDateRangePicker
              className="w-100"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: dateFrom,
                end_date: dateTo,
              }}
              onChange={handleDate}
            />
          </Grid>
          <Grid item xs={2}>
            <FieldSelect
              className="w-100"
              name={`ค้นหาจาก`}
              options={[
                // { value: 'profileID', name: 'ชื่อโปรไฟล์ลูกค้า' },
                { value: 'projectID', name: 'ชื่อโปรเจค' },
              ]}
              value={filterBy}
              onChange={handleFilterBy}
            />
          </Grid>
          <Grid item xs={2}>
            {/* <FieldSearch
              className="w-100"
              value={filter}
              onChange={handleFilter}
            /> */}
            <FieldAutoComplete
              className="w-100"
              value={filter}
              onChange={handleFilter}
              options={filterBy === 'projectID' ? acProject : acShipper}
            />
          </Grid>
          <Grid item xs={2}>
            <SearchButton onClick={handleSearch} />
            <ResetButton styles={{ marginLeft: '5px' }} onClick={handleReset} />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderDasdboard = () => {
    return (
      <div className="div-chart">
        <Box
          className="box-inline box-white"
          sx={{
            height: '625px',
            minWidth: '1150px',
            padding: '18px 25px',
          }}
        >
          <BiaxialBarChart title="KPI รับงาน" data={dashboard1} />
        </Box>
        <Box
          className="box-inline box-white"
          sx={{
            height: '625px',
            minWidth: '1150px',
            padding: '18px 25px',
            marginLeft: '30px',
          }}
        >
          <BiaxialBarChart2 title="KPI รับสินค้า" data={dashboard2} />
        </Box>
        <Box
          className="box-inline box-white"
          sx={{
            height: '625px',
            minWidth: '1150px',
            padding: '18px 25px',
            marginLeft: '30px',
          }}
        >
          <BiaxialBarChart2 title="KPI ส่งสินค้า" data={dashboard3} />
        </Box>
        <Box
          className="box-inline box-white"
          sx={{
            height: '625px',
            minWidth: '1150px',
            padding: '18px 25px',
            marginLeft: '30px',
          }}
        >
          <BiaxialBarChart2 title="KPI เอกสารนำกลับ" data={dashboard4} />
        </Box>
      </div>
    );
  };

  return (
    <Box className="DashbordWork">
      <Container>
        <SubMenu menus={subMenu_menus} breadcrumbs={subMenu_breadcrumbs} />
        {renderFilterBar()}
      </Container>
      {isReady === false ? <Loading /> : renderDasdboard()}
    </Box>
  );
};

export default DashboardKPI;
