import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Loading from 'components/loading/LoadingBackground';
import { Modal, Typography } from '@mui/material';
import styles from './TableFavoritePlace.module.css';
import CloseIcon from '@mui/icons-material/Close';
import ButtonCustom from 'components/ButtonCustom';
import { useForm, Controller } from 'react-hook-form';
import FieldSelect from 'components/field/FieldSelect';
import { PlaceType } from 'utils/enum';
import { addFavoritePlace } from 'utils/api/profile/favoritePlace';
import { useCookies } from 'react-cookie';
import { LongdoMap, map, longdo } from 'components/map/LongdoMap';
import HelperMap from 'utils/helperMap';
import FieldInput from 'components/field/FieldInput';
import ENV from 'utils/environment';
import InputLongdoAutoComplete from 'components/input/InputLongdoAutoComplete';
const ModalAddFavoritePlace = (props) => {
  const [, , removeAccessToken] = useCookies(['access_token']);
  const { profile_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [currentLocation, setCurrentLocation] = useState();
  const [currentPin, setCurrentPin] = useState();
  const { ref } = useRef();
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm({
    defaultValues: {
      location: {
        lat: '',
        long: '',
        search: '',
      },
    },
  });

  const location_info = watch('location');

  useEffect(() => {
    console.log('step', step);
    console.log('location_info', location_info);
  }, [step, location_info]);

  const handleMaxDigit = (e, field, max) => {
    const newValue = e.target.value;
    // Limit input to a maximum of 10 characters
    if (newValue.length <= max) {
      setValue(field, newValue);
    }
    if (newValue.length > 0) {
      clearErrors(field);
    }
  };

  const handlePrevious = () => {
    if (step == 1) {
      props?.handleClose();
    } else if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleNext = async (data) => {
    console.log('data handleNext', data);
    if (step < 2) {
      setStep(step + 1);
    }
  };

  console.log('MApppp', map);
  useEffect(() => {
    if (props?.open) {
      initMap();
    }
  }, [props?.open]);

  const initMap = () => {
    console.log('initMap modal map');
    if (props?.open && map) {
      navigator.geolocation.getCurrentPosition(async function (position) {
        await map.location(
          { lat: position.coords.latitude, lon: position.coords.longitude },
          true,
        );
        let data = await HelperMap.getGeocode({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setCurrentLocation(
          await HelperMap.getDetailLocation(data, {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }),
        );
        const dot = await new longdo.Marker(
          { lat: position.coords.latitude, lon: position.coords.longitude },
          {
            title: 'My Location',
            draggable: false,
            weight: longdo.OverlayWeight.Top,
          },
        );
        setCurrentPin(dot);
        await moveMap({
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        });
        await map.Ui.Fullscreen.visible(true);
        await map.Ui.Zoombar.visible(true);
        // await map.Overlays.add(dot);
      });
    }
  };
  useEffect(() => {
    if (
      props.open &&
      location_info &&
      location_info.lat !== '' &&
      location_info.long !== ''
    ) {
      markMap();
    }
  }, [location_info?.lat, location_info?.long, currentPin]);

  const markMap = async () => {
    if (map) {
      if (currentPin) {
        await map.Overlays.clear();
      }

      const dot = await new longdo.Marker(
        { lat: location_info?.lat, lon: location_info?.long },
        {
          title: 'My Location',
          draggable: false,
          clickable: false,
          weight: longdo.OverlayWeight.Top,
          icon: {
            html:
              '<div class="d-flex flex-row"><img src="' +
              require('assets/images/work/send_pin.png') +
              '" style="height: 30px"/></div>',
          },
        },
      );

      await moveMap({ lat: location_info?.lat, lon: location_info?.long });
      await map.Overlays.add(dot);
      await map.zoom(13, true);
      await map.Ui.Fullscreen.visible(true);
      await map.Ui.Zoombar.visible(true);
      // await map.Overlays.add(currentPin);

      await map.Event.bind('overlayDrop', async function (overlay) {
        console.log(overlay.location());
        let data = await HelperMap.getGeocode({
          lat: overlay.location().lat,
          lng: overlay.location().lon,
        });
        let detail = await HelperMap.getDetailLocation(data, {
          lat: overlay.location().lat,
          lng: overlay.location().lon,
        });
        detail.search = detail.address;
        setValue('location', {
          ...location_info,
          ...detail,
        });
        await moveMap(overlay.location());
      });
    }
  };

  const moveMap = async (location) => {
    map.location(location, true);
  };

  const renderFormPage1 = () => {
    return (
      <div>
        <div className={styles.titleCard}>
          <Typography fontSize={16} color={'grey.main'} fontWeight={500}>
            เพิ่มสถานที่ที่บันทึกไว้
          </Typography>
        </div>
        <div>
          <Controller
            name="location"
            control={control}
            rules={{
              validate: () => {
                if (location_info.lat === '' || location_info.long === '') {
                  return 'กรุณากรอกชื่อสถานที่';
                } else {
                  return true;
                }
              },
            }}
            render={({ field: { onChange, value } }) => (
              <div style={{ paddingTop: '20px' }}>
                {/* <FieldFindLocation
                    onChange={onChange}
                    value={value}
                    error={!!errors.location}
                    errorText={errors.location ? errors.location.message : ""}
                  /> */}
                <InputLongdoAutoComplete
                  value={value}
                  label={'ค้นหาสถานที่'}
                  onChange={(val) => {
                    console.log('val', val);
                    onChange({
                      ...val,
                    });
                  }}
                  closeFav={true}
                  error={!!errors.location}
                />
                <div style={{ color: 'red' }}>
                  {errors.location ? errors.location.message : ''}
                </div>
              </div>
            )}
          />
        </div>
        <div
          style={{
            padding: `25px 0`,
            position: 'relative',
            height: '504px',
          }}
        >
          <LongdoMap
            id={'post-work-main'}
            ref={ref}
            map_key={ENV.MAP_KEY[ENV.ENV].KEY_3}
          />
        </div>
      </div>
    );
  };

  const renderFormPage2 = () => {
    return (
      <div>
        <div className={styles.titleCard}>
          <Typography fontSize={16} color={'grey.main'} fontWeight={500}>
            เพิ่มสถานที่ที่บันทึกไว้
          </Typography>
        </div>
        <Controller
          name="location_name"
          control={control}
          rules={{
            required: 'กรุณากรอกชื่อสถานที่',
          }}
          render={({ field: { onChange, value } }) => (
            <div style={{ paddingTop: '23px' }}>
              <FieldInput
                label="ชื่อสถานที่*"
                style={{ width: '100%' }}
                value={value}
                onChange={onChange}
                error={!!errors.location_name}
                helperText={
                  errors.location_name ? errors.location_name.message : ''
                }
              />
            </div>
          )}
        />
        <Controller
          name="contact_name"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldInput
                label="ผู้ติดต่อ"
                style={{ width: '100%' }}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <Controller
          name="contact_phone"
          control={control}
          rules={{
            required: 'กรุณากรอกเบอร์มือถือ',
            pattern: {
              value: /^(?:\+66|0)[689]\d{8}$/,
              message: 'กรุณากรอกเบอร์มือถือให้ถูกต้อง',
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <div>
              <FieldInput
                label="เบอร์มือถือ*"
                type="number"
                style={{ width: '-webkit-fill-available' }}
                value={value}
                onChange={(e) => handleMaxDigit(e, 'contact_phone', 10)}
                error={!!errors.contact_phone}
                helperText={
                  errors.contact_phone ? errors.contact_phone.message : ''
                }
              />
            </div>
          )}
        />
        <Controller
          name="place_type"
          control={control}
          rules={{
            required: 'กรุณากรอกประเภทสถานที่',
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldSelect
                name="ประเภทสถานที่*"
                style={{ width: '100%' }}
                options={PlaceType}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <Controller
          name="remark"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldInput
                label="หมายเหตุ"
                style={{ width: '100%' }}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
      </div>
    );
  };

  const onSubmit = async (data) => {
    console.log('onSubmit', data);
    setLoading(true);
    const dataJson = {
      location_name: data?.location_name ?? '',
      lat: Number(data?.location.lat) ?? '',
      long: Number(data?.location.long) ?? '',
      address: data?.location.address ?? '',
      district: data?.location.district ?? '',
      subdistrict: data?.location.subdistrict ?? '',
      province: data?.location.province ?? '',
      postal_code: data?.location.postal_code ?? '',
      contact_name: data?.contact_name ?? '',
      contact_phone: data?.contact_phone ?? '',
      place_type: data?.place_type ?? '',
      remark: data?.remark ?? '',
    };
    await addFavoritePlace(profile_id, dataJson)
      .then((response) => {
        if (response.data.code === 1000) {
          props.handleClose();
          setLoading(false);
          props.alertSuccess();
        }
      })
      .catch(async (error) => {
        console.log(error);
        if (error) {
          setLoading(false);
          if (error.response.data.code === 4106) {
            props.alertError({
              open: true,
              desc: error.response.data.description,
            });
          } else if (error.response.data.code === '4105') {
            removeAccessToken('access_token');
            window.location.reload();
          }
        }
      });
  };

  return (
    <div>
      {loading && <Loading loading={loading} />}
      <Modal
        open={props?.open}
        onClose={(event, reason) =>
          reason !== 'backdropClick' ? props?.handleClose : ''
        }
        sx={{ zIndex: 1000 }}
      >
        <div className={styles.modalStyle}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseIcon
              sx={{ color: 'grey.main' }}
              onClick={props?.handleClose}
            />
          </div>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              {step === 1 && renderFormPage1()}
              {step === 2 && renderFormPage2()}
            </form>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name="ย้อนกลับ"
                  onClick={handlePrevious}
                  type={'cancel'}
                />
              </div>
              {step < 2 && (
                <div style={{ flex: 0.5 }}>
                  <ButtonCustom
                    name="ถัดไป"
                    onClick={handleSubmit(handleNext)}
                    fullWidth={true}
                  />
                </div>
              )}
              {step === 2 && (
                <div style={{ flex: 0.5 }}>
                  <ButtonCustom
                    name={'บันทึก'}
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    fullWidth={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalAddFavoritePlace;
