import {
  Box,
  Container,
  FormControl,
  Grid,
  Table,
  TableCell,
  TableRow,
} from '@mui/material';
import FieldDateRangePicker from 'components/field/FieldDateRangePicker';
import SubMenu from 'components/layout/SubMenu.js';
import * as moment from 'moment';
import { useEffect, useState } from 'react';
import {
  // getEWHTGinkgo,
  // getProfile,
  getProject,
  // getReceiptGinkgo,
  // getTruck,
  getUser,
  getWork,
} from 'utils/api/export-data/export-data';
import { subMenuDasdboard } from 'utils/submenuoptions';
import './Dashboard.css';
import CloudDownload from '@mui/icons-material/CloudDownload';
import Loading from 'components/loading/Loading';
import LoadingBackground from 'components/loading/LoadingBackground';
import FieldSelect from 'components/field/FieldSelect';

const ExportData = () => {
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(29, 'days').format('YYYY-MM-DD 00:00:00'),
  );
  const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD 23:59:59'));
  const [isLoading, setIsLoading] = useState(false);
  const [subMenu_menus, setSubMenuMenus] = useState([...subMenuDasdboard]);
  const [subMenu_breadcrumbs, setSubMenuBreadcrumbs] = useState([]);

  useEffect(() => {
    setActiveSubMenu(2);
    setSubMenuBreadcrumbs([
      {
        label: 'แผนภูมิและกราฟ',
        path: null,
      },
      {
        label: 'รายงาน',
        path: null,
      },
    ]);
  }, []);

  const setActiveSubMenu = (index) => {
    const updatedMenus = subMenu_menus.map((menu, idx) => {
      return { ...menu, active: idx === index };
    });
    setSubMenuMenus(updatedMenus);
  };

  const handleOnChangeDate = (e, key) => {
    setDateFrom(e[0]);
    setDateTo(e[1]);
  };

  const handleOnSubmit = async (type) => {
    setIsLoading(true);
    const params = {
      dateFrom: moment(dateFrom).toISOString(),
      dateTo: moment(dateTo).toISOString(),
    };
    try {
      let res;
      if (type === 'work') {
        res = await getWork(params);
      } else if (type === 'project') {
        res = await getProject(params);
      } else if (type === 'user') {
        res = await getUser(params);
      }
      // else if (type === 'profile') {
      //   res = await getProfile(params);
      // } else if (type === 'truck') {
      //   res = await getTruck(params);
      // } else if (type === 'ginkgo_t01') {
      //   res = await getReceiptGinkgo(params);
      // } else if (type === 'ginkgo_ewht') {
      //   res = await getEWHTGinkgo(params);
      // }
      if (res) {
        const href = URL.createObjectURL(res.data);
        window.open(href, '_blank');
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoadingBackground />}
      <div className="ExportData">
        <Container>
          <SubMenu menus={subMenu_menus} breadcrumbs={subMenu_breadcrumbs} />
          <Box mx={'20px'}>
            <form className="form-updatestatus" autoComplete="off">
              <Grid
                container
                spacing={2}
                mt={'5px'}
                mx={0}
                pt={'10px'}
                px={'20px'}
                sx={{
                  backgroundColor: '#FFF',
                  borderRadius: 3,
                }}
              >
                <Grid
                  item
                  xs={3}
                  py={'20px'}
                  px={'20px'}
                  sx={{ backgroundColor: '#FFF', borderRadius: 3 }}
                >
                  <FormControl fullWidth>
                    <FieldSelect
                      className="w-100"
                      name={`กรองและจัดเรียงตาม`}
                      options={[
                        { value: 'created_date', name: 'วันที่สร้างรายการ' },
                      ]}
                      value={`created_date`}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FieldDateRangePicker
                      sx={{ mibWidth: '300px' }}
                      className="w-100"
                      name={`วันเริ่มต้น - วันสิ้นสุด`}
                      value={{
                        start_date: dateFrom,
                        end_date: dateTo,
                      }}
                      onChange={(e) => handleOnChangeDate(e, 'date')}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Table>
                    <TableRow>
                      <TableCell className="text-center">
                        ข้อมูลผู้ใช้งาน
                      </TableCell>
                      <TableCell>
                        <div>
                          <CloudDownload
                            onClick={(e) => handleOnSubmit('user')}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    {/* <TableRow>
                      <TableCell className="text-center">
                        ข้อมูลโปรไฟล์
                      </TableCell>
                      <TableCell>
                        <div>
                          <CloudDownload
                            onClick={(e) => handleOnSubmit('profile')}
                          />
                        </div>
                      </TableCell>
                    </TableRow> */}
                    {/* <TableRow>
                      <TableCell className="text-center">ข้อมูลรถ</TableCell>
                      <TableCell>
                        <div>
                          <CloudDownload
                            onClick={(e) => handleOnSubmit('truck')}
                          />
                        </div>
                      </TableCell>
                    </TableRow> */}
                    <TableRow>
                      <TableCell className="text-center">
                        ข้อมูลโครงการ
                      </TableCell>
                      <TableCell>
                        <div>
                          <CloudDownload
                            onClick={(e) => handleOnSubmit('project')}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="text-center">ข้อมูลงาน</TableCell>
                      <TableCell>
                        <div>
                          <CloudDownload
                            onClick={(e) => handleOnSubmit('work')}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    {/* <TableRow>
                      <TableCell className="text-center">
                        ข้อมูลใบเสร็จ
                      </TableCell>
                      <TableCell>
                        <div>
                          <CloudDownload
                            onClick={(e) => handleOnSubmit('ginkgo_t01')}
                          />
                          <span
                            style={{
                              marginLeft: '10px',
                            }}
                          >
                            เรียงตามวันที่ชำระเงิน
                          </span>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="text-center">
                        ข้อมูลหนังสือรับรองการหัก ณ ที่จ่าย
                      </TableCell>
                      <TableCell>
                        <div>
                          <CloudDownload
                            onClick={(e) => handleOnSubmit('ginkgo_ewht')}
                          />
                          <span style={{ marginLeft: '10px' }}>
                            เรียงตามวันที่แจ้งโอน
                          </span>
                        </div>
                      </TableCell>
                    </TableRow> */}
                  </Table>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default ExportData;
