import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Autocomplete, TextField } from "@mui/material";
import { IconButton, FormHelperText } from "@mui/material";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import React, { useRef, useState, useEffect } from "react";
import { map } from "../map/LongdoMap";
import "./input.css";
import axios from "axios";
import HelperMap from "utils/helperMap";
import Swal from "sweetalert2";
import helper from "utils/helper";
export default function InputLongdoAutoComplete(props) {
  const {
    value,
    label,
    error,
    onChange = () => {},
    closeFav = false,
    renderFrontIcon = () => {},
    descriptionUnderField,
  } = props;
  const ref = useRef(null);
  const [placeList, setPlaceList] = useState([]);

  const [isFocused, setIsFocused] = useState(false);
  const [isLoc, setIsLoc] = useState(false);
  useEffect(() => {
    if (value?.search?.length > 0) {
      let isLatLong = value?.search?.split(",");
      if (isLatLong && isLatLong.length === 2 && isLatLong[1] !== "") {
        if (/.[0-9]/.test(isLatLong[1])) {
          setPlaceList([]);
          setIsLoc(true);
          getPlaceLocation({
            lat: isLatLong[0],
            lon: isLatLong[1],
          });
        } else {
          setIsLoc(false);
          searchPlace();
        }
      } else {
        setIsLoc(false);
        searchPlace();
      }
    }
  }, [value]);
  const searchPlace = async () => {
    let list = await HelperMap.getSuggest(value?.search);
    setPlaceList(list);
  };
  const place_detail = async (ele) => {
    let detail = await HelperMap.getPlace(ele.w);
    console.log("detail", detail);
    let place = await HelperMap.getPlaceFormLatLong(detail);
    if (
      place.type !== "water" &&
      place.province &&
      place.province !== "" &&
      place.district &&
      place.district !== "" &&
      place.subdistrict &&
      place.subdistrict !== "" &&
      place.postcode &&
      place.postcode !== ""
    ) {
      return {
        address: ele.w ?? "",
        province: place.province,
        district: place.district,
        subdistrict: place.subdistrict ?? "",
        postal_code: place.postcode,
        lat: detail.lat,
        long: detail.lon,
      };
    } else {
      Swal.fire({
        html: `ขออภัยจุดที่ท่านเลือกไม่มีที่อยู่`,
        type: "info",
      });
      return {
        address: value?.search,
        province: "",
        district: "",
        subdistrict: "",
        postal_code: "",
        lat: "",
        long: "",
      };
    }
  };
  const getPlaceLocation = async (loc) => {
    let place = await HelperMap.getPlaceFormLatLong(loc);
    console.log("place", place);
    if (
      place.province &&
      place.province !== "" &&
      place.district &&
      place.district !== "" &&
      place.subdistrict &&
      place.subdistrict !== "" &&
      place.postcode &&
      place.postcode !== ""
    ) {
      let list = [
        {
          ...place,
          lat: loc.lat,
          long: loc.lon,
          w:
            (place.road ?? "") +
            " " +
            place.subdistrict +
            " " +
            place.district +
            " " +
            place.province +
            " " +
            place.postcode,
          address:
            (place.road ?? "") +
            " " +
            place.subdistrict +
            " " +
            place.district +
            " " +
            place.province +
            " " +
            place.postcode,
        },
      ];
      console.log("list", list);
      setPlaceList(list);
    }
    // return
  };
  return (
    <div className={"input-google-complete"}>
      {/* <div id="form_div">Loading...</div> */}
      <FormControl variant="standard" fullWidth margin="none">
        <TextField
          label={label}
          value={value?.search ?? ""}
          error={error}
          onChange={async (val) => {
            onChange({ ...value, search: val.target.value });
          }}
          ref={ref}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setTimeout(() => {
              setIsFocused(false);
            }, 150);
          }}
          id={"location-search"}
          variant="standard"
        />
        <div style={{ position: "absolute", top: 25 }}>
          {helper.IsEmptyFunction(renderFrontIcon) ? (
            <SearchOutlinedIcon sx={{ fontSize: 30, color: "#8ca3ba" }} />
          ) : (
            renderFrontIcon()
          )}
        </div>
        {closeFav ? (
          ""
        ) : (
          <div
            style={{
              position: "absolute",
              top: 25,
              right: 0,
              backgroundColor: "white",
            }}
          >
            <IconButton
            // onClick={handleClickShowPassword}
            // onMouseDown={handleMouseDownPassword}
            >
              <BookmarkBorderOutlinedIcon sx={{ color: "#8ca3ba" }} />
            </IconButton>
          </div>
        )}
        <div
          style={{
            fontSize: 10,
            marginLeft: 20,
            position: "absolute",
            color: "#8ca3ba",
            bottom: -16,
            left: 20,
          }}
        >
          {/* <div>
            สามารถค้นหาจากชื่อสถานที่ พิกัดละติจูด,ลองจิจูด และ Google Plus Code
          </div> */}
        </div>

        {descriptionUnderField ? (
          <FormHelperText
            sx={{
              color: value ? "primary.main" : "grey.main",
              fontSize: "11px",
              padding: "0px 14px 0px",
            }}
          >
            {descriptionUnderField}
          </FormHelperText>
        ) : null}
      </FormControl>

      {placeList.length > 0 && isFocused ? (
        <div className="place-list pac-container">
          {placeList.map((ele, index) => {
            return (
              <div
                key={"google-lat-long" + index}
                className="pac-item"
                style={{ fontSize: 12, fontWeight: 600 }}
                onClick={async () => {
                  console.log("click", ele);
                  if (isLoc) {
                    onChange({ ...value, ...ele, search: ele.address });
                  } else {
                    let data = await place_detail(ele);
                    onChange({ ...value, ...data, search: data.address });
                  }
                }}
              >
                {ele.w}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
