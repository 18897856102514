import { Navigate } from 'react-router-dom';
import MainLayout from '../layout/MainLayout';
import helper from './helper';

const PrivateRoutes = () => {
  let auth = { token: helper.getCookie('access_token') };
  console.log('auth', auth);

  return auth.token ? <MainLayout /> : <Navigate to="/login" />;
  // return <MainLayout />;
};

export default PrivateRoutes;
