import instance from '../../axiosInstance';
import helper from 'utils/helper';

const getAllUser = async (param, body) => {
  try {
    return instance.post(`/api/user/tps/get-user`, body, {
      params: param,
    });
  } catch (error) {
    console.log('error', error);
  }
};

const getDrivingLicense = async () => {
  try {
    return instance.get(`/api/master/driving-license`);
  } catch (error) {
    console.log('error', error);
  }
};

const getUserById = async (id) => {
  try {
    return instance.get(`/api/user/tps/get-user-by-id/${id}`);
  } catch (error) {
    console.log(error);
  }
};

const getUserByCode = async (user_code) => {
  try {
    return instance.get(`/api/user/get-user-by-code/${user_code}`);
  } catch (error) {
    console.log(error);
  }
};

const getUserLoggedIn = async () => {
  return await instance.get(`/api/user/logged-in`);
};

const getUserPermission = async () => {
  const param = {
    sort_field: 'id',
    sort_order: 1,
    page: 1,
    per_page: 10,
  };

  try {
    return instance.get(`/api/master/user-permission`, {
      params: param,
    });
  } catch (error) {
    console.log('error', error);
  }
};

const checkDrivingLicense = async (param) => {
  try {
    return instance.get(`/api/master/truck-driving-license`, {
      params: param,
    });
  } catch (error) {
    console.log(error);
  }
};

const createUser = async (data) => {
  try {
    return instance.post(`api/user/user/create-user`, data, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  } catch (error) {
    console.log(error);
  }
};

const checkUniqueFields = async (data) => {
  try {
    return instance.post(`/api/user/check-unique-fields-user`, data, {
      headers: {
        'content-type': 'application/json',
      },
    });
  } catch (error) {
    console.log(error);
  }
};

const editUser = async (id, data) => {
  try {
    return instance.patch(`/api/user/user/edit-user-by-id/${id}`, data, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  } catch (error) {
    console.log('error', error);
  }
};

export {
  getAllUser,
  getDrivingLicense,
  getUserById,
  getUserByCode,
  getUserLoggedIn,
  getUserPermission,
  checkDrivingLicense,
  createUser,
  checkUniqueFields,
  editUser,
};
