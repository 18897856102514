import { Box, Container, Grid, Paper, TextField } from '@mui/material';
import SubMenu from 'components/layout/SubMenu.js';
import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import AreaChart from 'components/chart/AreaChart';
import StackedAreaChart from 'components/chart/StackedAreaChart';
import PieChart from 'components/chart/PieChart';
import StackedBarChart from 'components/chart/StackedBarChart';
import ProgressChart from 'components/chart/ProgressChart';
import FieldSelect from 'components/field/FieldSelect';
import moment from 'moment';
import FieldDateRangePicker from 'components/field/FieldDateRangePicker';
import FieldSearch from 'components/field/FieldSearch';
import SearchButton from 'components/button/SearchButton';
import ResetButton from 'components/button/ResetButton';
import Loading from 'components/loading/Loading';
import {
  getDashbaordIcon,
  getDashbaordWork,
} from 'utils/api/dashboard/dashbaord';
import { subMenuDasdboard } from 'utils/submenuoptions';
import { getAllProjectTPS, getAllShipper } from 'utils/api/masterData/service';
import FieldAutoComplete from 'components/field/FieldAutoComplete';
import * as currency from 'currency.js';

const DashbordWork = () => {
  const [dashboard1, setDashboard1] = useState(null);
  const [dashboard2, setDashboard2] = useState(null);
  const [dashboard3, setDashboard3] = useState(null);
  const [dashboard4, setDashboard4] = useState(null);
  const [dashboard5, setDashboard5] = useState(null);
  const [dashboard6, setDashboard6] = useState(null);
  const [dashboard7, setDashboard7] = useState(null);
  const [dashboard8, setDashboard8] = useState(null);
  const [dashboard9, setDashboard9] = useState(null);
  const [dashboard10, setDashboard10] = useState(null);
  const [dashboard11, setDashboard11] = useState(null);
  const [dashboard12, setDashboard12] = useState(null);
  const [dashboard13, setDashboard13] = useState(null);
  const [dashboard14, setDashboard14] = useState(null);
  const [dashboard15, setDashboard15] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(6, 'days').format('YYYY-MM-DD 00:00:00'),
  );
  const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD 23:59:59'));
  const [groupBy, setGroupBy] = useState(4);
  const [filterBy, setFilterBy] = useState('projectID');
  const [filter, setFilter] = useState(null);

  const [subMenu_menus, setSubMenuMenus] = useState([...subMenuDasdboard]);
  const [subMenu_breadcrumbs, setSubMenuBreadcrumbs] = useState([]);

  const [acProject, setAcProject] = useState([]);
  const [acShipper, setAcShipper] = useState([]);

  const monthToNumber = (month) => {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return monthNames.indexOf(month);
  };

  const getWeekNumber = (date) => {
    const d = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    );
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    const weekNumber = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return weekNumber < 10 ? '0' + weekNumber : weekNumber.toString();
  };

  const generateDates = (dateFrom, dateTo, groupBy) => {
    const fromDate = new Date(dateFrom);
    const toDate = new Date(dateTo);
    const generatedDates = [];

    if (groupBy === 1) {
      for (
        let year = fromDate.getFullYear();
        year <= toDate.getFullYear();
        year++
      ) {
        generatedDates.push(year.toString());
      }
    } else if (groupBy === 2) {
      const startYear = fromDate.getFullYear();
      const endYear = toDate.getFullYear();
      const startMonth = fromDate.getMonth();
      const endMonth = toDate.getMonth();

      for (let year = startYear; year <= endYear; year++) {
        const monthStart = year === startYear ? startMonth : 0;
        const monthEnd = year === endYear ? endMonth : 11;

        for (let month = monthStart; month <= monthEnd; month++) {
          const date = new Date(year, month, 1);
          generatedDates.push(formatDate2(date));
        }
      }
    } else if (groupBy === 3) {
      const currentDate = new Date(dateFrom);
      while (currentDate <= toDate) {
        const weekNumber = getWeekNumber(currentDate);
        const year = currentDate.getFullYear();
        generatedDates.push(`w${weekNumber}/${year}`);
        currentDate.setDate(currentDate.getDate() + 7);
      }
    } else if (groupBy === 4) {
      const currentDate = new Date(dateFrom);
      while (currentDate <= toDate) {
        generatedDates.push(formatDate4(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }
    return generatedDates;
  };

  const formatDate1 = (dateString) => {
    return moment(dateString).format('YYYY');
  };

  const formatDate2 = (dateString) => {
    return moment(dateString).format('MMM YYYY');
  };
  const formatDate3 = (dateString) => {
    return dateString;
  };

  const formatDate4 = (dateString) => {
    return moment(dateString).format('DD MMM YYYY');
  };

  useEffect(() => {
    setActiveSubMenu(0);
    setSubMenuBreadcrumbs([
      {
        label: 'แผนภูมิและกราฟ',
        path: null,
      },
      {
        label: 'แผนภูมิและกราฟผู้ใช้งาน',
        path: null,
      },
    ]);
    fetchDashboard();
    getAutoCompleteProject();
    // getAutoCompleteShipper();
  }, []);

  const setActiveSubMenu = (index) => {
    const updatedMenus = subMenu_menus.map((menu, idx) => {
      return { ...menu, active: idx === index };
    });
    setSubMenuMenus(updatedMenus);
  };

  async function getAutoCompleteProject() {
    try {
      const res = await getAllProjectTPS();
      if (res) {
        setAcProject(res.data.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAutoCompleteShipper() {
    try {
      const res = await getAllShipper();
      if (res) {
        setAcShipper(res.data.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchDashboard() {
    setIsReady(false);
    const requestData = {
      dateFrom: moment(dateFrom).toISOString(),
      dateTo: moment(dateTo).toISOString(),
      groupBy: groupBy,
    };
    if (filterBy !== '' && filter !== null) {
      requestData[filterBy] = filter.value;
    }
    try {
      const response = await getDashbaordWork(requestData);
      if (response.status === 200) {
        const res = response.data.data;
        const rawData = res.map((item) => {
          if (groupBy === 1) {
            item.groupdate = formatDate1(item.groupdate);
          } else if (groupBy === 2) {
            item.groupdate = formatDate2(item.groupdate);
          } else if (groupBy === 3) {
            item.groupdate = formatDate3(item.groupdate);
          } else if (groupBy === 4) {
            item.groupdate = formatDate4(item.groupdate);
          }
          return item;
        });
        const generatedDates = generateDates(dateFrom, dateTo, groupBy);
        generatedDates.forEach((generatedDate) => {
          if (!rawData.some((item) => item.groupdate === generatedDate)) {
            rawData.push({
              groupdate: generatedDate,
              shipper_individual: '0',
              shipper_corporate: '0',
              shipper_payment_type_credit: '0',
              shipper_payment_type_debit: '0',
              work_carrier: {},
              work_created_by_admin: '0',
              work_created_by_user: '0',
              work_matching: '0',
              work_product_type: {},
              work_service: {},
              work_shipper: {},
              work_truck_type: {},
            });
          }
        });

        if (groupBy === 2) {
          rawData.sort((a, b) => {
            const [aMonth, aYear] = a.groupdate.split(' ');
            const [bMonth, bYear] = b.groupdate.split(' ');
            const aMonthNumber = monthToNumber(aMonth);
            const bMonthNumber = monthToNumber(bMonth);

            if (aYear === bYear) {
              return aMonthNumber - bMonthNumber;
            } else {
              return aYear.localeCompare(bYear);
            }
          });
        } else if (groupBy === 3) {
          rawData.sort((a, b) => {
            const aWeekYear = a.groupdate.split(' ')[0];
            const bWeekYear = b.groupdate.split(' ')[0];
            const [aWeek, aYear] = aWeekYear.split('/');
            const [bWeek, bYear] = bWeekYear.split('/');
            const aWeekNumber = parseInt(aWeek.split('w')[1]);
            const bWeekNumber = parseInt(bWeek.split('w')[1]);
            if (aYear === bYear) {
              return aWeekNumber - bWeekNumber;
            } else {
              return aYear.localeCompare(bYear);
            }
          });
        } else if (groupBy === 4) {
          rawData.sort((a, b) => {
            const dateA = new Date(a.groupdate);
            const dateB = new Date(b.groupdate);
            return dateA - dateB;
          });
        } else {
          rawData.sort((a, b) => a.groupdate.localeCompare(b.groupdate));
        }

        const data = rawData.map((value, index) => {
          const target = 10;
          const targetDiff = (100 / target) * value.work_matching - 100;
          const prev = index === 0 ? 'NaN' : rawData[index - 1].work_matching;
          const prevDiff = (100 / prev) * value.work_matching - 100;
          const targetDiffText =
            targetDiff >= 0
              ? `+${targetDiff.toFixed(2)}%`
              : `${targetDiff.toFixed(2)}%`;
          const prevDiffText =
            prevDiff >= 0
              ? `+${prevDiff.toFixed(2)}%`
              : `${prevDiff.toFixed(2)}%`;
          return {
            name: value.groupdate,
            value: value.work_matching,
            data: {
              ปริมาณงานที่จับคู่แล้ว: value.work_matching,
              เป้าหมาย: `${target}`,
              ส่วนต่างจากเป้าหมาย: targetDiffText,
              ปริมาณงานที่จับคู่แล้วก่อนหน้า: `${prev}`,
              ส่วนต่างจากปริมาณงานที่จับคู่แล้วก่อนหน้า: prevDiffText,
            },
          };
        });

        const data2 = rawData.map((value, index) => {
          const sum = currency(value.work_created_by_user).add(
            value.work_created_by_admin,
          );
          const sum_format = currency(sum, {
            symbol: '',
            precision: 0,
          }).value;

          return {
            name: value.groupdate,
            value: [
              value.work_created_by_user,
              value.work_created_by_admin,
              sum_format,
            ],
            data: {
              บุคคลทั่วไป: value.work_created_by_user,
              นิติบุคคล: value.work_created_by_admin,
              รวม: sum_format,
            },
          };
        });

        const arrService = rawData.map((value, index) => {
          return Object.keys(value.work_service);
        });
        const uniqueService = [...new Set(arrService.flat())];
        const zeroValue = {};
        uniqueService.forEach((service) => {
          zeroValue[service] = 0;
        });

        const data3 = rawData.map((value, index) => {
          const resValue = [];
          const resData = {};
          for (const key of uniqueService) {
            resValue.push(value.work_service[key] || 0);
            resData[key] = value.work_service[key] || 0;
          }
          return {
            name: value.groupdate,
            value: resValue,
            data: resData,
          };
        });

        const data4 = rawData.reduce(
          (prev, curr) => {
            return [
              prev[0] + parseFloat(curr.work_created_by_user),
              prev[1] + parseFloat(curr.work_created_by_admin),
            ];
          },
          [0, 0],
        );

        const data5 = rawData.reduce(
          (prev, curr) => {
            for (const key in prev) {
              prev[key] += curr.work_service[key] || 0;
            }
            return prev;
          },
          { ...zeroValue },
        );

        function getRandomColor() {
          const letters = '0123456789ABCDEF';
          let color = '';
          for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
          }
          return color;
        }

        const newData5 = Object.entries(data5).map(([name, value]) => {
          const total = Object.values(data5).reduce(
            (acc, curr) => acc + curr,
            0,
          );
          const percent = ((value / total) * 100).toFixed(2);
          const color = getRandomColor();

          return {
            name,
            value,
            percent: parseFloat(percent),
            color,
          };
        });

        const data6 = rawData.map((value, index) => {
          return {
            name: value.groupdate,
            value: [
              currency(value.shipper_payment_type_debit).value,
              currency(value.shipper_payment_type_credit).value,
              currency(value.shipper_payment_type_debit).add(
                value.shipper_payment_type_credit,
              ).value,
            ],
            data: {
              เงินสด: currency(value.shipper_payment_type_debit, {
                symbol: '',
                precision: 0,
              }).format(),
              เครดิต: currency(value.shipper_payment_type_credit, {
                symbol: '',
                precision: 0,
              }).format(),
              รวม: currency(value.shipper_payment_type_credit, {
                symbol: '',
                precision: 0,
              })
                .add(value.shipper_payment_type_credit)
                .format(),
            },
          };
        });

        const data7 = rawData.map((value, index) => {
          return {
            name: value.groupdate,
            value: [
              currency(value.shipper_individual).value,
              currency(value.shipper_corporate).value,
              currency(value.shipper_individual).add(value.shipper_corporate)
                .value,
            ],
            data: {
              บุคคลทั่วไป: currency(value.shipper_individual, {
                symbol: '',
                precision: 0,
              }).format(),
              นิติบุคคล: currency(value.shipper_corporate, {
                symbol: '',
                precision: 0,
              }).format(),
              รวม: currency(value.shipper_individual, {
                symbol: '',
                precision: 0,
              })
                .add(value.shipper_corporate)
                .format(),
            },
          };
        });

        const data8 = rawData.reduce(
          (prev, curr) => {
            return [
              prev[0] + parseFloat(curr.shipper_payment_type_debit),
              prev[1] + parseFloat(curr.shipper_payment_type_credit),
            ];
          },
          [0, 0],
        );

        const data9 = rawData.reduce(
          (prev, curr) => {
            return [
              prev[0] + parseFloat(curr.shipper_individual),
              prev[1] + parseFloat(curr.shipper_corporate),
            ];
          },
          [0, 0],
        );

        const data10 = rawData.map((value, index) => {
          const sortedItems = Object.entries(value.work_product_type)
            .sort((a, b) => b[1] - a[1])
            .map(([key, val]) => ({ [val.name]: val.value }));

          const top10Items = sortedItems.slice(0, 10);

          const otherTotal = sortedItems.slice(10).reduce((acc, curr) => {
            const value = Object.values(curr)[0];
            return acc + value;
          }, 0);

          if (otherTotal !== 0) {
            top10Items.push({ อื่นๆ: otherTotal });
          }

          const top10ItemsJSON = {};
          top10Items.forEach((obj) => {
            for (const key in obj) {
              top10ItemsJSON[key] = obj[key];
            }
          });

          return {
            name: value.groupdate,
            value: top10ItemsJSON,
          };
        });

        const data11 = rawData.map((value, index) => {
          const sortedItems = Object.entries(value.work_truck_type)
            .sort((a, b) => b[1] - a[1])
            .map(([key, val]) => ({ [val.name]: val.value }));

          const top10Items = sortedItems.slice(0, 10);

          const otherTotal = sortedItems.slice(10).reduce((acc, curr) => {
            const value = Object.values(curr)[0];
            return acc + value;
          }, 0);

          if (otherTotal !== 0) {
            top10Items.push({ อื่นๆ: otherTotal });
          }

          const top10ItemsJSON = {};
          top10Items.forEach((obj) => {
            for (const key in obj) {
              top10ItemsJSON[key] = obj[key];
            }
          });
          return {
            name: value.groupdate,
            value: top10ItemsJSON,
          };
        });

        const data12 = rawData.reduce((prev, curr) => {
          for (const key in curr.work_product_type) {
            if (!prev[key]) {
              prev[key] = {
                name: curr.work_product_type[key].name,
                value: 0,
              };
            }
            prev[key].value += curr.work_product_type[key].value || 0;
          }
          return prev;
        }, {});

        const newData12 = Object.entries(data12).map(([id, value]) => ({
          id: id,
          name: value.name,
          value: value.value,
        }));
        const totalValue12 = newData12.reduce(
          (acc, item) => currency(acc).add(item.value).value,
          0,
        );
        newData12.sort((a, b) => b.value - a.value);
        const top10Items12 = newData12.slice(0, 10);

        const data13 = rawData.reduce((prev, curr) => {
          for (const key in curr.work_truck_type) {
            if (!prev[key]) {
              prev[key] = {
                name: curr.work_truck_type[key].name,
                value: 0,
              };
            }
            prev[key].value += curr.work_truck_type[key].value || 0;
          }
          return prev;
        }, {});
        const newData13 = Object.entries(data13).map(([id, value]) => ({
          id: id,
          name: value.name,
          value: value.value,
        }));
        const totalValue13 = newData13.reduce(
          (acc, item) => currency(acc).add(item.value).value,
          0,
        );
        newData13.sort((a, b) => b.value - a.value);
        const top10Items13 = newData13.slice(0, 10);

        const data14 = rawData.reduce((prev, curr) => {
          for (const key in curr.work_shipper) {
            if (!prev[key]) {
              prev[key] = {
                name: curr.work_shipper[key].name,
                value: 0,
              };
            }
            prev[key].value += curr.work_shipper[key].value || 0;
          }
          return prev;
        }, {});
        const newData14 = Object.entries(data14).map(([id, value]) => ({
          id: id,
          name: value.name,
          value: value.value,
        }));
        const totalValue14 = newData14.reduce(
          (acc, item) => currency(acc).add(item.value).value,
          0,
        );
        newData14.sort((a, b) => b.value - a.value);
        const top10Items14 = newData14.slice(0, 10);

        const data15 = rawData.reduce((prev, curr) => {
          for (const key in curr.work_carrier) {
            if (!prev[key]) {
              prev[key] = {
                name: curr.work_carrier[key].name,
                value: 0,
              };
            }
            prev[key].value += curr.work_carrier[key].value || 0;
          }
          return prev;
        }, {});
        const newData15 = Object.entries(data15).map(([id, value]) => ({
          id: id,
          name: value.name,
          value: value.value,
        }));
        const totalValue15 = newData15.reduce(
          (acc, item) => currency(acc).add(item.value).value,
          0,
        );
        newData15.sort((a, b) => b.value - a.value);
        const top10Items15 = newData15.slice(0, 10);

        let findImg = {
          product_type: [],
          truck_type: [],
          shipper: [],
          carrier: [],
        };
        findImg.product_type = top10Items12.map((item) => item.id);
        findImg.truck_type = top10Items13.map((item) => item.id);
        findImg.shipper = top10Items14.map((item) => item.id);
        findImg.carrier = top10Items15.map((item) => item.id);

        const icon = await getDashbaordIcon(findImg);
        if (icon) {
          const { product_type, carrier, shipper, truck_type, url } =
            icon.data.data;

          newData12.forEach((item) => {
            const percent = (item.value / totalValue12) * 100;
            item['percent'] = parseFloat(percent.toFixed(2));
            const icon_part =
              product_type.find((val) => val.name === item.name)?.image ?? null;
            if (icon_part === null) {
              item['icon'] = null;
            } else {
              item['icon'] = `http://${url}${icon_part}`;
            }
          });

          newData13.forEach((item) => {
            const percent = (item.value / totalValue13) * 100;
            item['percent'] = parseFloat(percent.toFixed(2));
            const icon_part =
              truck_type.find((val) => val.name === item.name)?.image ?? null;
            if (icon_part === null) {
              item['icon'] = null;
            } else {
              item['icon'] = `http://${url}${icon_part}`;
            }
          });

          newData14.forEach((item) => {
            const percent = (item.value / totalValue14) * 100;
            item['percent'] = parseFloat(percent.toFixed(2));
            const icon_part =
              shipper.find((val) => val.name === item.name)?.image ?? null;
            if (icon_part === null) {
              item['icon'] = null;
            } else {
              // item["icon"] = `http://${url}${icon_part}`;
              item['icon'] = `http://${icon_part}`;
            }
          });

          newData15.forEach((item) => {
            const percent = (item.value / totalValue15) * 100;
            item['percent'] = parseFloat(percent.toFixed(2));
            const icon_part =
              carrier.find((val) => val.name === item.name)?.image ?? null;
            if (icon_part === null) {
              item['icon'] = null;
            } else {
              // item["icon"] = `http://${url}${icon_part}`;
              item['icon'] = `http://${icon_part}`;
            }
          });
        }

        setDashboard1(data);
        setDashboard2(data2);
        setDashboard3(data3);
        setDashboard4([
          {
            name: 'ผู้ใช้งาน',
            value: data4[0],
            percent: (data4[0] / (data4[0] + data4[1])) * 100,
            color: 'FFC675',
          },
          {
            name: 'ผู้ดูแลระบบ',
            value: data4[1],
            percent: (data4[1] / (data4[0] + data4[1])) * 100,
            color: '8C8DFF',
          },
        ]);
        setDashboard5(newData5);
        setDashboard6(data6);
        setDashboard7(data7);
        setDashboard8([
          {
            name: 'บุคคลทั่วไป',
            value: data8[0],
            percent: (data8[0] / (data8[0] + data8[1])) * 100,
            color: 'FFC675',
          },
          {
            name: 'นิติบุคคล',
            value: data8[1],
            percent: (data8[1] / (data8[0] + data8[1])) * 100,
            color: '8C8DFF',
          },
        ]);
        setDashboard9([
          {
            name: 'บุคคลทั่วไป',
            value: data9[0],
            percent: (data9[0] / (data9[0] + data9[1])) * 100,
            color: 'FFC675',
          },
          {
            name: 'นิติบุคคล',
            value: data9[1],
            percent: (data9[1] / (data9[0] + data9[1])) * 100,
            color: '8C8DFF',
          },
        ]);
        setDashboard10(data10);
        setDashboard11(data11);
        setDashboard12(newData12);
        setDashboard13(newData13);
        setDashboard14(newData14);
        setDashboard15(newData15);
      }
      setIsReady(true);
    } catch (error) {
      setIsReady(true);
      console.error('Error fetching Dashboard:', error);
    }
  }

  const handleGroupby = (val) => {
    setGroupBy(val);
  };

  const handleDate = (val) => {
    setDateFrom(val[0]);
    setDateTo(val[1]);
  };

  const handleFilterBy = (val) => {
    setFilterBy(val);
  };

  const handleFilter = (val) => {
    setFilter(val);
  };

  const handleSearch = () => {
    fetchDashboard();
  };

  const handleReset = () => {
    setDateFrom(moment().subtract(6, 'days').format('YYYY-MM-DD'));
    setDateTo(moment().format('YYYY-MM-DD'));
    setGroupBy(4);
    setFilterBy('');
    setFilter('');
  };

  return (
    <Box className="DashbordWork">
      <Container>
        <SubMenu menus={subMenu_menus} breadcrumbs={subMenu_breadcrumbs} />
        <Box
          className="box"
          sx={{
            height: '100px',
            width: '100%',
            padding: '26px 37px',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <FieldSelect
                className="w-100"
                name={`กรองตาม`}
                options={[
                  { value: 1, name: 'ปี' },
                  { value: 2, name: 'เดือน' },
                  { value: 3, name: 'สัปดาห์' },
                  { value: 4, name: 'วัน' },
                ]}
                value={groupBy}
                onChange={handleGroupby}
              />
            </Grid>
            <Grid item xs={2}>
              <FieldDateRangePicker
                className="w-100"
                name={`วันเริ่มต้น - วันสิ้นสุด`}
                value={{
                  start_date: dateFrom,
                  end_date: dateTo,
                }}
                onChange={handleDate}
              />
            </Grid>
            <Grid item xs={2}>
              <FieldSelect
                className="w-100"
                name={`ค้นหาจาก`}
                options={[
                  { value: 'projectID', name: 'ชื่อโครงการ' },
                  // { value: 'profileID', name: 'ชื่อโปรไฟล์ลูกค้า' },
                ]}
                value={filterBy}
                onChange={handleFilterBy}
              />
            </Grid>
            <Grid item xs={2}>
              {/* <FieldSearch
              className="w-100"
              value={filter}
              onChange={handleFilter}
            /> */}
              <FieldAutoComplete
                className="w-100"
                value={filter}
                onChange={handleFilter}
                options={filterBy === 'projectID' ? acProject : acShipper}
              />
            </Grid>
            <Grid item xs={2}>
              <SearchButton onClick={handleSearch} />
              <ResetButton
                styles={{ marginLeft: '5px' }}
                onClick={handleReset}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
      {isReady === false ? (
        <Loading />
      ) : (
        <div className="div-chart">
          <Box
            className="box-inline box-white"
            sx={{
              height: '625px',
              minWidth: '1150px',
              padding: '18px 25px',
            }}
          >
            <AreaChart
              title="ปริมาณงานที่จับคู่แล้ว"
              data={dashboard1}
              color="2499EF"
            />
          </Box>

          <Box className="box-inline" px={{ marginLeft: '30px' }}>
            <Box
              className="box-white"
              px={{ width: '800px', height: '300px', padding: '18px 25px' }}
            >
              <StackedAreaChart
                title="ปริมาณงานที่จับคู่แล้วแยกตามประเภทผู้ประกาศงาน"
                data={dashboard2}
                color={['FFC675', 'FF316F', '2499EF']}
              />
            </Box>
            <Box
              className="box-white"
              px={{
                width: '800px',
                height: '300px',
                padding: '18px 25px',
                marginTop: '25px',
              }}
            >
              <StackedAreaChart
                title="ปริมาณงานที่จับคู่แล้วแยกตามประเภทบริการ"
                data={dashboard3}
                color={['FFC675', '27CE88', '2499EF']}
              />
            </Box>
          </Box>

          <Box className="box-inline" px={{ marginLeft: '30px' }}>
            <Box
              className="box-white"
              px={{ width: '325px', height: '300px', padding: '18px 25px' }}
            >
              <PieChart
                data={dashboard4}
                title="สัดส่วนปริมาณงานที่จับคู่แล้ว"
                title2="แยกตามประเภทโปรไฟล์"
                unit="งาน"
              />
            </Box>
            <Box
              className="box-white"
              px={{
                width: '325px',
                height: '300px',
                padding: '18px 25px',
                marginTop: '25px',
              }}
            >
              <PieChart
                data={dashboard5}
                title="สัดส่วนปริมาณงานที่จับคู่แล้ว"
                title2="แยกตามประเภทบริการ"
                unit="งาน"
              />
            </Box>
          </Box>

          <Box className="box-inline" px={{ marginLeft: '30px' }}>
            <Box
              className="box-white"
              px={{ width: '800px', height: '300px', padding: '18px 25px' }}
            >
              <StackedAreaChart
                title="ปริมาณงานที่จับคู่แล้วแยกตามประเภทโปรไฟล์"
                data={dashboard7}
                color={['FFC675', '8C8DFF', '2499EF']}
              />
            </Box>
            <Box
              className="box-white"
              px={{
                width: '800px',
                height: '300px',
                padding: '18px 25px',
                marginTop: '25px',
              }}
            >
              <StackedAreaChart
                title="ปริมาณงานที่จับคู่แล้วแยกตามประเภทการชำระเงิน"
                data={dashboard6}
                color={['FFC675', '8CA3BA', '2499EF']}
              />
            </Box>
          </Box>

          <Box className="box-inline" px={{ marginLeft: '30px' }}>
            <Box
              className="box-white"
              px={{ width: '325px', height: '300px', padding: '18px 25px' }}
            >
              <PieChart
                data={dashboard9}
                title="สัดส่วนปริมาณงานที่จับคู่แล้ว"
                title2="แยกตามประเภทโปรไฟล์"
                unit="งาน"
              />
            </Box>
            <Box
              className="box-white"
              px={{
                width: '325px',
                height: '300px',
                padding: '18px 25px',
                marginTop: '25px',
              }}
            >
              <PieChart
                data={dashboard8}
                title="สัดส่วนปริมาณงานที่จับคู่แล้ว"
                title2="แยกตามประเภทการชำระเงิน"
                unit="งาน"
              />
            </Box>
          </Box>

          <Box className="box-inline" px={{ marginLeft: '30px' }}>
            <Box
              className="box-white"
              px={{ width: '800px', height: '300px', padding: '18px 25px' }}
            >
              <StackedBarChart
                title="ปริมาณงานที่จับคู่แล้วแยกตามประเภทสินค้า"
                data={dashboard10}
              />
            </Box>
            <Box
              className="box-white"
              px={{
                width: '800px',
                height: '300px',
                padding: '18px 25px',
                marginTop: '25px',
              }}
            >
              <StackedBarChart
                title="ปริมาณงานที่จับคู่แล้วแยกตามประเภทรถ"
                data={dashboard11}
              />
            </Box>
          </Box>

          <Box className="box-inline" px={{ marginLeft: '30px' }}>
            <Box
              className="box-white"
              px={{ width: '500px', height: '300px', padding: '18px 25px' }}
            >
              <ProgressChart
                title="สัดส่วนปริมาณงานที่จับคู่แล้วแยกตามประเภทสินค้า"
                unit="งาน"
                data={dashboard12}
                color="rgba(39, 206, 136)"
              />
            </Box>
            <Box
              className="box-white"
              px={{
                width: '500px',
                height: '300px',
                padding: '18px 25px',
                marginTop: '25px',
              }}
            >
              <ProgressChart
                title="ปริมาณงานที่จับคู่แล้วแยกตามประเภทรถ"
                unit="งาน"
                data={dashboard13}
                color="rgba(140, 141, 255)"
              />
            </Box>
          </Box>

          <Box className="box-inline" px={{ marginLeft: '30px' }}>
            <Box
              className="box-white"
              px={{ width: '500px', height: '300px', padding: '18px 25px' }}
            >
              <ProgressChart
                title="ปริมาณงานที่จับคู่แล้วแยกตามโปรไฟล์ลูกค้าสูงสุด 10 อันดับแรก"
                unit="งาน"
                data={dashboard14}
                color="rgba(255, 49, 111)"
              />
            </Box>
            <Box
              className="box-white"
              px={{
                width: '500px',
                height: '300px',
                padding: '18px 25px',
                marginTop: '25px',
              }}
            >
              <ProgressChart
                title="ปริมาณงานที่จับคู่แล้วแยกตามโปรไฟล์ผู้ขนส่งสูงสุด 10 อันดับแรก"
                unit="งาน"
                data={dashboard15}
                color="rgba(36, 153, 239)"
              />
            </Box>
          </Box>
        </div>
      )}
    </Box>
  );
};

export default DashbordWork;
