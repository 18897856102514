import React from 'react';
const Error = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '30px',
          borderRadius: '20px',
          boxShadow: `15px 16px 15px -3px rgba(0,0,0,0.1)`,
        }}
      >
        <div style={{ fontSize: '46px', fontWeight: '500' }}>404</div>
        <div style={{ fontSize: '40px', fontWeight: '500' }}>
          PAGE NOT FOUND
        </div>
      </div>
    </div>
  );
};

export default Error;
