export const subMenuDasdboard = [
  {
    label: 'แผนภูมิและกราฟปริมาณงาน',
    path: '/dashboard/work',
  },
  {
    label: 'แผนภูมิและกราฟ KPI รับงาน',
    path: '/dashboard/kpi',
  },
  {
    label: 'รายงาน',
    path: '/dashboard/export-data',
  },
];

export const subMenuFinance = [
  {
    label: 'รอแจ้งหนี้',
    path: '/finance/shipper/unpaid',
    header: 'ข้อมูลการเงินลูกค้า',
  },
  {
    label: 'แจ้งหนี้แล้ว',
    path: '/finance/shipper/invoice',
  },
  {
    label: 'ประวัติการแจ้งหนี้',
    path: '/finance/shipper/invoice-group',
  },
  {
    label: 'วางบิลแล้ว',
    path: '/finance/shipper/billingnote',
  },
  {
    label: 'ประวัติการวางบิล',
    path: '/finance/shipper/billingnote-group',
  },
  {
    label: 'ชำระแล้ว',
    path: '/finance/shipper/paid',
  },
  {
    label: 'ประวัติการชำระ',
    path: '/finance/shipper/paid-group',
  },
  {
    label: 'รอตั้งเบิก',
    path: '/finance/carrier/unpaid',
    header: 'ข้อมูลการเงินผู้ขนส่ง',
  },
  {
    label: 'ตั้งเบิกแล้ว',
    path: '/finance/carrier/invoice',
  },
  {
    label: 'ประวัติการตั้งเบิก',
    path: '/finance/carrier/invoice-group',
  },
  {
    label: 'เตรียมแจ้งโอนแล้ว',
    path: '/finance/carrier/billingnote',
  },
  {
    label: 'ประวัติการเตรียมแจ้งโอน',
    path: '/finance/carrier/billingnote-group',
  },
  {
    label: 'แจ้งโอนแล้ว',
    path: '/finance/carrier/paid',
  },
  {
    label: 'ประวัติการแจ้งโอน',
    path: '/finance/carrier/paid-group',
  },
];

export const subMenuAdmin = [
  {
    label: 'จัดการผู้ดูแลระบบ',
    path: '/',
  },
];

export const subMenuAdminID = [
  {
    label: 'รายละเอียดผู้ดูและระบบ',
    path: '',
    active: true,
  },
];

export const subMenuMasterData = [
  {
    label: 'Term&Condition',
    path: '/master-data/term-and-condition',
  },
  {
    label: 'Privacy Policy',
    path: '/master-data/privacy-policy',
  },
  {
    label: 'พื้นที่ให้บริการ',
    path: '/master-data/service-area',
  },
  {
    label: 'เส้นทางจังหวัดที่ให้บริการ (FTL-Factor)',
    path: '/master-data/route-province',
  },
  {
    label: 'เส้นทางภูมิภาคที่ให้บริการ (STL-Size&Freight)',
    path: '/master-data/route-stl-standard-size',
  },
  {
    label: 'ประเภทรถ',
    path: '/master-data/truck-type',
  },
  {
    label: 'อัตรานํ้ามัน',
    path: '/master-data/fuel-rate',
  },
  {
    label: 'ประกันภัยสินค้ารายปี',
    path: '/master-data/annual-insurance',
  },
  {
    label: 'อุปกรณ์เสริมติดรถ',
    path: '/master-data/accessories',
  },
  {
    label: 'ประเภทสินค้า',
    path: '/master-data/product-type',
  },
  {
    label: 'อัพเดทเวอร์ชัน WeMoveDrive',
    path: '/master-data/app-version/wemovedrive-version',
  },
  {
    label: 'Third Party Key',
    path: '/master-data/third-party-key',
  },
];

export const subMenuUser = [
  {
    label: 'ข้อมูลผู้ใช้งาน',
    path: '/',
  },
  {
    label: 'ติดตามสถานะเอกสารผู้ใช้งานทั้งหมด',
    path: '/',
  },
];

export const subMenuTruck = [
  {
    label: 'ข้อมูลรถ',
    path: '/truck',
  },
  {
    label: 'ติดตามสถานะเอกสารทั้งหมด',
    path: '/',
  },
];

export const subMenuPostWork = [
  {
    label: 'ประกาศงานรายการเดียว',
    path: '/post-work',
  },
  {
    label: 'ประกาศงานแบบใช้ไฟล์นำเข้า',
    path: '/import-work',
  },
  {
    label: 'จัดเส้นทาง',
    path: '/route-optimize',
  },
];

export const subMenuProject = [
  {
    label: 'ข้อมูลโครงการ',
    path: '/project',
  },
];
