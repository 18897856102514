import instance from 'utils/axiosInstance';

export const getWork = async (params = {}) => {
  return await instance.get(`api/xlsx/tps/work`, {
    params: params,
    responseType: 'blob',
    timeout: 180000,
  });
};
export const getProject = async (params = {}) => {
  return await instance.get(`api/xlsx/tps/project`, {
    params: params,
    responseType: 'blob',
    timeout: 180000,
  });
};
export const getUser = async (params = {}) => {
  return await instance.get(`api/xlsx/tps/user`, {
    params: params,
    responseType: 'blob',
    timeout: 180000,
  });
};
