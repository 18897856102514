import React from "react";
import {
  TextField,
  useTheme,
  FormLabelClasses,
  FormHelperText,
} from "@mui/material";
import helper from "utils/helper";

const FieldInput = ({
  value = ``,
  onChange = () => {},
  onBlur = () => {},
  label = "",
  subLabel = "",
  error = false,
  helperText = "",
  readOnly = false,
  style = {},
  closePaddingBottom = false,
  className = ``,
  type = ``,
  descriptionUnderField = ``,
}) => {
  const theme = useTheme();
  return (
    <div
      style={{
        pointerEvents: readOnly ? "none" : "",
        paddingBottom:
          closePaddingBottom || descriptionUnderField !== ``
            ? 0
            : readOnly && !error
            ? "23px"
            : 0,
      }}
    >
      <TextField
        // id="standard-basic"
        className={className}
        type={type}
        label={
          label && subLabel ? (
            <section style={{ lineHeight: 1.2, fontSize: "16px" }}>
              {label}
              <br />
              <p style={{ fontSize: "10px", margin: 0 }}>{subLabel}</p>
            </section>
          ) : (
            label
          )
        }
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        variant="standard"
        sx={{
          bgcolor: readOnly ? "rgba(140, 163, 186, 0.20)" : "white",
          // width: "-webkit-fill-available",
          "& .MuiInputBase-input": {
            padding: "4px 14px 5px 14px",
          },
          "& .MuiFormLabel-root[data-shrink=true]": {
            padding: "0 14px",
            marginTop: "0",
          },
          "& .MuiFormLabel-root[data-shrink=false]": {
            padding: "0 14px",
            marginTop: subLabel ? "-10px" : "-5px",
          },
          "& .MuiFormHelperText-root": {
            padding: "0 14px",
          },
          paddingBottom:
            error || readOnly || closePaddingBottom || descriptionUnderField
              ? 0
              : "23px",
          ...style,
        }}
        error={error}
        // helperText={helperText}
        InputProps={{
          readOnly: readOnly,
        }}
      />
      {error && helperText ? (
        <FormHelperText sx={{ color: "error.main", padding: "0 14px" }}>
          {helperText}
        </FormHelperText>
      ) : descriptionUnderField ? (
        <FormHelperText
          sx={{
            color: value ? "primary.main" : "grey.main",
            fontSize: "11px",
            padding: "0 14px",
          }}
        >
          {descriptionUnderField}
        </FormHelperText>
      ) : (
        ""
      )}
      {/* {disabled ? <div style={{ paddingBottom: "25px" }}></div> : ""} */}
    </div>
  );
};

export default FieldInput;
