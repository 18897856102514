import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Logo from 'assets/images/WemoveLogo.png';
import CloseDrawer from 'assets/icons/closeDrawer.svg';
import {
  Person,
  HomeSharp,
  VerifiedUserSharp,
  PostAdd,
  DescriptionRounded,
  AdminPanelSettingsSharp,
  PinDropRounded,
  AccountBalanceRounded,
  AccountCircle,
} from '@mui/icons-material';
import { FaTruckFast } from 'react-icons/fa6';
import { BsFillBoxSeamFill, BsDatabaseFillGear } from 'react-icons/bs';
import { HiPresentationChartLine } from 'react-icons/hi';
import { useTheme, Typography } from '@mui/material';
import { AdminRole } from 'utils/enum';
import { useCookies } from 'react-cookie';
import API from 'utils/api/auth/auth';
import { useNavigate } from 'react-router-dom';
const api = API.create();

export default function DrawerContent(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [state, setState] = React.useState({
    left: false,
  });
  const [, , removeAccessToken] = useCookies(['access_token']);

  // const [role, setRole] = React.useState('');
  React.useEffect(() => {
    console.log('props', props);
  }, [props]);
  // React.useEffect(() => {
  //   if (props?.admin_info) {
  //     const admin_role = AdminRole?.find(
  //       (role) => props?.admin_info?.admin_role_type == role.value,
  //     );
  //     console.log('admin_role', admin_role);
  //     setRole(admin_role?.name);
  //   }
  // }, [props?.admin_info, AdminRole]);

  const handleLogout = async () => {
    await api
      .logout()
      .then((res) => {
        removeAccessToken('access_token');
        navigate('/login');
      })
      .catch((e) => console.log(e));
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 270 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <ListItemButton href="/" style={{}}>
            <ListItemIcon size="medium" style={{ padding: '5px' }}>
              <HomeSharp fontSize="large" sx={{ color: 'grey.main' }} />
            </ListItemIcon>
            <ListItemText sx={{ color: 'grey.main' }}>หน้าแรก</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton href="/user">
            <ListItemIcon size="medium" style={{ padding: '5px' }}>
              <Person fontSize="large" sx={{ color: 'grey.main' }} />
            </ListItemIcon>
            <ListItemText sx={{ color: 'grey.main' }}>
              ข้อมูลผู้ใช้งาน
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton href="/project">
            <ListItemIcon size="medium" style={{ padding: '5px' }}>
              <DescriptionRounded
                fontSize="large"
                sx={{ color: 'grey.main' }}
              />
            </ListItemIcon>
            <ListItemText sx={{ color: 'grey.main' }}>
              ข้อมูลโครงการ
            </ListItemText>
          </ListItemButton>
        </ListItem>
        {/* <ListItem>
          <ListItemButton href="/truck">
            <ListItemIcon size="medium" style={{ padding: '5px 8px' }}>
              <FaTruckFast
                fontSize="30px"
                style={{ color: theme.palette.grey.main }}
              />
            </ListItemIcon>
            <ListItemText sx={{ color: 'grey.main' }}>ข้อมูลรถ</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton href="/">
            <ListItemIcon size="medium" style={{ padding: '5px' }}>
              <VerifiedUserSharp fontSize="large" sx={{ color: 'grey.main' }} />
            </ListItemIcon>
            <ListItemText sx={{ color: 'grey.main' }}>
              ประกันภัยสินค้า
            </ListItemText>
          </ListItemButton>
        </ListItem> */}
        <ListItem>
          <ListItemButton href="/post-work">
            <ListItemIcon size="medium" style={{ padding: '5px' }}>
              <PostAdd fontSize="large" sx={{ color: 'grey.main' }} />
            </ListItemIcon>
            <ListItemText sx={{ color: 'grey.main' }}>ประกาศงาน</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton href="/work">
            <ListItemIcon size="medium" style={{ padding: '5px 8px' }}>
              <BsFillBoxSeamFill
                fontSize="30px"
                style={{ color: theme.palette.grey.main }}
              />
            </ListItemIcon>
            <ListItemText sx={{ color: 'grey.main' }}>ข้อมูลงาน</ListItemText>
          </ListItemButton>
        </ListItem>
        {/* <ListItem>
          <ListItemButton href="/tracking-driver">
            <ListItemIcon size="medium" style={{ padding: '5px' }}>
              <PinDropRounded fontSize="large" sx={{ color: 'grey.main' }} />
            </ListItemIcon>
            <ListItemText sx={{ color: 'grey.main' }}>
              ติดตามคนขับทั้งหมด
            </ListItemText>
          </ListItemButton>
        </ListItem> */}
        <ListItem>
          <ListItemButton href="/finance/shipper/unpaid">
            <ListItemIcon size="medium" style={{ padding: '5px' }}>
              <AccountBalanceRounded
                fontSize="large"
                sx={{ color: 'grey.main' }}
              />
            </ListItemIcon>
            <ListItemText sx={{ color: 'grey.main' }}>
              ข้อมูลการเงิน
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton href="/dashboard/work">
            <ListItemIcon size="medium" style={{ padding: '5px 8px' }}>
              <HiPresentationChartLine
                fontSize="30px"
                style={{ color: theme.palette.grey.main }}
              />
            </ListItemIcon>
            <ListItemText sx={{ color: 'grey.main' }}>
              แผนภูมิและกราฟ
            </ListItemText>
          </ListItemButton>
        </ListItem>
        {/* <ListItem>
          <ListItemButton href="/admin">
            <ListItemIcon size="medium" style={{ padding: '5px' }}>
              <AdminPanelSettingsSharp
                fontSize="large"
                sx={{ color: 'grey.main' }}
              />
            </ListItemIcon>
            <ListItemText sx={{ color: 'grey.main' }}>
              จัดการผู้ดูแลระบบ
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton href="/master-data/term-and-condition">
            <ListItemIcon size="medium" style={{ padding: '5px 8px' }}>
              <BsDatabaseFillGear
                fontSize="30px"
                style={{ color: theme.palette.grey.main }}
              />
            </ListItemIcon>
            <ListItemText sx={{ color: 'grey.main' }}>
              จัดการ Master Data
            </ListItemText>
          </ListItemButton>
        </ListItem> */}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor="left"
          open={props.openDrawer}
          onClose={() => {
            props.onClose();
          }}
        >
          <div
            style={{
              display: 'flex',
              padding: 20,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <img src={Logo} height={'37px'} alt="logo wemove platform" />
            <img
              src={CloseDrawer}
              height={'30px'}
              onClick={() => {
                props.onClose();
              }}
              alt=""
            />
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'row', padding: '0 10px' }}
          >
            <div>
              {props?.user_info?.image ? (
                <img
                  src={`http://${props?.user_info?.image}`}
                  width={'75px'}
                  height={'75px'}
                  alt="image profile"
                  style={{
                    objectFit: 'cover',
                    borderRadius: '10px',
                    display: 'block',
                    margin: 'auto',
                    verticalAlign: 'middle',
                  }}
                />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    width: '75px',
                    height: '75px',
                    backgroundColor: `rgba(140, 163, 186, 0.2)`,
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '10px',
                  }}
                >
                  <AccountCircle
                    sx={{
                      fontSize: 48,
                      color: 'grey.main',
                    }}
                  />
                </div>
              )}
            </div>
            <div
              style={{
                padding: '0 10px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography sx={{ fontWeight: '500' }}>
                {props?.user_info?.user_code || ''}
              </Typography>
              <Typography sx={{ fontWeight: '300' }}>
                {props?.user_info?.firstname || ''}{' '}
                {props?.user_info?.lastname || ''}
              </Typography>
            </div>
          </div>
          {list('left')}
          <List
            sx={{ marginTop: 'auto', display: 'flex', alignItems: 'flex-end' }}
          >
            <ListItem>
              <ListItemButton onClick={() => handleLogout()}>
                <ListItemText
                  sx={{
                    color: 'grey.main',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  ออกจากระบบ
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
