import instance from '../../axiosInstance';

const findCompanyName = async (user_code) => {
  try {
    return instance.get(`/api/profile/company-name/${user_code}`);
  } catch (error) {
    console.log('error', error);
  }
};

const getUserProfile = async (profile_id) => {
  try {
    return instance.get(`/api/tps/profile/${profile_id}`);
  } catch (error) {
    console.log('error', error);
  }
};
const editProfile = (id, data) => {
  try {
    return instance.patch(`/api/user/profile/${id}`, data, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  } catch (error) {
    console.log('error', error);
  }
};

const getArea = async (param) => {
  try {
    return instance.get(`/api/master/map-area`, {
      params: param,
    });
  } catch (error) {
    console.log('error', error);
  }
};
export { findCompanyName, getUserProfile, editProfile, getArea };
