const theme = {
  token: {
    fontFamily: "Kanit, sans-serif",
  },
  components: {
    Radio: {
      colorPrimary: "#2499EF",
    },
    Checkbox: {
      colorPrimary: "#2499EF",
    },
    DatePicker: {
      // colorPrimary: "#121f43",
      // backgroundColor: "#121f43", // Example background color
    },
    TreeSelect: {
      colorPrimary: "#121F43",
      colorTextBase: "#121F43",
      colorPrimaryHover: "#121F43",
      // colorTextPlaceholder: "#8CA3BA",
    },
  },
};

export default theme;
